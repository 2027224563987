import { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { deleteAllCookies, deleteCookie, getCookieByKey, setCookie } from "../common/function";
import { postTokenRefresh } from "./accounts";
import { ROUTE_PATH } from "../constants/routePath";

export const setInterceptors = (instance: AxiosInstance) => {
  // axios 요청 인터셉터
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig<any> => {
      const accessToken = getCookieByKey("accessToken");
      const refreshToken = getCookieByKey("refreshToken");
      if (!accessToken) {
        alert("로그인이 필요합니다.");
        window.location.href = ROUTE_PATH.LOGIN2;
        return config;
      }
      if (accessToken && refreshToken) {
        config.headers.Authorization = accessToken;
      }
      return config;
    },
    (error) => Promise.reject(error.response),
  );

  // axios 응답 인터셉터
  instance.interceptors.response.use(
    (response) => {
      // const { status, data } = response;
      // console.log("응답 인터셉터 성공", response);
      // if (status === 200) {
      //   console.log("interceptor >", response);
      //   console.log("data >", data);
      // }
      return response;
    },
    async (error) => {
      console.error("응답 인터셉터 오류", error);
      const originalConfig = error.config;
      const msg = error.response.data.message;
      const status = error.response.status;

      if (status === 400) {
        deleteAllCookies();
        window.alert("토큰이 만료되어 자동으로 로그아웃 되었습니다.");
        window.location.href = "/";
      }
      // 토큰 만료 - 401 Unauthorized
      else if (status === 401) {
        if (msg === "Token has expired") {
          deleteCookie("accessToken");

          const refreshToken = getCookieByKey("refreshToken");
          await postTokenRefresh({ refresh: refreshToken })
            .then((res) => {
              // console.log("토큰 재발급 성공", res.data);
              const newAccessToken = res.data.result.token.access;
              setCookie("accessToken", newAccessToken, 1);

              return instance(originalConfig);
            })
            .then(() => {
              // console.log("토큰 재발급 성공 후 api 성공", res);
              window.location.reload();
            });
        }
      }
      // 비밀번호 오류
      else if (status === 403) {
        return Promise.reject(error.response);
      }
      // 그 외
      else {
        deleteAllCookies();
        window.alert("오류가 발생하여 자동으로 로그아웃 되었습니다.");
        window.location.href = "/";
      }
      return Promise.reject(error.response);
    },
  );
};
