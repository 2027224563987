import "./styles.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { postNewLogin } from "../../../apis/accounts";
import { useInput } from "../../../hooks/useInput";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";
import { setCookie } from "../../../common/function";
import CommonButton from "../../Button/CommonButton";
import StrokeButton from "../../Button/StrokeButton";
import ColInput from "../../Input/ColInput";
import ErrorText from "../../ErrorText";

const NewLoginForm = () => {
  const { goTo } = useNavigateHook();
  const {
    text: id,
    setText: setId,
    handleChange: handleChangeId,
    error: idError,
    setError: setIdError,
    inputRef: idRef,
  } = useInput("");
  const {
    text: password,
    setText: setPassword,
    handleChange: handleChangePassword,
    error: passwordError,
    setError: setPasswordError,
    inputRef: passwordRef,
  } = useInput("");

  const [idErrorMessage, setIdErrorMessage] = useState<string>("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  // 로그인 버튼 클릭 시
  const handleLogin = () => {
    // e.preventDefault();
    if (!id) {
      setPasswordError(false);
      if (idRef.current) {
        idRef.current.focus();
      }
      setIdError(true);
      setIdErrorMessage("아이디를 입력해주세요.");
    } else if (!password) {
      setIdError(false);
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
      setPasswordError(true);
      setPasswordErrorMessage("비밀번호를 입력해주세요.");
    } else {
      const requestBody = {
        username: id,
        password,
      };
      postNewLogin(requestBody)
        .then((res) => {
          // console.log("로그인 성공:", res);
          setErrorMsg("");

          const accessToken = res.data.result.token.access;
          const refreshToken = res.data.result.token.refresh;

          setCookie("accessToken", accessToken, 1);
          setCookie("refreshToken", refreshToken, 1);

          goTo(ROUTE_PATH.HOME);
        })
        .catch((error) => {
          console.error("로그인 실패", error);
          const { message } = error.response.data;

          if (idRef.current) {
            idRef.current.focus();
          }

          setId("");
          setPassword("");

          setIdError(false);
          setPasswordError(false);
          setErrorMsg(message);
        });
    }
  };

  // 로그인 엔터 이벤트
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login__container">
      <h2>로그인</h2>
      <div className="login__content">
        <div className="login__input-wrap">
          <ColInput
            labelText={"아이디"}
            type="text"
            name="id"
            value={id}
            placeholder="아이디를 입력해주세요"
            error={idError}
            errorMsg={idErrorMessage}
            onChange={handleChangeId}
            ref={idRef}
          />
          <ColInput
            labelText={"비밀번호"}
            type="password"
            name="password"
            value={password}
            placeholder="비밀번호를 입력해주세요"
            error={passwordError}
            errorMsg={passwordErrorMessage}
            onChange={handleChangePassword}
            ref={passwordRef}
            handleKeyDown={handleKeyDown}
          />
        </div>
        {errorMsg && <ErrorText text={errorMsg} />}
        <div className="login__btn-wrap">
          <CommonButton text={"로그인"} size={"xl"} onClick={handleLogin} />
          <StrokeButton
            text={"회원가입"}
            size={"xl"}
            strokeColor={"primary"}
            onClick={() => goTo(ROUTE_PATH.SIGN_UP2)}
          />
          <div className="login__link-wrap">
            <Link to={`${ROUTE_PATH.SEARCH}?type=id`}>아이디 찾기</Link>
            <Link to={`${ROUTE_PATH.SEARCH}?type=pw`}>비밀번호 찾기</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLoginForm;
