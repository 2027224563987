export const physicalQuestionnaire = {
  sectionType: "physical",
  sectionTitle: "신체기능 파악",
  sectionDescription: {
    main: "신체기능",
    sub: "어르신의 운동능력과 신체능력을 확인합니다",
  },
  questionList: [
    {
      id: "physicalQuestionnaire1",
      message: "어르신이 세수나 양치질을 하실 때 도움이 얼마나 필요한가요?",
      answer: [
        {
          message: "매우 많이 필요",
          value: 1,
        },
        {
          message: "조금필요",
          value: 2,
        },
        {
          message: "필요없음",
          value: 3,
        },
      ],
    },
    {
      id: "physicalQuestionnaire2",
      message: "어르신이 화장실(대변 및 소변 목적)을 사용하실 때 도움이 얼마나 필요한가요?",
      answer: [
        {
          message: "매우 많이 필요",
          value: 1,
        },
        {
          message: "조금필요",
          value: 2,
        },
        {
          message: "필요없음",
          value: 3,
        },
      ],
    },
    {
      id: "physicalQuestionnaire3",
      message: "어르신이 식사를 하실 때 도움이 얼마나 필요한가요?",
      answer: [
        {
          message: "매우 많이 필요",
          value: 1,
        },
        {
          message: "조금필요",
          value: 2,
        },
        {
          message: "필요없음",
          value: 3,
        },
      ],
    },
    {
      id: "physicalQuestionnaire4",
      message: "어르신이 자세를 바꾸 실 때 도움이 얼마나 필요한가요?",
      answer: [
        {
          message: "매우 많이 필요",
          value: 1,
        },
        {
          message: "조금필요",
          value: 2,
        },
        {
          message: "필요없음",
          value: 3,
        },
      ],
    },
  ],
};

export const behaviorQuestionnaire = {
  sectionType: "behavior",
  sectionTitle: "행동변화 파악",
  sectionDescription: {
    main: "행동변화",
    sub: "어르신의 특정 행동들의 존재 여부를 확인합니다",
  },
  questionList: [
    {
      id: "behaviorQuestionnaire1",
      message: "어르신께서 길을 잃거나 불안정한 움직임을 보이시나요?",
      answer: [
        {
          message: "심함",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
    {
      id: "behaviorQuestionnaire2",
      message: "어르신께서 불규칙적인 수면이 있거나 시간을 혼동 하시나요?",
      answer: [
        {
          message: "심함",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
    {
      id: "behaviorQuestionnaire3",
      message: "어르신께서 슬픈 상태에 빠지거나 우는 경우가 있으신가요?",
      answer: [
        {
          message: "심함",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
    {
      id: "behaviorQuestionnaire4",
      message: "어르신께서 의미없거나 부적절한 행동을 하시나요?",
      answer: [
        {
          message: "심함",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
  ],
};

export const recognizeQuestionnaire = {
  sectionType: "recognize",
  sectionTitle: "인지기능 파악",
  sectionDescription: {
    main: "인지기능",
    sub: "어르신의 뇌기능, 인지능력을 확인합니다",
  },
  questionList: [
    {
      id: "recognizeQuestionnaire1",
      message: "어르신께서 치매를 확진 받으셨나요?",
      answer: [
        {
          message: "있음",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
    {
      id: "recognizeQuestionnaire2",
      message: "어르신께서 주위 환경이나 날짜 등을 제대로 대답하실 수 있나요?",
      answer: [
        {
          message: "못함",
          value: 0,
        },
        {
          message: "잘함",
          value: 1,
        },
      ],
    },
    {
      id: "recognizeQuestionnaire3",
      message: "어르신에게 무언가 부탁하면, 그 부탁을 이해하거나 따르실 수 있나요?",
      answer: [
        {
          message: "못함",
          value: 0,
        },
        {
          message: "잘함",
          value: 1,
        },
      ],
    },
  ],
};

export const rehabilitationQuestionnaire = {
  sectionType: "rehabilitation",
  sectionTitle: "재활영역 파악",
  sectionDescription: {
    main: "재활 영역",
    sub: "어르신의 관절, 근육 등 몸을 움직이는 요소들을 확인합니다",
  },
  questionList: [
    {
      id: "rehabilitationQuestionnaire1",
      message: "어르신께서 오른쪽 팔이나 다리를 움직일 수 있나요?",
      answer: [
        {
          message: "모두 못 움직임",
          value: 1,
        },
        {
          message: "일부 움직임",
          value: 2,
        },
        {
          message: "모두 움직임",
          value: 3,
        },
      ],
    },
    {
      id: "rehabilitationQuestionnaire2",
      message: "어르신께서 왼쪽 팔이나 다리를 움직일 수 있나요?",
      answer: [
        {
          message: "모두 못 움직임",
          value: 1,
        },
        {
          message: "일부 움직임",
          value: 2,
        },
        {
          message: "모두 움직임",
          value: 3,
        },
      ],
    },
    {
      id: "rehabilitationQuestionnaire3",
      message: "어르신께서 무릎 관절이나 발목관절을 문제없이 움직일 수 있나요?",
      answer: [
        {
          message: "모두 못 움직임",
          value: 1,
        },
        {
          message: "일부 움직임",
          value: 2,
        },
        {
          message: "모두 움직임",
          value: 3,
        },
      ],
    },
    {
      id: "rehabilitationQuestionnaire4",
      message: "어르신께서 팔꿈치 관절이나 손목, 손가락 관절을 움직일 수 있나요?",
      answer: [
        {
          message: "모두 못 움직임",
          value: 1,
        },
        {
          message: "일부 움직임",
          value: 2,
        },
        {
          message: "모두 움직임",
          value: 3,
        },
      ],
    },
  ],
};

export const nursingQuestionnaire = {
  sectionType: "nursing",
  sectionTitle: "간호처치 파악",
  sectionDescription: {
    main: "간호 처치",
    sub: "어르신께서 받고 계신 의학적 치료 존재 여부를 확인합니다",
  },
  questionList: [
    {
      id: "nursingQuestionnaire1",
      message: "어르신께서 망상이 있으신가요?",
      answer: [
        {
          message: "있음",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
    {
      id: "nursingQuestionnaire2",
      message: "어르신께서 환각, 환청 등이 있으신가요?",
      answer: [
        {
          message: "있음",
          value: 0,
        },
        {
          message: "없음",
          value: 1,
        },
      ],
    },
  ],
};
