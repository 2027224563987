import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Matress: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.1085 7C4.4354 7 3.84924 7.39992 3.62274 7.97988L1.5315 13.3315C1.51048 13.3851 1.5 13.4421 1.5 13.5C1.5 13.5956 1.52999 13.6926 1.59074 13.7766C1.68829 13.9118 1.85549 14 2.0415 14H21.9585C22.1445 14 22.3115 13.9121 22.4091 13.7769C22.4698 13.6928 22.5 13.5956 22.5 13.5C22.5 13.4421 22.4895 13.3851 22.4685 13.3315L20.3768 7.98002C20.1503 7.39993 19.5645 7 18.8915 7H5.1085ZM2.69129 7.61602C3.07483 6.63404 4.04364 6 5.1085 6H18.8915C19.9565 6 20.9248 6.63421 21.3082 7.61611L23.3995 12.9665C23.4675 13.1399 23.5 13.3209 23.5 13.5C23.5 13.8053 23.4041 14.1072 23.2199 14.3621C22.9285 14.7659 22.4555 15 21.9585 15H2.0415C1.54462 15 1.07172 14.766 0.780255 14.3624C0.596014 14.1074 0.5 13.8054 0.5 13.5C0.5 13.321 0.532492 13.14 0.600405 12.9667C0.600373 12.9668 0.600437 12.9667 0.600405 12.9667L2.69129 7.61602C2.69131 7.61598 2.69128 7.61605 2.69129 7.61602Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 16.5V13.5H1.5V16.5H0.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 16.5V13.5H23.5V16.5H22.5Z" fill="#191919" />
      <path
        d="M0.776516 17.3583C0.599662 17.1078 0.5 16.8078 0.5 16.5H1.5C1.5 16.5914 1.52983 16.6905 1.59191 16.7794C1.68994 16.9136 1.85603 17 2.04 17H21.96C22.144 17 22.3101 16.9136 22.4081 16.7794C22.4702 16.6905 22.5 16.5914 22.5 16.5H23.5C23.5 16.8078 23.4003 17.1078 23.2235 17.3583L23.2204 17.3628L23.2203 17.3627C22.9282 17.7673 22.455 18 21.96 18H2.04C1.54496 18 1.07184 17.7673 0.77966 17.3627L0.776492 17.3584L0.776516 17.3583Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M12 13C12.2761 13 12.5 12.7761 12.5 12.5C12.5 12.2239 12.2761 12 12 12C11.7239 12 11.5 12.2239 11.5 12.5C11.5 12.7761 11.7239 13 12 13Z"
        fill="#191919"
      />
      <path
        d="M9 13C9.27614 13 9.5 12.7761 9.5 12.5C9.5 12.2239 9.27614 12 9 12C8.72386 12 8.5 12.2239 8.5 12.5C8.5 12.7761 8.72386 13 9 13Z"
        fill="#191919"
      />
      <path
        d="M15 13C15.2761 13 15.5 12.7761 15.5 12.5C15.5 12.2239 15.2761 12 15 12C14.7239 12 14.5 12.2239 14.5 12.5C14.5 12.7761 14.7239 13 15 13Z"
        fill="#191919"
      />
      <path
        d="M6 13C6.27614 13 6.5 12.7761 6.5 12.5C6.5 12.2239 6.27614 12 6 12C5.72386 12 5.5 12.2239 5.5 12.5C5.5 12.7761 5.72386 13 6 13Z"
        fill="#191919"
      />
      <path
        d="M18 13C18.2761 13 18.5 12.7761 18.5 12.5C18.5 12.2239 18.2761 12 18 12C17.7239 12 17.5 12.2239 17.5 12.5C17.5 12.7761 17.7239 13 18 13Z"
        fill="#191919"
      />
      <path
        d="M21 13C21.2761 13 21.5 12.7761 21.5 12.5C21.5 12.2239 21.2761 12 21 12C20.7239 12 20.5 12.2239 20.5 12.5C20.5 12.7761 20.7239 13 21 13Z"
        fill="#191919"
      />
      <path
        d="M3 13C3.27614 13 3.5 12.7761 3.5 12.5C3.5 12.2239 3.27614 12 3 12C2.72386 12 2.5 12.2239 2.5 12.5C2.5 12.7761 2.72386 13 3 13Z"
        fill="#191919"
      />
      <path
        d="M12 11C12.2761 11 12.5 10.7761 12.5 10.5C12.5 10.2239 12.2761 10 12 10C11.7239 10 11.5 10.2239 11.5 10.5C11.5 10.7761 11.7239 11 12 11Z"
        fill="#191919"
      />
      <path
        d="M9.5 11C9.77614 11 10 10.7761 10 10.5C10 10.2239 9.77614 10 9.5 10C9.22386 10 9 10.2239 9 10.5C9 10.7761 9.22386 11 9.5 11Z"
        fill="#191919"
      />
      <path
        d="M14.5 11C14.7761 11 15 10.7761 15 10.5C15 10.2239 14.7761 10 14.5 10C14.2239 10 14 10.2239 14 10.5C14 10.7761 14.2239 11 14.5 11Z"
        fill="#191919"
      />
      <path
        d="M7 11C7.27614 11 7.5 10.7761 7.5 10.5C7.5 10.2239 7.27614 10 7 10C6.72386 10 6.5 10.2239 6.5 10.5C6.5 10.7761 6.72386 11 7 11Z"
        fill="#191919"
      />
      <path
        d="M17 11C17.2761 11 17.5 10.7761 17.5 10.5C17.5 10.2239 17.2761 10 17 10C16.7239 10 16.5 10.2239 16.5 10.5C16.5 10.7761 16.7239 11 17 11Z"
        fill="#191919"
      />
      <path
        d="M20 11C20.2761 11 20.5 10.7761 20.5 10.5C20.5 10.2239 20.2761 10 20 10C19.7239 10 19.5 10.2239 19.5 10.5C19.5 10.7761 19.7239 11 20 11Z"
        fill="#191919"
      />
      <path
        d="M4 11C4.27614 11 4.5 10.7761 4.5 10.5C4.5 10.2239 4.27614 10 4 10C3.72386 10 3.5 10.2239 3.5 10.5C3.5 10.7761 3.72386 11 4 11Z"
        fill="#191919"
      />
      <path
        d="M12 9C12.2761 9 12.5 8.77614 12.5 8.5C12.5 8.22386 12.2761 8 12 8C11.7239 8 11.5 8.22386 11.5 8.5C11.5 8.77614 11.7239 9 12 9Z"
        fill="#191919"
      />
      <path
        d="M10 9C10.2761 9 10.5 8.77614 10.5 8.5C10.5 8.22386 10.2761 8 10 8C9.72386 8 9.5 8.22386 9.5 8.5C9.5 8.77614 9.72386 9 10 9Z"
        fill="#191919"
      />
      <path
        d="M14 9C14.2761 9 14.5 8.77614 14.5 8.5C14.5 8.22386 14.2761 8 14 8C13.7239 8 13.5 8.22386 13.5 8.5C13.5 8.77614 13.7239 9 14 9Z"
        fill="#191919"
      />
      <path
        d="M7.5 9C7.77614 9 8 8.77614 8 8.5C8 8.22386 7.77614 8 7.5 8C7.22386 8 7 8.22386 7 8.5C7 8.77614 7.22386 9 7.5 9Z"
        fill="#191919"
      />
      <path
        d="M16.5 9C16.7761 9 17 8.77614 17 8.5C17 8.22386 16.7761 8 16.5 8C16.2239 8 16 8.22386 16 8.5C16 8.77614 16.2239 9 16.5 9Z"
        fill="#191919"
      />
      <path
        d="M19 9C19.2761 9 19.5 8.77614 19.5 8.5C19.5 8.22386 19.2761 8 19 8C18.7239 8 18.5 8.22386 18.5 8.5C18.5 8.77614 18.7239 9 19 9Z"
        fill="#191919"
      />
      <path
        d="M5 9C5.27614 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.27614 8 5 8C4.72386 8 4.5 8.22386 4.5 8.5C4.5 8.77614 4.72386 9 5 9Z"
        fill="#191919"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5 17.5V14.5H21.5V17.5H20.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5 17.5V14.5H18.5V17.5H17.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5 17.5V14.5H15.5V17.5H14.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5 17.5V14.5H12.5V17.5H11.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 17.5V14.5H9.5V17.5H8.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.5 17.5V14.5H6.5V17.5H5.5Z" fill="#191919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 17.5V14.5H3.5V17.5H2.5Z" fill="#191919" />
    </svg>
  );
};

export default Matress;
