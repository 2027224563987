import "./styles.scss";
import classNames from "classnames";

interface Props {
  text: string;
  name: string;
  option?: string;
  checked: boolean;
  onClick: any;
}

const Checkbox: React.FC<Props> = ({ text, name, option, checked, onClick }) => {
  return (
    <div className="checkbox-item" onClick={onClick}>
      <div className={classNames("checkbox-wrap", { checked: checked })}>
        <input type="checkbox" name={name} />
        {checked ? (
          <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.2561 0.244078C14.5815 0.569515 14.5815 1.09715 14.2561 1.42259L5.92275 9.75592C5.59732 10.0814 5.06968 10.0814 4.74424 9.75592L0.577574 5.58926C0.252137 5.26382 0.252137 4.73618 0.577574 4.41074C0.903011 4.08531 1.43065 4.08531 1.75609 4.41074L5.3335 7.98816L13.0776 0.244078C13.403 -0.0813592 13.9306 -0.0813592 14.2561 0.244078Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.2558 0.244078C14.5813 0.569515 14.5813 1.09715 14.2558 1.42259L5.92251 9.75592C5.59707 10.0814 5.06943 10.0814 4.744 9.75592L0.57733 5.58926C0.251893 5.26382 0.251893 4.73618 0.57733 4.41074C0.902767 4.08531 1.4304 4.08531 1.75584 4.41074L5.33325 7.98816L13.0773 0.244078C13.4028 -0.0813592 13.9304 -0.0813592 14.2558 0.244078Z"
              fill="#D1D1D1"
            />
          </svg>
        )}
      </div>
      <label htmlFor={name} className={classNames({ all: name === "all" })}>
        <em className={option}>{name !== "all" && (option === "essential" ? "[필수]" : "[선택]")}</em>
        {text}
      </label>
    </div>
  );
};

export default Checkbox;
