import { useReducer } from "react";

interface ModalState {
  [key: string]: boolean;
}

type ModalAction = { type: "OPEN_MODAL"; modal: string } | { type: "CLOSE_MODAL"; modal: string };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case "OPEN_MODAL":
      return { ...state, [action.modal]: true };
    case "CLOSE_MODAL":
      return { ...state, [action.modal]: false };
    default:
      return state;
  }
};

const useModal = () => {
  const [state, dispatch] = useReducer(modalReducer, {});

  const openModal = (modal: string) => dispatch({ type: "OPEN_MODAL", modal });
  const closeModal = (modal: string) => dispatch({ type: "CLOSE_MODAL", modal });

  return {
    state,
    openModal,
    closeModal,
  };
};

export default useModal;
