interface Props {
  width: string;
  height: string;
  color: string;
}

const Checkbox: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.9142 12.5858C42.6953 13.3668 42.6953 14.6332 41.9142 15.4142L21.9142 35.4142C21.1332 36.1953 19.8668 36.1953 19.0858 35.4142L9.08579 25.4142C8.30474 24.6332 8.30474 23.3668 9.08579 22.5858C9.86683 21.8047 11.1332 21.8047 11.9142 22.5858L20.5 31.1716L39.0858 12.5858C39.8668 11.8047 41.1332 11.8047 41.9142 12.5858Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Checkbox;
