import {
  PHYSICAL_ANSWER,
  PHYSICAL_QUESION_INFO,
  PHYSICAL_QUESTION,
  PHYSICAL_SIMPLE_QUESTION,
} from "./careGradeQuestions";
import {
  BEHAVIOR_ANSWER,
  BEHAVIOR_QUESION_INFO,
  BEHAVIOR_SIMPLE_QUESTION,
  BEHAVIOR_QUESTION,
} from "./careGradeQuestions";
import {
  RECOGNIZE_ANSWER,
  RECOGNIZE_QUESION_INFO,
  RECOGNIZE_SIMPLE_QUESTION,
  NURSING_QUESTION,
} from "./careGradeQuestions";
import {
  REHABILITATION_ANSWER,
  REHABILITATION_QUESION_INFO,
  REHABILITATION_SIMPLE_QUESTION,
  REHABILITATION_QUESTION,
} from "./careGradeQuestions";
import {
  NURSING_ANSWER,
  NURSING_QUESION_INFO,
  NURSING_SIMPLE_QUESTION,
  RECOGNIZE_QUESTION,
} from "./careGradeQuestions";

// 간소화
export const EX_QUESTION_TYPE: { [key: number]: any } = {
  1: {
    info: PHYSICAL_QUESION_INFO,
    questions: PHYSICAL_SIMPLE_QUESTION,
    answers: PHYSICAL_ANSWER,
  },
  2: {
    info: BEHAVIOR_QUESION_INFO,
    questions: BEHAVIOR_SIMPLE_QUESTION,
    answers: BEHAVIOR_ANSWER,
  },
  3: {
    info: RECOGNIZE_QUESION_INFO,
    questions: RECOGNIZE_SIMPLE_QUESTION,
    answers: RECOGNIZE_ANSWER,
  },
  4: {
    info: REHABILITATION_QUESION_INFO,
    questions: REHABILITATION_SIMPLE_QUESTION,
    answers: REHABILITATION_ANSWER,
  },
  5: {
    info: NURSING_QUESION_INFO,
    questions: NURSING_SIMPLE_QUESTION,
    answers: NURSING_ANSWER,
  },
} as const;

// 구체화
export const DETAIL_QUESTION_TYPE = {
  1: {
    info: PHYSICAL_QUESION_INFO,
    questions: PHYSICAL_QUESTION,
    answers: PHYSICAL_ANSWER,
  },
  2: {
    info: BEHAVIOR_QUESION_INFO,
    questions: BEHAVIOR_QUESTION,
    answers: BEHAVIOR_ANSWER,
  },
  3: {
    info: RECOGNIZE_QUESION_INFO,
    questions: RECOGNIZE_QUESTION,
    answers: RECOGNIZE_ANSWER,
  },
  4: {
    info: REHABILITATION_QUESION_INFO,
    questions: REHABILITATION_QUESTION,
    answers: REHABILITATION_ANSWER,
  },
  5: {
    info: NURSING_QUESION_INFO,
    questions: NURSING_QUESTION,
    answers: NURSING_ANSWER,
  },
} as const;
