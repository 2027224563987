import React, { useState } from "react";
import Welcome from "../../components/SignUp/Welcome";
import "./styles.scss";
import Privacy from "../../components/SignUp/Privacy";
import SignUpForm from "../../components/SignUp/SignUpForm";

const SignUp = () => {
  const [isSelectedUserType, setIsSelectedUserType] = useState(false);
  const [isAgreePrivacy, setIsAgreePrivacy] = useState(false);
  const [userType, setUserType] = useState("");
  const [agreementObject, setAgreementObject] = useState<any>({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const handleUserType = (targetUserType: string) => {
    setIsSelectedUserType(true);
    setUserType(targetUserType);
    console.log(userType);
    setIsAgreePrivacy(false);
  };
  return (
    <main className="page-main-container main-container">
      {!isSelectedUserType ? (
        <Welcome handleUserType={handleUserType} />
      ) : !isAgreePrivacy ? (
        <Privacy
          setIsAgreePrivacy={setIsAgreePrivacy}
          agreementObject={agreementObject}
          setAgreementObject={setAgreementObject}
        />
      ) : (
        <SignUpForm agreementObject={agreementObject} />
      )}
    </main>
  );
};

export default SignUp;
