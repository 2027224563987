import "./styles.scss";
import CommonButton from "../Button/CommonButton";
import StrokeButton from "../Button/StrokeButton";
import Checkbox from "../svg/icon/Checkbox";

interface Props {
  mainText: string;
  subText: string;
  firstBtnText: string;
  firstBtnOnClick: () => void;
  secondBtnText: string;
  secondBtnOnClick: () => void;
}

const Completion: React.FC<Props> = ({
  mainText,
  subText,
  firstBtnText,
  firstBtnOnClick,
  secondBtnText,
  secondBtnOnClick,
}) => {
  return (
    <div className="completion__container">
      <div className="completion__content">
        <div className="completion-ico-check-bg">
          <Checkbox width="48" height="48" color="#329B10" />
        </div>
        <div className="txt-wrap">
          <p className="txt-main">{mainText}</p>
          <p className="txt-sub">{subText}</p>
        </div>
      </div>
      <div className="completion__btn-wrap">
        <StrokeButton text={firstBtnText} size={"xl"} strokeColor={"gray"} onClick={firstBtnOnClick} />
        <CommonButton text={secondBtnText} size={"xl"} onClick={secondBtnOnClick} />
      </div>
    </div>
  );
};

export default Completion;
