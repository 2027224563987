export const ROUTE_PATH = {
  HOME: "/home",
  QUESTIONNAIRE_EX: "/questionnaire/ex",
  QUESTIONNAIRE_SIMPLE: "/questionnaire/simple",
  QUESTIONNAIRE_DETAIL: "/questionnaire/detail",
  SIGN_UP: "/signUp",
  SIGN_UP2: "/signUp2",
  LOGIN: "/login",
  LOGIN2: "/login2",
  SEARCH: "/search",
  PROFILE: "/profile",
  MYPAGE: "/mypage",
  USERINFO: "/mypage/info",
  MODIFYINFO: "/mypage/modify",
  CHANGEPW: "/mypage/change",
  CANCELUSER: "/mypage/cancel",
  CAREGRADE: "/care",
  ASSESSMENT: "/care/assessment",
  CAREGRADERESULT: "/care/result",
  QUESTIONBOARD: "/question_board",
  FREEBOARD: "/free_board",
  COUNSEL: "/counsel",
  NAVERBAND: "/naver_band",
  WELFAREDEVICE: "/welfare_device",
  SUBSIDY: "/subsidy",
};
