import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { phoneAuthState } from "../../recoil/atoms";
import { postAuthVerify, postPhoneAuth } from "../../apis/accounts";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_REGEXP } from "../../constants/signup";
import { useInput } from "../../hooks/useInput";
import CommonInput from "../Input/CommonInput";
import TimerInput from "../Input/TimerInput";

interface Props {
  propVal?: string;
  errorMsg?: string;
  labelType?: string;
  hasError?: boolean;
}

const PhoneAuthForm: React.FC<Props> = ({ propVal, errorMsg, labelType, hasError }) => {
  const [phoneAuth, setPhoneAuth] = useRecoilState(phoneAuthState);

  const {
    text: phone,
    setText: setPhone,
    handleChange: handleChangePhone,
    error: phoneError,
    setError: setPhoneError,
    handleBlur: handleBlurPhone,
    errorMsg: phoneErrorMessage,
    setErrorMsg: setPhoneErrorMessage,
  } = useInput("", SIGNUP_REGEXP.PHONE, SIGNUP_ERROR_MESSAGE.PHONE);
  const {
    text: authNum,
    setText: setAuthNum,
    handleChange: handleChangeAuthNum,
    error: authNumError,
    setError: setAuthNumError,
    handleBlur: handleBlurAuthNum,
    errorMsg: authNumErrorMessage,
    setErrorMsg: setAuthNumErrorMessage,
  } = useInput("");

  const [buttonText, setButtonText] = useState<string>("인증번호 받기");

  const [isCheckAuth, setIsCheckAuth] = useState<boolean>(false); // 인증번호 전송 체크
  const [isClickAuthBtn, setIsClickAuthBtn] = useState<boolean>(false); // 인증번호 버튼 클릭 체크
  const [isCheckTime, setIsCheckTime] = useState<boolean>(false); // 시간종료 체크

  const [phoneDisabled, setPhoneDisable] = useState<boolean>(false);
  const [phoneBtnDisabled, setPhoneBtnDisable] = useState<boolean>(true);
  const [authNumDisabled, setAuthNumDisable] = useState<boolean>(false);
  const [authNumBtnDisabled, setAuthNumBtnDisable] = useState<boolean>(false);

  // 회원정보 수정 - 휴대폰 입력 값 체크 시 사용
  useEffect(() => {
    if (propVal) {
      setPhone(propVal);
      setPhoneAuth({ ...phoneAuth, phone: propVal });
    }
  }, [propVal, phoneAuth, setPhone, setPhoneAuth]);

  useEffect(() => {
    if (errorMsg) {
      setPhoneError(true);
      setPhoneErrorMessage(errorMsg);
    }
  }, [errorMsg, setPhoneError, setPhoneErrorMessage]);

  useEffect(() => {
    setPhoneAuth({ ...phoneAuth, phone });
    if (SIGNUP_REGEXP.PHONE.test(phone)) {
      setPhoneBtnDisable(false);
    } else {
      setPhoneBtnDisable(true);
    }
  }, [phone, phoneAuth, setPhoneAuth]);

  useEffect(() => {
    setAuthNumBtnDisable(isCheckTime);
  }, [isCheckTime]);

  useEffect(() => {
    if (hasError) {
      handleBlurPhone();
      if (errorMsg) {
        setPhoneDisable(false);
        setPhoneBtnDisable(false);
        setAuthNum("");
        setAuthNumDisable(false);
        setAuthNumBtnDisable(false);
      }
    }
  }, [hasError, errorMsg, handleBlurPhone, setAuthNum]);

  // 휴대폰 인증번호 요청
  const handleGetNumber = () => {
    if (!phone) {
      setPhoneError(true);
      setPhoneErrorMessage("휴대폰 번호를 입력해주세요");
    } else {
      alert("입력하신 휴대전화로 인증번호를 발송했습니다.");
      const requestBody = { phone };
      postPhoneAuth(requestBody)
        .then(() => {
          // console.log("휴대폰 인증번호 전송 성공:", res);
          setIsCheckAuth(true);
          setIsClickAuthBtn(true);
          setPhoneDisable(true);
          setButtonText("인증번호 재전송");

          // 휴대폰 입력 값 체크 시 사용
          setPhoneAuth({
            ...phoneAuth,
            phone,
            isSuccess: false,
          });
        })
        .catch((error) => {
          console.error("휴대폰 인증번호 전송 실패:", error);
          setPhoneError(true);
          setPhoneErrorMessage(SIGNUP_ERROR_MESSAGE.PHONE);
          setPhoneDisable(false);

          // 휴대폰 입력 값 체크 시 사용
          setPhoneAuth({
            ...phoneAuth,
            phone,
            isSuccess: false,
          });
        });
    }
  };

  // 휴대폰 인증 확인
  const handleAuthVerify = () => {
    const requestBody = {
      phone,
      code: authNum,
    };
    postAuthVerify(requestBody)
      .then(() => {
        // console.log("휴대폰 인증 성공", res);
        setPhoneError(false);
        setPhoneErrorMessage("");
        setAuthNumError(false);

        setPhoneBtnDisable(true);
        setAuthNumDisable(true);
        setAuthNumBtnDisable(true);

        setPhoneAuth({ ...phoneAuth, isSuccess: true });
      })
      .catch((error) => {
        console.error("휴대폰 인증 실패", error);
        const { message } = error.response.data;
        setAuthNumError(true);
        setAuthNumErrorMessage(message);
      });
  };

  useEffect(() => {
    if (isCheckTime) {
      // 나중에 모달로 대체
      alert("인증시간이 종료되었습니다. 인증전송을 다시 진행해주세요.");
    }
  }, [isCheckTime]);

  return (
    <div className="phone-auth-form">
      <CommonInput
        labelText="휴대폰 번호"
        labelType={labelType}
        type="text"
        name="phone"
        value={phone}
        maxLength={11}
        placeholder="휴대폰번호 -없이 입력"
        buttonText={buttonText}
        disabled={phoneDisabled}
        btnDisabled={phoneBtnDisabled}
        error={phoneError}
        errorMsg={phoneErrorMessage}
        onChange={handleChangePhone}
        onClick={handleGetNumber}
        onBlur={handleBlurPhone}
      />
      {isCheckAuth && (
        <TimerInput
          row={true}
          labelText="인증번호"
          type="text"
          name="authNum"
          value={authNum}
          maxLength={6}
          placeholder="인증번호 6자리 입력"
          buttonText={"인증번호 확인"}
          disabled={authNumDisabled}
          btnDisabled={authNumBtnDisabled}
          error={authNumError}
          errorMsg={authNumErrorMessage}
          onChange={handleChangeAuthNum}
          isClickAuthBtn={isClickAuthBtn}
          setIsClickAuthBtn={setIsClickAuthBtn}
          setIsCheckTime={setIsCheckTime}
          onClick={handleAuthVerify}
          onBlur={handleBlurAuthNum}
        />
      )}
    </div>
  );
};

export default PhoneAuthForm;
