import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Youtube: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#FF0302"
      />
      <path
        d="M24.183 12.6613C24.183 12.6613 24.0199 11.5102 23.5178 11.0048C22.8819 10.3396 22.171 10.3363 21.8449 10.2972C19.5101 10.1276 16.0047 10.1276 16.0047 10.1276H15.9982C15.9982 10.1276 12.4928 10.1276 10.158 10.2972C9.83189 10.3363 9.12102 10.3396 8.48515 11.0048C7.98297 11.5102 7.82319 12.6613 7.82319 12.6613C7.82319 12.6613 7.65363 14.0146 7.65363 15.3646V16.6298C7.65363 17.9798 7.81993 19.333 7.81993 19.333C7.81993 19.333 7.98297 20.4841 8.48189 20.9896C9.11776 21.6548 9.95254 21.632 10.3243 21.7037C11.6612 21.8309 16.0015 21.87 16.0015 21.87C16.0015 21.87 19.5101 21.8635 21.8449 21.6972C22.171 21.658 22.8819 21.6548 23.5178 20.9896C24.0199 20.4841 24.183 19.333 24.183 19.333C24.183 19.333 24.3493 17.983 24.3493 16.6298V15.3646C24.3493 14.0146 24.183 12.6613 24.183 12.6613ZM14.2765 18.1657V13.4733L18.7862 15.8276L14.2765 18.1657Z"
        fill="white"
      />
    </svg>
  );
};

export default Youtube;
