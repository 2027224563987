import { atom } from "recoil";
import { ROUTE_PATH } from "../constants/routePath";
import { IUserInfo } from "../constants/interface";

/**
 * 회원가입 atom
 */
// 회원가입 - 회원유형 상태
export const userTypeAtom = atom({
  key: "userTypeAtom",
  default: 1,
});

type agrementType = {
  [key: number]: boolean;
};

// 회원가입 - 이용약관 상태
export const agreementAtom = atom<agrementType>({
  key: "agreementAtom",
  default: {
    1: false,
    2: false,
    3: false,
    4: false,
  },
});

type phoneAuthType = {
  phone: string;
  isSuccess: boolean;
};

// 휴대폰 인증 상태
export const phoneAuthState = atom<phoneAuthType>({
  key: "phoneAuthState",
  default: {
    phone: "",
    isSuccess: false,
  },
});

export type idType = {
  id: string;
  isCheckId: boolean;
};

// 아이디 상태
export const idState = atom<idType>({
  key: "idState",
  default: {
    id: "",
    isCheckId: false,
  },
});

export type passwordType = {
  password: string;
  isCorrect: boolean;
};

// 비밀번호 상태
export const passwordState = atom<passwordType>({
  key: "passwordState",
  default: {
    password: "",
    isCorrect: false,
  },
});

export type nicknameType = {
  nickname: string;
  isCheckNickName: boolean;
};

// 닉네임 상태
export const nicknameState = atom<nicknameType>({
  key: "nicknameState",
  default: {
    nickname: "",
    isCheckNickName: false,
  },
});

export type emailType = {
  email: string;
  domain: string;
};

// 이메일 상태
export const emailState = atom<emailType>({
  key: "emailState",
  default: {
    email: "",
    domain: "",
  },
});

export type genderType = {
  gender: string;
};

// 성별 상태
export const genderState = atom<genderType>({
  key: "genderState",
  default: {
    gender: "",
  },
});

/**
 * 아이디/비밀번호 찾기 atom
 */
type searchIdType = {
  name: string;
  id: string;
  date: string;
};

// 아이디 찾기 상태
export const searchIdState = atom<searchIdType>({
  key: "searchIdState",
  default: {
    name: "",
    id: "",
    date: "",
  },
});

type searchPwType = {
  id: string;
};

// 비밀번호 찾기 상태
export const searchPwState = atom<searchPwType>({
  key: "searchPwState",
  default: {
    id: "",
  },
});

/**
 * 마이페이지 atom
 */
// 마이페이지 선택 메뉴 상태
export const currentMypageMenuState = atom({
  key: "currentMypageMenuState",
  default: ROUTE_PATH.USERINFO,
});

// 회원정보 조회 상태
export const userInfoState = atom<IUserInfo>({
  key: "userInfoStata",
  default: {
    name: "",
    nickname: "",
    gender: "",
    birth: "",
    phone: "",
    email: "",
    user_type: 1,
  },
});

/**
 * 요양등급검사 atom
 */
export const careGradeState = atom({
  key: "careGradeState",
  default: {
    age: "",
    gender: "",
  },
});

export interface IResponses {
  physical: Record<string, string>;
  behavior: Record<string, string>;
  recognize: Record<string, string>;
  rehabilitation: Record<string, string>;
  nursing: Record<string, string>;
}

// 요양등급검사 설문 응답들 상태
export const responsesState = atom<IResponses>({
  key: "responsesState",
  default: {
    physical: {},
    behavior: {},
    recognize: {},
    rehabilitation: {},
    nursing: {},
  },
});

// 요양등급검사 설문 결과 상태
export const assessmentState = atom({
  key: "assessmentState",
  default: {
    rate: 1,
    score: 0,
    isResult: false,
  },
});

// 이전 경로 상태 저장
export const previousRoute = atom<{ path: string }>({
  key: "previousRoute",
  default: {
    path: ROUTE_PATH.HOME,
  },
});
