import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { userTypeAtom } from "../../recoil/atoms";
import NewPrivacy from "../../components/SignUp/NewPrivacy";
import NewSignUpForm from "../../components/SignUp/NewSignUpForm";
import NewUserType from "../../components/SignUp/NewUserType";
import NewSingUpCompletion from "../../components/SignUp/NewSignUpCompletion";
import { getCookieByKey } from "../../common/function";
import { useNavigateHook } from "../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../constants/routePath";

const SignUp2 = () => {
  const { goTo } = useNavigateHook();

  const [isSelectedUserType, setIsSelectedUserType] = useState<boolean>(false);
  const [isAgreePrivacy, setIsAgreePrivacy] = useState<boolean>(false);

  const setUserTypeCd = useSetRecoilState(userTypeAtom);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  useEffect(() => {
    const token = getCookieByKey("accessToken");
    if (token) {
      alert("로그인 상태입니다. 홈 화면으로 이동합니다.");
      goTo(ROUTE_PATH.HOME);
    }
  }, []);

  useEffect(() => {
    // 스크롤 최상단 이동
    window.scrollTo(0, 0);
  }, [isSelectedUserType, isAgreePrivacy, isCompleted]);

  const handleUserType = (userTypeCd: number) => {
    setIsSelectedUserType(true);
    setUserTypeCd(userTypeCd);
    setIsAgreePrivacy(false);
  };

  return (
    <main className="page-main-container">
      {!isSelectedUserType ? (
        <NewUserType handleUserType={handleUserType} />
      ) : !isAgreePrivacy ? (
        <NewPrivacy setIsAgreePrivacy={setIsAgreePrivacy} />
      ) : isCompleted ? (
        <NewSingUpCompletion />
      ) : (
        <NewSignUpForm setIsCompleted={setIsCompleted} />
      )}
    </main>
  );
};

export default SignUp2;
