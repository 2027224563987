import "./styles.scss";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { emailState, nicknameState, phoneAuthState, userInfoState } from "../../../../recoil/atoms";
import { patchProfile } from "../../../../apis/accounts";
import { SIGNUP_REGEXP } from "../../../../constants/signup";
import { ROUTE_PATH } from "../../../../constants/routePath";
import { useNavigateHook } from "../../../../hooks/useNavigateHook";
import useModal from "../../../../hooks/useModal";
import { checkFields } from "../../../../common/function";
import CommonInput from "../../../Input/CommonInput";
import CommonButton from "../../../Button/CommonButton";
import StrokeButton from "../../../Button/StrokeButton";
import CheckModal from "../../../Modal/CheckModal";
import NoticeModal from "../../../Modal/NoticeModal";
import NewMypageTemplate from "../../MypageTemplate";
import NicknameForm from "../../../NicknameForm";
import GenderForm from "../../../GenderForm";
import EmailForm from "../../../EmailForm";
import PhoneAuthForm from "../../../PhoneAuthForm";

const ModifyUserInfo = () => {
  const { goTo } = useNavigateHook();
  const { state: modalState, openModal, closeModal } = useModal();
  const profile = useRecoilValue(userInfoState);
  const { phone, isSuccess } = useRecoilValue(phoneAuthState);
  const { nickname, isCheckNickName } = useRecoilValue(nicknameState);
  const { email, domain } = useRecoilValue(emailState);

  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});

  // 회원정보 수정 취소
  const handleModifyCancel = () => goTo(ROUTE_PATH.USERINFO);

  // 변경 값 체크 함수 - 모든 값이 동일하면 변경되지 않음
  const hasObjectChanged = (oldObj: Record<string, any>, newObj: Record<string, any>): Record<string, any> => {
    const updatedData: Record<string, any> = {};

    const isEmpty = (value: any) => value === null || value === "" || value === undefined;

    // 각 키의 값을 비교
    for (const key in oldObj) {
      if (isEmpty(oldObj[key]) && isEmpty(newObj[key])) {
        // 둘 다 빈 값이면 변경 없음으로 간주
        continue;
      }
      if (oldObj[key] !== newObj[key]) {
        // console.log("다른값: ", key, oldObj[key], newObj[key]);
        updatedData[key] = newObj[key];
      }
    }
    return updatedData;
  };

  // 프로필 수정
  const handleModifyProfile = () => {
    const hasEmptyFields = checkFields([
      { key: "nickname", value: nickname, message: "닉네임을 확인해주세요", pattern: SIGNUP_REGEXP.NICKNAME },
      { key: "email", value: email, message: "이메일을 확인해주세요", pattern: SIGNUP_REGEXP.EMAIL },
      { key: "domain", value: domain, message: "이메일 도메인을 확인해주세요", pattern: SIGNUP_REGEXP.DOMAIN },
      { key: "phone", value: phone, message: "휴대폰 번호를 확인해주세요", pattern: SIGNUP_REGEXP.PHONE },
    ]);

    const { error, errorMsg } = hasEmptyFields;
    if (error) {
      if (!email && !domain) {
        const updataMsg = {
          ...errorMsg,
          email: "",
          domain: "",
        };
        setErrorMessages(updataMsg);
      } else {
        setErrorMessages(errorMsg);
        return;
      }
    }

    const updatedErrorMsg: { [key: string]: string } = { ...errorMessages };
    if (nickname !== profile.nickname && !isCheckNickName) {
      updatedErrorMsg.nickname = "닉네임 중복확인을 해주세요";
      setErrorMessages(updatedErrorMsg);
      return;
    }
    if (phone !== profile.phone && !isSuccess) {
      updatedErrorMsg.phone = "휴대폰 번호 인증확인을 해주세요";
      setErrorMessages(updatedErrorMsg);
      return;
    }

    const formattedEmail = email && domain ? `${email}@${domain}` : "";
    const newProfile = {
      ...profile,
      nickname,
      email: formattedEmail,
      phone,
    };
    // 변경 값 체크 및 프로필 데이터 업데이트
    const updatedData = hasObjectChanged(profile, newProfile);
    if (Object.keys(updatedData).length === 0) {
      openModal("notice");
      return false;
    }

    patchProfile(updatedData)
      .then(() => {
        // console.log("프로필 수정 성공:", res);
        openModal("modify");
      })
      .catch((error) => {
        console.error("프로필 수정 실패", error);
      });
  };

  return (
    <NewMypageTemplate title="회원정보 수정">
      <div className="mypage-user-info__content">
        <div className="mypage__content__input-wrap">
          <CommonInput labelText="이름" type="text" name="id" value={profile.name || ""} disabled={true} />
          <NicknameForm propVal={profile.nickname} errorMsg={errorMessages.nickname} />
          <CommonInput labelText="생년월일" type="text" name="birth" value={profile.birth || ""} disabled={true} />
          <GenderForm propVal={profile.gender} />
          <EmailForm propVal={profile.email} errorMsg={errorMessages.email || errorMessages.domain} />
          <PhoneAuthForm propVal={profile.phone} errorMsg={errorMessages.phone} />
        </div>
        <div className="mypage__content__btn-wrap">
          <StrokeButton text={"취소"} size={"xl"} strokeColor={"gray"} onClick={handleModifyCancel} />
          <CommonButton text={"수정하기"} size={"xl"} onClick={handleModifyProfile} />
        </div>
        {/* 모달 팝업 */}
        <NoticeModal
          isOpen={modalState.notice}
          modalMsg={`변경할 내용이\n없습니다`}
          closeModal={() => closeModal("notice")}
        />
        <CheckModal
          isOpen={modalState.modify}
          modalMsg={"회원정보 수정"}
          returnPath={ROUTE_PATH.MYPAGE}
          closeModal={() => closeModal("modify")}
        />
      </div>
    </NewMypageTemplate>
  );
};

export default ModifyUserInfo;
