import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { agreementAtom } from "../../../recoil/atoms";
import { privacyPolicy } from "../../../constants/privacyPolicy";
import Checkbox from "../../Checkbox/Checkbox";
import CommonButton from "../../Button/CommonButton";
import NewSignUpTemplate from "../NewSignUpTemplate";

interface Props {
  setIsAgreePrivacy: React.Dispatch<any>;
}

const NewPrivacy: React.FC<Props> = ({ setIsAgreePrivacy }) => {
  const [agreementObject, setAgreementObject] = useRecoilState(agreementAtom);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const handleAgreementObject = (key: any) => {
    const tempValue = agreementObject[key];

    setAgreementObject({
      ...agreementObject,
      [key]: !tempValue,
    });
  };

  const onClickAllAgreement = () => {
    const isAllCheck = Object.values(agreementObject).every((value) => value === true);
    const updateObject = Object.keys(agreementObject).reduce((acc: any, key) => {
      acc[key] = !isAllCheck;
      return acc;
    }, {});
    setAgreementObject(updateObject);
  };

  useEffect(() => {
    const isAgree = agreementObject[1] && agreementObject[2];
    setIsAgree(isAgree);
  }, [agreementObject]);

  return (
    <NewSignUpTemplate title={"약관동의"} subTitle={"어부바 서비스 이용약관 동의"} tabIndex={2}>
      <div className="terms__container">
        <div className="terms__content">
          <div className="terms__item">
            <Checkbox
              text={"약관 전체동의하기"}
              name="all"
              checked={agreementObject[1] && agreementObject[2] && agreementObject[3] && agreementObject[4]}
              onClick={onClickAllAgreement}
            />
          </div>
          <hr className="full-line"></hr>
          <div className="terms__item">
            <Checkbox
              text={"어부바 이용약관"}
              option="essential"
              name="1"
              checked={agreementObject[1]}
              onClick={() => handleAgreementObject(1)}
            />
            <div className="terms-box">{privacyPolicy[1]}</div>
          </div>
          <div className="terms__item">
            <Checkbox
              text={"개인정보 수집 및 이용동의"}
              option="essential"
              name="2"
              checked={agreementObject[2]}
              onClick={() => handleAgreementObject(2)}
            />
            <div className="terms-box">{privacyPolicy[2]}</div>
          </div>
          <div className="terms__item">
            <Checkbox
              text={"어부바 활용정보동의"}
              option="option"
              name="3"
              checked={agreementObject[3]}
              onClick={() => handleAgreementObject(3)}
            />
            <div className="terms-box">{privacyPolicy[3]}</div>
          </div>
          <div className="terms__item">
            <Checkbox
              text={"SMS, 이메일 마케팅 수신동의"}
              option="option"
              name="4"
              checked={agreementObject[4]}
              onClick={() => handleAgreementObject(4)}
            />
            <div className="terms-box">{privacyPolicy[4]}</div>
          </div>
        </div>
        <CommonButton text={"다음"} size={"xl"} disabled={!isAgree} onClick={() => setIsAgreePrivacy(true)} />
      </div>
    </NewSignUpTemplate>
  );
};

export default NewPrivacy;
