import React, { FC } from "react";
import "./styles.scss";

interface Props {
  resultGrade: number;
}

const ResultDesc1: FC<Props> = ({ resultGrade }) => {
  return (
    <>
      {resultGrade === 1 && (
        <>
          <p>
            요양원 등 이용시 전체 비용의
            <strong>20%인 487,170원 (1개월 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 2 && (
        <>
          <p>
            요양원 등 이용시 전체 비용의
            <strong>455,850원 (1개월 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 3 && (
        <>
          <p>
            요양원 등 이용시 전체 비용의
            <strong>20%인 442,800원 (1개월 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 4 && (
        <>
          <p>
            요양원 등 이용시 전체 비용의
            <strong>20%인 442,800원 (1개월 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade && resultGrade >= 5 && (
        <>
          <p>
            요양원 등 이용시 전체 비용의
            <strong>20%인 442,800원 (1개월 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
    </>
  );
};

const ResultDesc2: FC<Props> = ({ resultGrade }) => {
  return (
    <>
      {resultGrade === 1 && (
        <>
          <p>
            방문간호, 방문요양 등 이용시
            <strong>15%인 3,618원 (1시간 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 2 && (
        <>
          <p>
            방문간호, 방문요양 등 이용시
            <strong>15%인 3,618원 (1시간 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 3 && (
        <>
          <p>
            방문간호, 방문요양 등 이용시
            <strong>15%인 3,618원 (1시간 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 4 && (
        <>
          <p>
            방문간호, 방문요양 등 이용시
            <strong>15%인 3,618원 (1시간 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade && resultGrade >= 5 && (
        <>
          <p>
            방문간호, 방문요양 등 이용시
            <strong>15%인 3,618원 (1시간 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
    </>
  );
};

const ResultDesc3: FC<Props> = ({ resultGrade }) => {
  return (
    <>
      {resultGrade === 1 && (
        <>
          <p>해당 등급(1, 2등급)은</p>
          <strong>데이케어센터 이용이 불가합니다.</strong>
        </>
      )}
      {resultGrade === 2 && (
        <>
          <p>
            데이케어센터 등 이용 시 전체비용의
            <strong>15%인 170,280원 (20일 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 3 && (
        <>
          <p>
            데이케어센터 등 이용 시 전체비용의
            <strong>15%인 165,640원 (20일 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade === 4 && (
        <>
          <p>
            데이케어센터 등 이용 시 전체비용의
            <strong>15%인 160,920원 (20일 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
      {resultGrade && resultGrade >= 5 && (
        <>
          <p>
            데이케어센터 등 이용 시 전체비용의
            <strong>15%인 160,920원 (20일 기준)</strong>
            으로 이용하실 수 있습니다.
          </p>
        </>
      )}
    </>
  );
};

const QuestionnaireResult: FC<Props> = ({ resultGrade }) => {
  return (
    <section className="page-main-container result-container">
      <div className="message-box ft-bold">
        <div className="result-title">
          <p>설문형 장기 요양등급 판정(간소화) 결과</p>
        </div>
        <div>
          <p>
            어르신의
            {resultGrade === 1 && <strong>예상 장기요양등급은 1~2 등급입니다. </strong>}
            {resultGrade === 2 && <strong>예상 장기요양등급은 2~3 등급입니다. </strong>}
            {resultGrade === 3 && <strong>예상 장기요양등급은 3~4 등급입니다. </strong>}
            {resultGrade === 4 && <strong>예상 장기요양등급은 4~5 등급입니다. </strong>}
            {resultGrade >= 5 && <strong>5등급 혹은 등급 판정 불가 </strong>}
          </p>
        </div>
        <div className="fromLeft">
          <ResultDesc1 resultGrade={resultGrade} />
        </div>
        <div className="fromLeft">
          <ResultDesc2 resultGrade={resultGrade} />
        </div>
        <div className="fromLeft">
          <ResultDesc3 resultGrade={resultGrade} />
        </div>
        <div>
          <p>상기 내용은 비급여 항목을 제외한 것으로, 이용하시는 시설마다 차이가 있을수 있습니다.</p>
        </div>
      </div>
      <p className="warning ft-bold">※주의 : 실제 등급과 편차가 있을 수 있음</p>
      <button className="confirm-button ft-bold" onClick={() => alert("준비중입니다.")}>
        회원가입하고 상세등급 조회하기
      </button>
    </section>
  );
};

export default QuestionnaireResult;
