import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Logo: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      className="logo-icon"
      width={width}
      height={height}
      viewBox="0 0 64 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10_363)">
        <path
          d="M9.58733 9.59043C9.1112 9.0082 8.53603 8.55047 7.86563 8.18063C7.16476 7.87303 6.36867 7.6936 5.50782 7.6936C4.64697 7.6936 3.81659 7.87669 3.15 8.18063C2.44914 8.54681 1.84349 9.0082 1.39783 9.59043C0.917889 10.1727 0.567454 10.8464 0.346528 11.6118C0.0913205 12.4064 -0.00390625 13.2046 -0.00390625 14.0615V22.6082C-0.00390625 23.4944 0.0913205 24.3219 0.346528 25.0872C0.540791 25.8819 0.887416 26.5556 1.33689 27.1049C1.78255 27.7164 2.38819 28.1778 3.08906 28.4854C3.75945 28.8223 4.55555 28.9724 5.51163 28.9724C6.4677 28.9724 7.2638 28.8186 7.96467 28.4854C8.63506 28.1778 9.20643 27.7164 9.68637 27.1049C10.132 26.5556 10.452 25.8819 10.7072 25.0872C10.9319 24.3183 11.0576 23.4907 11.0576 22.6082V14.0615C11.0576 13.2046 10.9281 12.4064 10.6767 11.6118C10.4215 10.8464 10.0406 10.1727 9.59495 9.59043H9.58733ZM8.72648 22.6082C8.72648 23.8312 8.43699 24.8419 7.8961 25.5779C7.32474 26.3762 6.52865 26.7387 5.50782 26.7387C4.48699 26.7387 3.66042 26.3725 3.15 25.5779C2.57864 24.8419 2.31963 23.8312 2.31963 22.6082V14.0322C2.31963 12.8385 2.57483 11.8571 3.11572 11.0625C3.62614 10.2935 4.42223 9.89802 5.47354 9.89802C6.52484 9.89802 7.32094 10.2972 7.8961 11.0625C8.43699 11.8571 8.72648 12.8385 8.72648 14.0322V22.6082Z"
          fill="#329B10"
        />
        <path
          d="M17.2285 7.5105C16.5962 7.5105 16.082 8.00484 16.082 8.61271V15.5958L13.5642 16.2476C13.0956 16.3684 12.7681 16.7786 12.7681 17.2473C12.7681 17.9284 13.4423 18.4227 14.1241 18.247L16.082 17.7416V32.9016C16.082 33.5094 16.5962 34.0038 17.2285 34.0038C17.8608 34.0038 18.375 33.5094 18.375 32.9016V8.61271C18.375 8.00484 17.8608 7.5105 17.2285 7.5105Z"
          fill="#329B10"
        />
        <path
          d="M62.4765 15.9071L60.8805 16.3611V8.61271C60.8805 8.00484 60.3662 7.5105 59.7339 7.5105C59.1016 7.5105 58.5874 8.00484 58.5874 8.61271V32.8979C58.5874 33.5058 59.1016 34.0001 59.7339 34.0001C60.3662 34.0001 60.8805 33.5058 60.8805 32.8979V18.7193L63.1431 18.0968C63.6535 17.9577 64.0039 17.5073 64.0039 16.9983C64.0039 16.2366 63.2421 15.6873 62.4803 15.9034L62.4765 15.9071Z"
          fill="#329B10"
        />
        <path
          d="M40.7496 22.1907C35.1236 24.1644 28.9529 24.1717 23.3231 22.2127C22.767 22.0222 22.108 22.2017 21.8566 22.6997C21.5938 23.2233 21.9023 23.8641 22.5346 24.0875C25.2048 25.0249 27.9892 25.5522 30.7889 25.6694C30.827 25.6694 30.8651 25.6694 30.9032 25.6694V32.8429C30.9032 33.4618 31.425 33.9598 32.0649 33.9598C32.7049 33.9598 33.2267 33.4581 33.2267 32.8429V25.6621C33.2686 25.6621 33.3105 25.6621 33.3524 25.6621C36.0797 25.5339 38.7918 25.0176 41.3972 24.1095C42.0066 23.8971 42.3837 23.3002 42.1856 22.7802C41.9761 22.2346 41.3248 21.982 40.7496 22.1834V22.1907Z"
          fill="#329B10"
        />
        <path
          d="M5.5269 5.14852C7.00579 5.14852 8.20467 3.99598 8.20467 2.57426C8.20467 1.15254 7.00579 0 5.5269 0C4.048 0 2.84912 1.15254 2.84912 2.57426C2.84912 3.99598 4.048 5.14852 5.5269 5.14852Z"
          fill="#329B10"
        />
        <path
          d="M54.7326 7.97167C54.3784 7.94604 54.0546 8.07787 53.8261 8.29758C53.6166 8.49898 53.4871 8.77727 53.4871 9.08853V16.9505H47.6858V9.08853C47.6858 8.78094 47.5563 8.49898 47.3468 8.29758C47.1183 8.07787 46.7945 7.9497 46.4403 7.97167C45.8232 8.01562 45.3623 8.54658 45.3623 9.13979V25.6985C45.3623 26.4932 45.5528 27.0754 45.9337 27.5038C46.3146 27.9323 46.9545 28.1446 47.9068 28.1446H53.2623C54.2184 28.1446 54.8545 27.9286 55.2354 27.5038C55.6163 27.0754 55.8068 26.4932 55.8068 25.6985V9.13979C55.8068 8.54292 55.3459 8.01195 54.7288 7.97167H54.7326ZM53.4871 25.4862C53.4871 25.7315 53.4528 25.8853 53.3271 25.9732C53.2623 26.0977 53.0719 26.127 52.8167 26.127H48.3562C48.101 26.127 47.9106 26.0977 47.8458 25.9732C47.7201 25.8816 47.6858 25.7278 47.6858 25.4862V19.1805H53.4871V25.4862Z"
          fill="#329B10"
        />
        <path
          d="M27.1436 20.3706H36.9862C37.9423 20.3706 38.5784 20.1546 38.9593 19.7261C39.3402 19.2977 39.5345 18.7155 39.5345 17.9209V8.91278C39.5345 8.37083 39.1498 7.87648 38.5975 7.77395C38.1861 7.69705 37.8052 7.83254 37.55 8.08154C37.3405 8.28294 37.211 8.56124 37.211 8.8725V12.8566H26.9227V8.8725C26.9227 8.5649 26.7931 8.28294 26.5836 8.08154C26.3246 7.83254 25.9437 7.70071 25.5362 7.77395C24.9838 7.87648 24.5991 8.37083 24.5991 8.91278V17.9209C24.5991 18.7155 24.7934 19.2977 25.1743 19.7261C25.5552 20.1546 26.1951 20.3706 27.1474 20.3706H27.1436ZM37.2071 17.7085C37.2071 17.9538 37.1767 18.1076 37.0472 18.1955C36.9824 18.32 36.792 18.3493 36.5367 18.3493H27.5892C27.334 18.3493 27.1436 18.32 27.0788 18.1955C26.9493 18.1039 26.9188 17.9501 26.9188 17.7085V14.9365H37.2071V17.7085Z"
          fill="#329B10"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_363">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
