import "./styles/common.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_PATH } from "./constants/routePath";
import QuestionnaireEx from "./pages/Questionnaire/External";
import QuestionnaireSimple from "./pages/Questionnaire/Simple";
import QuestionnaireDetail from "./pages/Questionnaire/Detail";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import SignUp2 from "./pages/SignUp2";
import SearchIdPassword from "./pages/SearchIdPassword";
import NewHeader from "./components/NewHeader";
import NewLogin from "./pages/NewLogin";
import Mypage from "./pages/Mypage";
import CareGradeHomePage from "./pages/CareGrade/CareGradeHomePage";
import AssessmentPage from "./pages/CareGrade/AssessmentPage";
import CareGradeResultPage from "./pages/CareGrade/CareGradeResultPage";
import ScrollToTop from "./hooks/ScrollToTop";
import NewHome from "./pages/NewHome";
import FallbackRedirector from "./components/FallbackRedirector";

const App: React.FC = () => {
  return (
    <div className="app-main-container">
      <NewHeader />
      <ScrollToTop />
      <Routes>
        <Route path={""} element={<Navigate to={ROUTE_PATH.CAREGRADE} />} />
        <Route path={`${ROUTE_PATH.HOME}`} element={<NewHome />} />
        <Route path={`${ROUTE_PATH.QUESTIONNAIRE_EX}`} element={<QuestionnaireEx />} />
        <Route path={`${ROUTE_PATH.QUESTIONNAIRE_SIMPLE}`} element={<QuestionnaireSimple />} />
        <Route path={`${ROUTE_PATH.QUESTIONNAIRE_DETAIL}`} element={<QuestionnaireDetail />} />
        <Route path={`${ROUTE_PATH.SIGN_UP}`} element={<SignUp />} />
        <Route path={`${ROUTE_PATH.SIGN_UP2}`} element={<SignUp2 />} />
        <Route path={`${ROUTE_PATH.LOGIN}`} element={<Login />} />
        <Route path={`${ROUTE_PATH.LOGIN2}`} element={<NewLogin />} />
        <Route path={`${ROUTE_PATH.SEARCH}`} element={<SearchIdPassword />} />
        <Route path={`${ROUTE_PATH.MYPAGE}/*`} element={<Mypage />} />
        <Route path={`${ROUTE_PATH.CAREGRADE}`} element={<CareGradeHomePage />} />
        <Route path={`${ROUTE_PATH.ASSESSMENT}`} element={<AssessmentPage />} />
        <Route path={`${ROUTE_PATH.CAREGRADERESULT}`} element={<CareGradeResultPage />} />
        <Route path={ROUTE_PATH.QUESTIONBOARD} element={<FallbackRedirector />} />
        <Route path={ROUTE_PATH.FREEBOARD} element={<FallbackRedirector />} />
        <Route path={ROUTE_PATH.COUNSEL} element={<FallbackRedirector />} />
        <Route path={ROUTE_PATH.NAVERBAND} element={<FallbackRedirector />} />
        <Route path={ROUTE_PATH.WELFAREDEVICE} element={<FallbackRedirector />} />
        <Route path={ROUTE_PATH.SUBSIDY} element={<FallbackRedirector />} />
      </Routes>
    </div>
  );
};

export default App;
