import "./styles.scss";
import classNames from "classnames";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { previousRoute } from "../../recoil/atoms";
import { Link, useLocation } from "react-router-dom";
import { postLogout } from "../../apis/accounts";
import { ROUTE_PATH } from "../../constants/routePath";
import { useNavigateHook } from "../../hooks/useNavigateHook";
import { deleteAllCookies, getCookieByKey } from "../../common/function";
import Sidebar from "../Sidebar";
import CommonButton from "../Button/CommonButton";
import Logo from "../svg/icon/Logo";
// import Menu from "../svg/icon/Menu";

const NewHeader = () => {
  const { goTo } = useNavigateHook();
  const isLogin = getCookieByKey("accessToken");
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = (path: string) => currentPath.includes(path);

  const setPreviousRoute = useSetRecoilState(previousRoute);
  const onClickMenu = () => setPreviousRoute({ path: currentPath });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleSidebar = () => setIsOpen(!isOpen);

  // 로그아웃 이벤트
  const handleLogout = () => {
    postLogout()
      .then(() => {
        // console.log("로그아웃 성공", res);
        deleteAllCookies();
        window.location.reload();
      })
      .catch((error) => {
        console.error("로그아웃 실패", error);
      });
  };

  return (
    <header className="header__container">
      <div className="header__content">
        <div className="header__logo-wrap">
          <Link to={ROUTE_PATH.HOME}>
            <Logo width="64" height="34" />
          </Link>
        </div>
        <div className="header__menu">
          <ul className="header__menu__ul">
            <li>
              <Link to={ROUTE_PATH.CAREGRADE} className={classNames({ selected: isSelected(ROUTE_PATH.CAREGRADE) })}>
                예상 요양 등급 확인
              </Link>
            </li>
            <li>
              <Link to={ROUTE_PATH.QUESTIONBOARD} onClick={onClickMenu}>
                질문 게시판
              </Link>
            </li>
            <li>
              <Link to={ROUTE_PATH.FREEBOARD} onClick={onClickMenu}>
                자유게시판
              </Link>
            </li>
            <li>
              <Link to={ROUTE_PATH.COUNSEL} onClick={onClickMenu}>
                요양보호사 상담
              </Link>
            </li>
          </ul>
        </div>
        {isLogin ? (
          <div className="header__login-wrap">
            <Link to={ROUTE_PATH.MYPAGE} className="primary">
              마이페이지
            </Link>
            <Link to={ROUTE_PATH.HOME} onClick={handleLogout}>
              로그아웃
            </Link>
          </div>
        ) : (
          <div className="header__login-wrap">
            <CommonButton text={"로그인"} size={"sm"} onClick={() => goTo(ROUTE_PATH.LOGIN2)} />
            <Link to={ROUTE_PATH.SIGN_UP2}>회원가입</Link>
          </div>
        )}
        <div className="header__sidebar-btn">
          <button onClick={handleSidebar}>
            {/* <Menu width="24" height="24" /> */}
            {`메뉴`}
          </button>
        </div>
        <Sidebar isLogin={isLogin} isOpen={isOpen} onClose={handleSidebar} onLogout={handleLogout} />
      </div>
    </header>
  );
};

export default NewHeader;
