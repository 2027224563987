import React, { FC } from "react";
import "./styles.scss";
import { USER_TYPE } from "../../../constants/user";

interface Props {
  handleUserType: any;
}

const Welcome: FC<Props> = ({ handleUserType }) => {
  return (
    <section className="welcome-container">
      <div className="message-container">
        <p>
          <span>돌봄 커뮤니티 어부바</span>에 오신것을 환영합니다.
        </p>
        <p>
          어부바는 어르신의 보호자, 사회복지사, 요양보호사, 요양기관 관계자 등 돌봄과 관련된 모든 분들이 함께 이용하는
          서비스 입니다.
        </p>
        <p>
          회원 유형에 따라, 이용하실 수 있는 서비스와 가입절차가 다르기 때문에, 이용하시고자 하는 서비스의 목적에 맞게
          회원가입 선택 부탁드립니다.
        </p>
      </div>
      <div className="user-type-container">
        <span>개인회원</span>
        <div className="button-container">
          <div className="button-box">
            <p>보호자</p>
            <button onClick={() => handleUserType(USER_TYPE.guard)}>회원 가입</button>
          </div>
          <hr />
          <div className="button-box">
            <p>어르신</p>
            <button onClick={() => handleUserType(USER_TYPE.self)}>회원 가입</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
