import { forwardRef } from "react";
import "./styles.scss";

interface Props {
  text: string;
  size: string;
  width?: string;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CommonButton = forwardRef<HTMLButtonElement, Props>(({ text, size, width, disabled, onClick }, ref) => {
  return (
    <button className={`common-btn ${size}`} style={{ width }} disabled={disabled} onClick={onClick} ref={ref}>
      {text}
    </button>
  );
});

export default CommonButton;
