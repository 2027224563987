import { deleteAllCookies } from "../../../common/function";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";
import Modal from "../ModalForm";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const CancelModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const { goTo } = useNavigateHook();

  const handleCloseModal = () => {
    closeModal();
    deleteAllCookies();
    goTo(ROUTE_PATH.HOME);
  };

  return (
    <Modal isOpen={isOpen} closeModal={handleCloseModal}>
      {`회원탈퇴 되었습니다\n홈 화면으로 이동합니다`}
    </Modal>
  );
};

export default CancelModal;
