import { useRef, useState } from "react";

export const useInput = (initialText: string, regex?: RegExp, errorMessage?: string) => {
  const [text, setText] = useState<string>(initialText);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = e.target;
    setText(value);
    if (value.length > 0) {
      if (regex) {
        setError(!regex.test(value));
        if (!regex.test(value)) {
          setErrorMsg(errorMessage || "");
        } else {
          setErrorMsg("");
        }
      }
    } else {
      setError(false);
    }
  };

  const handleBlur = () => {
    if (!text) {
      if (regex && !regex.test(text)) {
        setError(true);
        setErrorMsg("필수 입력 항목입니다.");
      } else {
        setError(false);
        setErrorMsg("");
      }
    }
  };

  return {
    text,
    setText,
    handleChange,
    handleBlur,
    error,
    errorMsg,
    setErrorMsg,
    setError,
    inputRef,
  };
};
