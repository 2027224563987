import "./styles.scss";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { userInfoState } from "../../../../recoil/atoms";
import { getProfile } from "../../../../apis/accounts";
import { setFormatValue } from "../../../../common/function";
import { SIGNUP_REGEXP } from "../../../../constants/signup";
import { GENDER_TYPE_DESC, USER_TYPE_DESC, userTypes } from "../../../../constants/user";
import { useNavigateHook } from "../../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../../constants/routePath";
import StrokeButton from "../../../Button/StrokeButton";
import NewMypageTemplate from "../../MypageTemplate";

const CheckUserInfo = () => {
  const { goTo } = useNavigateHook();

  const [profile, setProfile] = useRecoilState(userInfoState);
  const { name, nickname, gender, birth, email, phone, user_type } = profile;
  const userTypeDes = USER_TYPE_DESC[user_type as userTypes];

  useEffect(() => {
    getUserProfile();
  }, []);

  // 프로필 조회
  const getUserProfile = () => {
    getProfile()
      .then((res) => {
        // console.log("프로필 조회 성공:", res);
        const data = res.data.result.user;
        const updatedEmail = data.email ?? "";
        const updateData = {
          ...data,
          email: updatedEmail,
        };
        setProfile(updateData);
      })
      .catch((error) => {
        console.error("프로필 조회 실패", error);
      });
  };

  return (
    <NewMypageTemplate title="회원정보 조회">
      <div className="mypage-user-info__content">
        <div className="mypage-check-user-info__item-wrap">
          <div className="mypage-check-user-info__item">
            <p>이름</p>
            <p>{name}</p>
          </div>
          <div className="mypage-check-user-info__item">
            <p>회원유형</p>
            <p>{userTypeDes}</p>
          </div>
          <div className="mypage-check-user-info__item">
            <p>닉네임</p>
            <p>{nickname}</p>
          </div>
          <div className="mypage-check-user-info__item">
            <p>성별</p>
            <p>{GENDER_TYPE_DESC[gender || ""]}</p>
          </div>
          <div className="mypage-check-user-info__item">
            <p>생년월일</p>
            <p>{birth}</p>
          </div>
          <div className="mypage-check-user-info__item">
            <p>이메일</p>
            <p>{email}</p>
          </div>
          <div className="mypage-check-user-info__item">
            <p>휴대폰번호</p>
            <p>{setFormatValue(phone || "", SIGNUP_REGEXP.PHONE)}</p>
          </div>
        </div>
        <div className="mypage__content__btn-wrap">
          <StrokeButton
            text={"수정하기"}
            size={"xl"}
            width={"50%"}
            strokeColor={"gray"}
            onClick={() => goTo(ROUTE_PATH.MODIFYINFO)}
          />
        </div>
      </div>
    </NewMypageTemplate>
  );
};

export default CheckUserInfo;
