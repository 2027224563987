import "./styles.scss";
import classNames from "classnames";
import { forwardRef } from "react";
import EyeOff from "../../svg/icon/eyeOff";
import Inputbox from "../Inputbox";
import EyeOn from "../../svg/icon/eyeOn";
import MessageText from "../../MessageText";

interface Props {
  labelText: string;
  labelType?: string;
  type: string;
  name: string;
  value: string;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  error?: boolean;
  errorMsg?: string;
  okMsg?: string;
  buttonText?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isShowPassword?: boolean;
  handleShowPassword?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const PasswordInput: React.FC<Props & React.RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, Props>(
  (
    {
      labelText,
      labelType,
      type,
      name,
      value,
      minLength,
      maxLength,
      placeholder,
      disabled,
      error,
      errorMsg = "",
      okMsg = "",
      onChange,
      onKeyDown,
      isShowPassword,
      handleShowPassword,
      onBlur,
    },
    ref,
  ) => {
    return (
      <div className="pw-input__container">
        <label className={`${labelType}`}>{labelText}</label>
        <div className={classNames("pw-input__content", { error: error })}>
          <div className="pw-input__item-wrap">
            <div className="pw-input__item">
              <Inputbox
                type={type}
                name={name}
                value={value}
                minLength={minLength}
                maxLength={maxLength}
                placeholder={placeholder}
                disabled={disabled}
                error={error}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                ref={ref}
              />
              <div className="pw-input__icon-wrap">
                <button onClick={handleShowPassword}>
                  {isShowPassword ? <EyeOff width="20" height="20" /> : <EyeOn width="20" height="20" />}
                </button>
              </div>
            </div>
          </div>
          {error ? <MessageText type={`${error}`} text={errorMsg} /> : okMsg && <MessageText text={okMsg} />}
        </div>
      </div>
    );
  },
);

export default PasswordInput;
