import { SIGNUP_REGEXP } from "../constants/signup";
import { IResponses } from "../recoil/atoms";

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const deleteCookie = (value: string) => {
  document.cookie = `${value}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

export const getCookieByKey = (keyName: string) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().split("=");

    if (cookie[0] === keyName) {
      return cookie[1];
    }
  }
  return null;
};

export const setFormatValue = (value: string, regex: RegExp) => {
  if (!regex.test(value)) return value;

  switch (regex) {
    case SIGNUP_REGEXP.PHONE:
      return `${value.substring(0, 3)}-${value.length === 10 ? value.substring(3, 6) : value.substring(3, 7)}-${value.substring(value.length - 4)}`;
    case SIGNUP_REGEXP.BIRTH:
      return `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`;
    default:
      return value;
  }
};

const isEmpty = (value: any): boolean => {
  return value === null || value === undefined || value === "";
};

// 주어진 폼 객체가 유효한지 검사
export const isFormValid = (form: Record<string, any>, patterns: Record<string, RegExp>): boolean => {
  const isInvalidPattern = (value: string, pattern: RegExp) => !pattern.test(value);

  return !Object.entries(form).some(([key, value]) => {
    const pattern = patterns[key.toUpperCase()];
    const emptyCheck = isEmpty(value);
    const patternCheck = pattern && isInvalidPattern(value, pattern);
    return emptyCheck || patternCheck;
  });
};

// 빈 값 여부 및 정규식 검사 함수 - 회원정보 수정
export const checkFields = (fields: { key: string; value: any; message: string; pattern?: RegExp }[]): any => {
  const newErrorMessages: { [key: string]: string } = {};
  let hasError = false;

  const isEmpty = (value: string) => value === "" || value === null || value === undefined;
  const isInvalidPattern = (value: string, pattern: RegExp) => !pattern.test(value);

  for (const field of fields) {
    if (isEmpty(field.value)) {
      newErrorMessages[field.key] = field.message;
      hasError = true;
    } else if (field.pattern && isInvalidPattern(field.value, field.pattern)) {
      newErrorMessages[field.key] = field.message;
      hasError = true;
    } else {
      newErrorMessages[field.key] = "";
    }
  }

  return { error: hasError, errorMsg: newErrorMessages };
};

// 데이터 객체의 각 속성의 값이 비어 있는지 확인하는 함수
export const checkIfAnyEmpty = (data: IResponses): boolean => {
  for (const category in data) {
    if (Object.prototype.hasOwnProperty.call(data, category)) {
      const questionnaires = data[category as keyof IResponses];
      // 카테고리 내에 비어 있는 값이 하나라도 있으면 true 반환
      if (Object.values(questionnaires).some(isEmpty)) {
        return true;
      }
    }
  }
  // 모든 카테고리에서 비어 있는 값이 없으면 false 반환
  return false;
};

// 질문 데이터 매핑 함수
export const mapQuestionData = (questionCategory: any) => {
  const { info, questions, answers } = questionCategory;

  const questionList = Object.entries(questions).map(([key, question]) => ({
    id: `${info.qusetionType}${key}`,
    message: question,
    answer: Object.entries(answers).map(([value, message]) => ({
      message,
      value: parseInt(value),
    })),
  }));

  return {
    title: info.title,
    desc: info.desc,
    type: info.type,
    questionList,
  };
};
