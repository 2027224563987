/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from "react";
import "./styles.scss";
import { postLogin } from "../../../apis/accounts";
import { setCookie } from "../../../common/function";

interface Props {
  isLogin: boolean;
  setIsLogin: React.Dispatch<any>;
}

const LoginForm: FC<Props> = ({ isLogin, setIsLogin }) => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const [isIdValid, setIsIdValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [loginMessage, setLoginMessage] = useState("");

  const onChangeId = (event: any) => {
    const inputValue = event.target.value;
    setId(inputValue);
    if (inputValue != "") {
      setIsIdValid(true);
    } else {
      setIsIdValid(false);
    }
  };

  const onChangePassword = (event: any) => {
    const inputValue = event.target.value;
    setPassword(inputValue);
    if (inputValue != "") {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const onClickConfirm = () => {
    if (isIdValid == false) {
      setLoginMessage("아이디를 입력해주세요");
    } else if (isPasswordValid == false) {
      setLoginMessage("비밀번호를 입력해주세요");
    } else {
      const requestBody = {
        username: id,
        password,
      };

      postLogin(requestBody)
        .then((res) => {
          // console.log("로그인 성공:", res);
          const accessToken = res.data.result.token.access;
          const refreshToken = res.data.result.token.refresh;
          setCookie("accessToken", accessToken, 1);
          setCookie("refreshToken", refreshToken, 1);

          setIsLogin(true);
        })
        .catch((error) => {
          console.error("로그인 실패", error);
          setLoginMessage(" 아이디 또는 비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.");
          setId("");
          setPassword("");

          setIsIdValid(false);
          setIsPasswordValid(false);
          setIsLogin(false);
        });
    }
  };

  return (
    <>
      <main className="main-container">
        <h2>로그인 정보입력</h2>
        <div className="form-container">
          <div className="form-el">
            <label htmlFor="id">아이디</label> <br />
            <input id="id" name="id" value={id} onChange={onChangeId} />
          </div>
          <div className="form-el">
            <label htmlFor="password">비밀번호</label> <br />
            <input type="password" id="password" name="password" value={password} onChange={onChangePassword} />
          </div>
          <br></br>
          <p className="message"> {loginMessage} </p>
          <button className="confirm-button" onClick={onClickConfirm}>
            로그인
          </button>
          <br></br>
        </div>
      </main>
    </>
  );
};

export default LoginForm;
