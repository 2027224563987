import "./styles.scss";
import classNames from "classnames";

interface Props {
  tabIndex: number;
}

const NumberTabbox: React.FC<Props> = ({ tabIndex }) => {
  return (
    <div className="number-tabbox__container">
      <div className={classNames("number-tabbox__item", { selected: tabIndex === 1 })}>
        <span>01</span>
        <span>가입유형 선택</span>
      </div>
      <div className="number-tabbox__line-wrap">
        <div className="line" />
      </div>
      <div className={classNames("number-tabbox__item", { selected: tabIndex === 2 })}>
        <span>02</span>
        <span style={{ whiteSpace: "nowrap" }}>약관동의</span>
      </div>
      <div className="number-tabbox__line-wrap">
        <div className="line" />
      </div>
      <div className={classNames("number-tabbox__item", { selected: tabIndex === 3 })}>
        <span>03</span>
        <span>기본정보 입력 </span>
      </div>
      <div className="number-tabbox__line-wrap">
        <div className="line" />
      </div>
      <div className={classNames("number-tabbox__item", { selected: tabIndex === 4 })}>
        <span>04</span>
        <span style={{ whiteSpace: "nowrap" }}>가입완료</span>
      </div>
    </div>
  );
};

export default NumberTabbox;
