import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { passwordState, searchPwState } from "../../../../recoil/atoms";
import { postChangePassword } from "../../../../apis/accounts";
import { SIGNUP_REGEXP } from "../../../../constants/signup";
import { isFormValid } from "../../../../common/function";
import CommonButton from "../../../Button/CommonButton";
import SearchTemplate from "../../SearchTemplate";
import PasswordForm from "../../../PasswordForm";
import ErrorText from "../../../ErrorText";

interface Props {
  setIsCheckModifyPw: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModifyPasswordForm: React.FC<Props> = ({ setIsCheckModifyPw }) => {
  const { password, isCorrect } = useRecoilValue(passwordState);
  const { id } = useRecoilValue(searchPwState);

  const [isCheckForm, setIsCheckForm] = useState<boolean>(true);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // 비밀번호 재설정 완료
  const handleResetPassword = () => {
    const requestBody = {
      username: id,
      password,
    };
    postChangePassword(requestBody)
      .then(() => {
        // console.log("비밀번호 변경 성공:", res);
        setIsCheckModifyPw(true);

        setError(false);
        setErrorMessage("");
      })
      .catch((error) => {
        console.error("비밀번호 변경 실패:", error);
        const { message } = error.response.data;
        setError(true);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    // 버튼 활성화 조건
    const flag = isFormValid({ password }, SIGNUP_REGEXP) && isCorrect;
    setIsCheckForm(flag);
  }, [password, isCorrect]);

  return (
    <SearchTemplate title={"비밀번호 찾기"} tabIndex={2}>
      <div className="modify-password-form__container">
        <div className="modify-password-form__content">
          <PasswordForm isChange={true} />
        </div>
        {error && <ErrorText text={errorMessage} />}
        <CommonButton text={"비밀번호 재설정 완료"} size={"xl"} disabled={!isCheckForm} onClick={handleResetPassword} />
      </div>
    </SearchTemplate>
  );
};

export default ModifyPasswordForm;
