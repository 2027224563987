import "./styles.scss";
import { postWithdrawal } from "../../../apis/accounts";
import useModal from "../../../hooks/useModal";
import NewMypageTemplate from "../MypageTemplate";
import CancelModal from "../../Modal/CancelModal";
import StrokeButton from "../../Button/StrokeButton";
import { useState } from "react";

const CancelUserForm = () => {
  const { state: modalState, openModal, closeModal } = useModal();
  const [textLength, setTextLength] = useState<number>(0);

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length <= 1000) {
      setTextLength(value.length);
    }
  };

  // 회원 탈퇴
  const handleCancel = () => {
    postWithdrawal()
      .then(() => {
        // console.log("회원탈퇴 성공", res);
        openModal("cancel");
      })
      .catch((error) => {
        console.error("회원탈퇴 실패", error);
      });
  };

  return (
    <NewMypageTemplate title={"회원탈퇴"}>
      <div className="cancel-user__content">
        <h2>{`회원탈퇴 전 아래 사항을\n꼭 확인해주세요`}</h2>
        <div className="cancel-user__text-box">
          <div className="cancel-user__text-item">
            <p className="txt-bold">유의사항</p>
            <p className="txt-gray">
              회원탈퇴 시 어부바 웹 서비스를 이용하실 수 없으며 서비스 이용 내역은 모두 삭제되고, 재가입하더라도
              복구되지 않습니다.
            </p>
          </div>
          <div className="cancel-user__text-item">
            <p className="txt-bold">게시글 정보</p>
            <p className="txt-gray">
              회원탈퇴 후 어부바 서비스에 입력한 게시물 및 댓글은 삭제되지 않으며, 회원정보 삭제로 인해 작성자 본인을
              확인할 수 없으므로 게시물 편집 및 삭제 처리가 불가능 합니다. 게시물 삭제를 원하시는 경우에는 먼저 해당
              게시물을 삭제 하신 후, 탈퇴 하시기 바랍니다.
            </p>
          </div>
        </div>
        <div className="cancel-user__text-box-wrap">
          <h3>어떤점이 아쉬우셨나요?</h3>
          <div className="cancel-user__textarea-wrap">
            <textarea
              name="reason"
              placeholder="아쉬우셨던 이유를 작성해 주세요. 개선사항을 반영해 더욱 더 좋은 모습으로 발전하는 어부바가 되겠습니다."
              onChange={(e) => handleChangeTextArea(e)}
            />
            <span className="txt-gray letter-num">{`${textLength}/1000`}</span>
          </div>
        </div>
        <div className="mypage__content__btn-wrap">
          <StrokeButton text={"탈퇴하기"} size={"md"} width={"25%"} strokeColor={"gray"} onClick={handleCancel} />
        </div>
        <CancelModal isOpen={modalState.cancel} closeModal={() => closeModal("cancel")} />
      </div>
    </NewMypageTemplate>
  );
};

export default CancelUserForm;
