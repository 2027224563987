import "./styles.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ROUTE_PATH } from "../../../constants/routePath";

interface Props {
  tabIndex: number;
}

const LineTabbox: React.FC<Props> = ({ tabIndex }) => {
  return (
    <div className="line-tabbox__container">
      <Link
        className={classNames("line-tabbox__item", { selected: tabIndex === 1 })}
        to={`${ROUTE_PATH.SEARCH}?type=id`}
      >
        아이디 찾기
      </Link>
      <Link
        className={classNames("line-tabbox__item", { selected: tabIndex === 2 })}
        to={`${ROUTE_PATH.SEARCH}?type=pw`}
      >
        비밀번호 찾기
      </Link>
    </div>
  );
};

export default LineTabbox;
