import "./styles.scss";

interface Props {
  text: string;
  size: string;
  width?: string;
  strokeColor: string;
  icon?: any;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const StrokeButton: React.FC<Props> = ({ text, size, width, strokeColor, icon, disabled, onClick }) => {
  return (
    <button className={`stroke-btn ${size} ${strokeColor}`} style={{ width }} disabled={disabled} onClick={onClick}>
      {text}
      {icon}
    </button>
  );
};

export default StrokeButton;
