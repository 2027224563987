import "./styles.scss";
import { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import { responsesState } from "../../../recoil/atoms";
import { ROUTE_PATH } from "../../../constants/routePath";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { getCookieByKey } from "../../../common/function";
import CommonButton from "../../Button/CommonButton";
import CareMan from "../../svg/img/CareMan";

const CareGradeHome = () => {
  const { goTo } = useNavigateHook();
  const isLogin = getCookieByKey("accessToken");
  const questionNum = !isLogin ? 17 : 53;

  const resetResponse = useResetRecoilState(responsesState);

  useEffect(() => {
    resetResponse();
  }, [resetResponse]);

  return (
    <div className="care-grade__container">
      <div className="care-grade__title-wrap">
        <h2>예상 요양 등급 확인</h2>
      </div>
      <div className="care-grade__content">
        <div className="care-grade__content__text-box">
          <p>{`본 테스트는 건강보험공단의 평가사가 어르신을 방문하여 작성하는\n장기요양인정 조사표의 설문을 기반으로 제작 되었습니다.`}</p>
          <p>{`예상 요양 등급을 통해 어르신의 일상적인 생활이\n얼마나 가능하신지를 종합적으로 판단하게 됩니다.`}</p>
          <p>
            <strong>{`${questionNum}문항`}</strong>
            {`으로 구성되어 있으며, 등급에 따라 지원 범위가 다릅니다.`}
          </p>
          <p className="last">※실제 등급과 편차가 있을 수 있습니다</p>
        </div>
        <div className="care-grade__content__btn-wrap">
          <CommonButton text={"시작하기"} size={"xl"} onClick={() => goTo(ROUTE_PATH.ASSESSMENT)} />
        </div>
      </div>
      <div className="care-grade__img-wrap">
        <CareMan />
      </div>
    </div>
  );
};

export default CareGradeHome;
