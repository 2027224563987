import "./styles.scss";
import { useState } from "react";
import NewMypageTemplate from "../../MypageTemplate";
import CheckPasswordForm from "../CheckPasswordForm";
import ChangePasswordForm from "../ChangePasswordForm";

const MypagePasswordHome = () => {
  const [isCheckPw, setIsCheckPw] = useState<boolean>(false);

  return (
    <NewMypageTemplate title="비밀번호 변경">
      <div className="mypage-change-pw__txt-box">
        <p>유의사항</p>
        <ul>
          <li>고객님의 개인정보를 안전하게 취급하며, 회원님의 동의 없이는 회원정보를 공개 및 변경하지 않습니다.</li>
          <li>회원님의 개인정보 보호를 위해 비밀번호를 입력해 주세요.</li>
        </ul>
      </div>
      <div className="mypage-change-pw__content">
        {!isCheckPw ? (
          <CheckPasswordForm setIsCheckPw={setIsCheckPw} />
        ) : (
          <ChangePasswordForm setIsCheckPw={setIsCheckPw} />
        )}
      </div>
    </NewMypageTemplate>
  );
};

export default MypagePasswordHome;
