import "./styles.scss";
import StrokeButton from "../../Button/StrokeButton";
import Close from "../../svg/icon/Close";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  closeModal: () => void;
}

const Modal: React.FC<Props> = ({ children, isOpen, closeModal }) => {
  return (
    <>
      {isOpen && (
        <div className="modal__wrap">
          <div className="modal__container">
            <div className="modal__icon">
              <button onClick={closeModal}>
                <Close width="28" height="28" />
              </button>
            </div>
            <div className="modal__content">{children}</div>
            <div className="modal__btn-wrap">
              <StrokeButton text="확인" size="md" width="160px" strokeColor="gray" onClick={closeModal} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
