import React, { FC, useState } from "react";
import "./styles.scss";
import MainGuideMessage from "../../../components/Questionnaire/MainGuideMessage";
import QuestionnaireForm from "../../../components/Questionnaire/QuestionnaireForm/Simple";
import QuestionnaireResult from "../../../components/Questionnaire/QuestionnaireResult";

const Questionnaire: FC = () => {
  const [isStartTest, setIsStartTest] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [resultGrade, setResultGrade] = useState(0);

  return (
    <main className="questionnaire_container">
      {!isStartTest ? (
        <MainGuideMessage setIsStartTest={setIsStartTest} />
      ) : (
        !showResult && <QuestionnaireForm setShowResult={setShowResult} setResultGrade={setResultGrade} />
      )}
      {isStartTest && showResult && <QuestionnaireResult resultGrade={resultGrade} />}
    </main>
  );
};

export default Questionnaire;
