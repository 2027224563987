import { useState } from "react";
import { useLocation } from "react-router-dom";
import SearchIdForm from "../../components/Search/SearchId/SearchIdForm";
import SearchIdCompletion from "../../components/Search/SearchId/SerachIdCompletion";
import SearchPasswordForm from "../../components/Search/SearchPassword/SearchPasswordForm";
import ModifyPasswordForm from "../../components/Search/SearchPassword/ModifyPasswordForm";
import SearchPasswordCompletion from "../../components/Search/SearchPassword/SearchPasswordCompletion";

const SearchIdPassword = () => {
  // 탭 분기 처리
  const location = useLocation();
  const type = location?.search.replace("?type=", "");

  const [isCheckId, setIsCheckId] = useState<boolean>(false);
  const [isCheckPw, setIsCheckPw] = useState<boolean>(false);
  const [isCheckModifyPw, setIsCheckModifyPw] = useState(false);

  return (
    <main className="page-main-container">
      {type === "id" ? (
        isCheckId ? (
          <SearchIdCompletion setIsCheckId={setIsCheckId} setIsCheckPw={setIsCheckPw} />
        ) : (
          <SearchIdForm setIsCheckId={setIsCheckId} setIsCheckPw={setIsCheckPw} />
        )
      ) : isCheckPw ? (
        isCheckModifyPw ? (
          <SearchPasswordCompletion />
        ) : (
          <ModifyPasswordForm setIsCheckModifyPw={setIsCheckModifyPw} />
        )
      ) : (
        <SearchPasswordForm setIsCheckPw={setIsCheckPw} />
      )}
    </main>
  );
};

export default SearchIdPassword;
