import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Corporation: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 2C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V18H16V3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H5ZM18 18V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.31607 15.7956 0 15 0H5C4.20435 0 3.44129 0.31607 2.87868 0.87868C2.31607 1.44129 2 2.20435 2 3V18H1C0.447715 18 0 18.4477 0 19C0 19.5523 0.447715 20 1 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H18ZM6 6C6 5.44772 6.44772 5 7 5H8C8.55228 5 9 5.44772 9 6C9 6.55228 8.55228 7 8 7H7C6.44772 7 6 6.55228 6 6ZM11 6C11 5.44772 11.4477 5 12 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H12C11.4477 7 11 6.55228 11 6ZM6 10C6 9.44771 6.44772 9 7 9H8C8.55228 9 9 9.44771 9 10C9 10.5523 8.55228 11 8 11H7C6.44772 11 6 10.5523 6 10ZM11 10C11 9.44771 11.4477 9 12 9H13C13.5523 9 14 9.44771 14 10C14 10.5523 13.5523 11 13 11H12C11.4477 11 11 10.5523 11 10ZM6 14C6 13.4477 6.44772 13 7 13H8C8.55228 13 9 13.4477 9 14C9 14.5523 8.55228 15 8 15H7C6.44772 15 6 14.5523 6 14ZM11 14C11 13.4477 11.4477 13 12 13H13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H12C11.4477 15 11 14.5523 11 14Z"
        fill="#329B10"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Corporation;
