export type CARE_GRADE_TYPE = 1 | 2 | 3 | 4 | 5;

interface ICareGredeResult {
  CARE_GRADE: string;
  CAREGRADE_DESC: string;
  CAREGRADE_INFO: {
    NURSING_HOME_EXPENSE: { PERCENT: string; COST: string };
    VISITATION_EXPENSE: { PERCENT: string; COST: string };
    VISITATION_LIMIT_AMOUNT: string;
    DAYCARE_CENTER_EXPENSE: { PERCENT: string; COST: string };
  };
}

export const CARE_GRADE_RESULT_TYPES: { [key in CARE_GRADE_TYPE]: ICareGredeResult } = {
  // 1~2등급
  1: {
    CARE_GRADE: "1~2등급",
    CAREGRADE_DESC: "돌봄 없이 일상생활이 매우 어려움",
    CAREGRADE_INFO: {
      NURSING_HOME_EXPENSE: { PERCENT: "20%", COST: "505,440원" },
      VISITATION_EXPENSE: { PERCENT: "15%", COST: "3,618원" },
      VISITATION_LIMIT_AMOUNT: "2,069,900",
      DAYCARE_CENTER_EXPENSE: { PERCENT: "15%", COST: "119,440원" },
    },
  },
  // 2~3등급
  2: {
    CARE_GRADE: "2~3등급",
    CAREGRADE_DESC: "일상생활에 많은 도움이 필요",
    CAREGRADE_INFO: {
      NURSING_HOME_EXPENSE: { PERCENT: "20%", COST: "468,900원" },
      VISITATION_EXPENSE: { PERCENT: "15%", COST: "3,618원" },
      VISITATION_LIMIT_AMOUNT: "1,869,600",
      DAYCARE_CENTER_EXPENSE: { PERCENT: "15%", COST: "110,560원" },
    },
  },
  // 3~4등급
  3: {
    CARE_GRADE: "3~4등급",
    CAREGRADE_DESC: "상당 부분 돌봄이 필요",
    CAREGRADE_INFO: {
      NURSING_HOME_EXPENSE: { PERCENT: "20%", COST: "442,800원" },
      VISITATION_EXPENSE: { PERCENT: "15%", COST: "3,618원" },
      VISITATION_LIMIT_AMOUNT: "1,455,800",
      DAYCARE_CENTER_EXPENSE: { PERCENT: "15%", COST: "102,060원" },
    },
  },
  // 4~5등급
  4: {
    CARE_GRADE: "4~5등급",
    CAREGRADE_DESC: "부분적으로 다른사람의 도움이 필요",
    CAREGRADE_INFO: {
      NURSING_HOME_EXPENSE: { PERCENT: "20%", COST: "442,800원" },
      VISITATION_EXPENSE: { PERCENT: "15%", COST: "3,618원" },
      VISITATION_LIMIT_AMOUNT: "1,341,800",
      DAYCARE_CENTER_EXPENSE: { PERCENT: "15%", COST: "97,420원" },
    },
  },
  // 5등급 혹은 해당 없음
  5: {
    CARE_GRADE: "5등급 혹은 해당없음",
    CAREGRADE_DESC: "부분적으로 다른사람의 도움이 필요",
    CAREGRADE_INFO: {
      NURSING_HOME_EXPENSE: { PERCENT: "20%", COST: "442,800원" },
      VISITATION_EXPENSE: { PERCENT: "15%", COST: "3,618원" },
      VISITATION_LIMIT_AMOUNT: "1,151,600",
      DAYCARE_CENTER_EXPENSE: { PERCENT: "15%", COST: "92,760원" },
    },
  },
};
