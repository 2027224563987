import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { nicknameState } from "../../recoil/atoms";
import { getCheckName } from "../../apis/accounts";
import { useInput } from "../../hooks/useInput";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_MESSAGE, SIGNUP_REGEXP } from "../../constants/signup";
import CommonInput from "../Input/CommonInput";

interface Props {
  propVal?: string;
  errorMsg?: string;
  labelType?: string;
  hasError?: boolean;
}

const NicknameForm: React.FC<Props> = ({ propVal, errorMsg, labelType, hasError }) => {
  const [nicknameValue, setNicknameValue] = useRecoilState(nicknameState);
  const [currentErrorMsg, setCurrentErrorMsg] = useState<string>("");

  const {
    text: nickname,
    setText: setNickname,
    handleChange: handelChangeNickname,
    error: nicknameError,
    setError: setNicknameError,
    handleBlur: handleBlurNickname,
    errorMsg: nicknameErrorMessage,
    setErrorMsg: setNicknameErrorMessage,
    inputRef: nicknameRef,
  } = useInput("", SIGNUP_REGEXP.NICKNAME, SIGNUP_ERROR_MESSAGE.NICKNAME);

  const [nicknameMessage, setNicknameMessage] = useState<string>("");

  useEffect(() => {
    // 회원정보 수정 - 닉네임 입력 값 체크 시 사용
    if (propVal) {
      setNickname(propVal);
      setNicknameValue({ ...nicknameValue, nickname: propVal });
    }
  }, [nicknameValue, propVal, setNickname, setNicknameValue]);

  useEffect(() => {
    if (errorMsg && errorMsg !== currentErrorMsg) {
      setCurrentErrorMsg(errorMsg);
      setNicknameMessage("");
      setNicknameError(true);
      setNicknameErrorMessage(errorMsg);
    }
  }, [errorMsg, currentErrorMsg, nicknameValue, setNicknameError, setNicknameErrorMessage, setNicknameValue]);

  useEffect(() => {
    setNicknameValue({ ...nicknameValue, nickname, isCheckNickName: false });
    setNicknameMessage("");
  }, [nickname, nicknameValue, setNicknameValue]);

  useEffect(() => {
    if (hasError) {
      handleBlurNickname();
    }
  }, [hasError, nicknameValue, setNicknameValue, handleBlurNickname]);

  // 닉네임 중복 체크
  const handleCheckName = () => {
    if (!nickname && nicknameRef.current) {
      nicknameRef.current.focus();
      setNicknameError(true);
      setNicknameErrorMessage("닉네임을 입력해주세요");
    } else {
      getCheckName(nickname)
        .then(() => {
          // console.log("닉네임 중복 체크 성공", res);
          setNicknameError(false);
          setNicknameMessage(SIGNUP_MESSAGE.NICKNAME);

          // 닉네임 입력 값 체크 시 사용
          setNicknameValue({ ...nicknameValue, nickname, isCheckNickName: true });
        })
        .catch((error) => {
          console.error("닉네임 중복 체크 실패", error);

          if (nicknameRef.current) {
            nicknameRef.current.focus();
          }
          const { message } = error.response.data;
          setNicknameMessage("");
          setNicknameError(true);
          setNicknameErrorMessage(message.nickname);

          setNicknameValue({ ...nicknameValue, nickname, isCheckNickName: false });
        });
    }
  };

  // 중복확인 엔터 이벤트
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleCheckName();
    }
  };

  // 포커스가 사라질 때 중복확인을 하지 않았을 경우 에러 처리
  const handleBlurIdWithCheck = () => {
    handleBlurNickname(); // 기존 블러 핸들러 호출

    if (nicknameValue.nickname && !nicknameValue.isCheckNickName) {
      setNicknameError(true);
      setNicknameErrorMessage("닉네임 중복확인을 해주세요");
    }
  };

  return (
    <div className="nickname-form">
      <CommonInput
        labelText={"닉네임"}
        labelType={labelType}
        type="text"
        name="nickname"
        value={nickname}
        maxLength={20}
        placeholder="별명(2~20자)"
        error={nicknameError}
        errorMsg={nicknameErrorMessage}
        okMsg={nicknameMessage}
        buttonText="중복확인"
        onChange={(e) => {
          handelChangeNickname(e);
          setCurrentErrorMsg("");
        }}
        onClick={handleCheckName}
        onKeyDown={handleKeyDown}
        onBlur={handleBlurIdWithCheck}
        ref={nicknameRef}
      />
    </div>
  );
};

export default NicknameForm;
