import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const WheelChair: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75736 11.7574C4.88258 10.6321 6.4087 10 8 10C9.5913 10 11.1174 10.6321 12.2426 11.7574C13.3679 12.8826 14 14.4087 14 16C14 17.5913 13.3679 19.1174 12.2426 20.2426C11.1174 21.3679 9.5913 22 8 22C6.4087 22 4.88258 21.3679 3.75736 20.2426C2.63214 19.1174 2 17.5913 2 16C2 14.4087 2.63214 12.8826 3.75736 11.7574ZM8 12C6.93913 12 5.92172 12.4214 5.17157 13.1716C4.42143 13.9217 4 14.9391 4 16C4 17.0609 4.42143 18.0783 5.17157 18.8284C5.92172 19.5786 6.93913 20 8 20C9.06087 20 10.0783 19.5786 10.8284 18.8284C11.5786 18.0783 12 17.0609 12 16C12 14.9391 11.5786 13.9217 10.8284 13.1716C10.0783 12.4214 9.06087 12 8 12ZM16.8787 16.8787C17.4413 16.3161 18.2043 16 19 16C19.7957 16 20.5587 16.3161 21.1213 16.8787C21.6839 17.4413 22 18.2043 22 19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22C18.2043 22 17.4413 21.6839 16.8787 21.1213C16.3161 20.5587 16 19.7957 16 19C16 18.2043 16.3161 17.4413 16.8787 16.8787ZM19 18C18.7348 18 18.4804 18.1054 18.2929 18.2929C18.1054 18.4804 18 18.7348 18 19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19C20 18.7348 19.8946 18.4804 19.7071 18.2929C19.5196 18.1054 19.2652 18 19 18Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2 3C2 2.44772 2.44772 2 3 2H4C4.79565 2 5.55871 2.31607 6.12132 2.87868C6.68393 3.44129 7 4.20435 7 5V7H17C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H16V13C17.0609 13 18.0783 13.4214 18.8284 14.1716C19.5786 14.9217 20 15.9391 20 17C20 17.5523 19.5523 18 19 18C18.4477 18 18 17.5523 18 17C18 16.4696 17.7893 15.9609 17.4142 15.5858C17.0391 15.2107 16.5304 15 16 15H12.6C12.0477 15 11.6 14.5523 11.6 14C11.6 13.4477 12.0477 13 12.6 13H14V9H7V11C7 11.5523 6.55228 12 6 12C5.44772 12 5 11.5523 5 11V5C5 4.73478 4.89464 4.48043 4.70711 4.29289C4.51957 4.10536 4.26522 4 4 4H3C2.44772 4 2 3.55228 2 3Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default WheelChair;
