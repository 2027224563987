import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Person: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.46447 1.46447C4.40215 0.526784 5.67392 0 7 0C8.32608 0 9.59785 0.526784 10.5355 1.46447C11.4732 2.40215 12 3.67392 12 5C12 6.32608 11.4732 7.59785 10.5355 8.53553C9.59785 9.47322 8.32608 10 7 10C5.67392 10 4.40215 9.47322 3.46447 8.53553C2.52678 7.59785 2 6.32608 2 5C2 3.67392 2.52678 2.40215 3.46447 1.46447ZM7 2C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5C4 5.79565 4.31607 6.55871 4.87868 7.12132C5.44129 7.68393 6.20435 8 7 8C7.79565 8 8.55871 7.68393 9.12132 7.12132C9.68393 6.55871 10 5.79565 10 5C10 4.20435 9.68393 3.44129 9.12132 2.87868C8.55871 2.31607 7.79565 2 7 2ZM5 14C4.20435 14 3.44129 14.3161 2.87868 14.8787C2.31607 15.4413 2 16.2043 2 17V19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19V17C0 15.6739 0.526784 14.4021 1.46447 13.4645C2.40215 12.5268 3.67392 12 5 12H9C10.3261 12 11.5979 12.5268 12.5355 13.4645C13.4732 14.4021 14 15.6739 14 17V19C14 19.5523 13.5523 20 13 20C12.4477 20 12 19.5523 12 19V17C12 16.2044 11.6839 15.4413 11.1213 14.8787C10.5587 14.3161 9.79565 14 9 14H5Z"
        fill="#329B10"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Person;
