import "./styles.scss";
import { USER_TYPE_CD } from "../../../constants/user";
import RightArrow from "../../svg/icon/RightArrow";
import Person from "../../svg/icon/Person";
import Corporation from "../../svg/icon/Corporation";
import StrokeButton from "../../Button/StrokeButton";
import NewSignUpTemplate from "../NewSignUpTemplate";
import { useResetRecoilState } from "recoil";
import { agreementAtom, phoneAuthState } from "../../../recoil/atoms";
import { useEffect } from "react";

interface Props {
  handleUserType: (userTypeCd: number) => void;
}

const NewUserType: React.FC<Props> = ({ handleUserType }) => {
  const resetAgreement = useResetRecoilState(agreementAtom);
  const resetPhoneAuth = useResetRecoilState(phoneAuthState);

  useEffect(() => {
    resetAgreement();
    resetPhoneAuth();
  }, []);

  return (
    <NewSignUpTemplate title={"회원가입"} subTitle={"회원가입 유형 선택"} tabIndex={1}>
      <div className="type-container">
        <div className="type-wrap">
          <div className="type-img-wrap">
            <div className="type-img">
              <Person width="28" height="40" />
            </div>
            <span>개인회원</span>
          </div>
          <div className="type-btn-wrap">
            <StrokeButton
              text={"보호자 회원가입"}
              size={"xl"}
              strokeColor={"gray"}
              icon={<RightArrow width="20" height="20" />}
              onClick={() => handleUserType(USER_TYPE_CD.GUARDIAN)}
            />
            <StrokeButton
              text={"본인 회원가입"}
              size={"xl"}
              strokeColor={"gray"}
              icon={<RightArrow width="20" height="20" />}
              onClick={() => handleUserType(USER_TYPE_CD.SELF)}
            />
            <StrokeButton
              text={"요양보호사 회원가입"}
              size={"xl"}
              strokeColor={"gray"}
              icon={<RightArrow width="20" height="20" />}
              onClick={() => handleUserType(USER_TYPE_CD.CAREWORKER)}
            />
          </div>
        </div>
        <div className="type-wrap">
          <div className="type-img-wrap">
            <div className="type-img">
              <Corporation width="40" height="40" />
            </div>
            <span>기업회원</span>
          </div>
          <div className="type-btn-wrap">
            <StrokeButton
              text={"기업 회원가입"}
              size={"xl"}
              strokeColor={"gray"}
              icon={<RightArrow width="20" height="20" />}
              onClick={() => handleUserType(USER_TYPE_CD.CORPORATION)}
            />
          </div>
        </div>
      </div>
      <div className="user-type-info">
        <h3>주의사항</h3>
        <p>각 유형에 따라 가입절차가 다릅니다. 해당하는 유형을 선택하여 가입해주세요.</p>
      </div>
    </NewSignUpTemplate>
  );
};

export default NewUserType;
