import CareGradeResult from "../../../components/CareGrade/CareGradeResult";

const CareGradeResultPage = () => {
  return (
    <main className="page-main-container">
      <CareGradeResult />
    </main>
  );
};

export default CareGradeResultPage;
