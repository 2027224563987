import "./styles.scss";
import classNames from "classnames";
import StrokeButton from "../../Button/StrokeButton";
import Inputbox from "../Inputbox";
import Timer from "../../Timer";
import MessageText from "../../MessageText";

interface Props {
  row?: boolean;
  labelText: string;
  type: string;
  name: string;
  value: string;
  maxLength?: number;
  placeholder: string;
  error?: boolean;
  errorMsg?: string;
  okMsg?: string;
  buttonText?: string;
  disabled?: boolean;
  btnDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isClickAuthBtn: boolean;
  setIsClickAuthBtn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckTime: React.Dispatch<React.SetStateAction<boolean>>;
}

const TimerInput: React.FC<Props> = ({
  row,
  labelText,
  type,
  name,
  value,
  maxLength,
  placeholder,
  error,
  errorMsg = "",
  okMsg,
  buttonText,
  disabled,
  btnDisabled,
  onChange,
  onClick,
  onBlur,
  isClickAuthBtn,
  setIsClickAuthBtn,
  setIsCheckTime,
}) => {
  return (
    <div className={classNames("timer-input__container", { row: row })}>
      <label className="essential">{labelText}</label>
      <div className={classNames("timer-input__content", { msg: errorMsg || okMsg })}>
        <div className={classNames("timer-input__item-wrap", { both: buttonText !== undefined })}>
          <div className="timer-input__item">
            <Inputbox
              type={type}
              name={name}
              value={value}
              maxLength={maxLength}
              placeholder={placeholder}
              error={error}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
            />
            <div className="timer-input__right_wrap">
              <Timer
                isClickAuthBtn={isClickAuthBtn}
                setIsClickAuthBtn={setIsClickAuthBtn}
                setIsCheckTime={setIsCheckTime}
              />
            </div>
          </div>
          {buttonText && (
            <StrokeButton
              text={buttonText}
              size={"lg"}
              strokeColor={"black"}
              disabled={btnDisabled}
              onClick={onClick}
            />
          )}
        </div>
        {error ? <MessageText type={`${error}`} text={errorMsg} /> : okMsg && <MessageText text={okMsg} />}
      </div>
    </div>
  );
};

export default TimerInput;
