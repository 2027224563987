/**
 * 신체기능
 */
// 기본 정보
export const PHYSICAL_QUESION_INFO: { [key: string]: string } = {
  title: "신체기능 파악",
  desc: "어르신의 운동능력과 신체능력을 확인합니다",
  type: "physical",
  qusetionType: "physicalQuestionnaire",
};

// 간소화 질문
export const PHYSICAL_SIMPLE_QUESTION = {
  1: "식사할 때",
  2: "화장실 갈 때",
  3: "움직일 때",
  4: "세수, 양치를 할 때",
} as const;

// 구체화 질문
export const PHYSICAL_QUESTION = {
  1: "옷을 벗고 입기를",
  2: "세수 할 때",
  3: "양치질 할 때",
  4: "목욕 할 때",
  5: "식사 할 때",
  6: "체위 변경을",
  7: "일어나 앉기를",
  8: "옮겨 앉기를",
  9: "방 밖으로 나올 때",
  10: "화장실을 갈 때",
  11: "대변 조절을",
  12: "소변 조절을",
} as const;

// 답안
export const PHYSICAL_ANSWER = {
  1: "혼자서 한다",
  2: "도움이 조금 필요하다",
  3: "도움이 매우 필요하다",
} as const;

/**
 * 행동변화
 */
// 기본 정보
export const BEHAVIOR_QUESION_INFO: { [key: string]: string } = {
  title: "행동변화 파악",
  desc: "어르신의 특정 행동들의 존재 여부를 확인합니다",
  type: "behavior",
  qusetionType: "behaviorQuestionnaire",
};

// 간소화 질문
export const BEHAVIOR_SIMPLE_QUESTION = {
  1: "길을 잃거나 움직임이 불안정하다",
  2: "시간을 혼동하고 수면이 불규칙하다",
  3: "울거나 슬픈 상태에 빠져있다",
  4: "의미없거나 부적절한 행동을 한다",
} as const;

// 구체화 질문
export const BEHAVIOR_QUESTION = {
  1: "망상이 있다",
  2: "환각, 환청이 있다",
  3: "슬픈 상태이거나 운다",
  4: "수면이 불규칙하고, 밤낮을 구분하지 못한다",
  5: "도움에 저항한다",
  6: "안절부절하지 못하고 서성거린다",
  7: "길을 잃는다",
  8: "폭언을 하거나 위협적인 행동을 한다",
  9: "밖으로 나가려고 한다",
  10: "물건을 망가트린다",
  11: "의미 없거나 부적절한 행동을 한다",
  12: "돈이나 물건을 감춘다",
  13: "부적절한 옷을 입는다",
  14: "대소변 불결행위를 한다",
} as const;

// 답안
export const BEHAVIOR_ANSWER = {
  0: "아니오",
  1: "예",
} as const;

/**
 * 인지기능
 */
// 기본 정보
export const RECOGNIZE_QUESION_INFO: { [key: string]: string } = {
  title: "인지기능 파악",
  desc: "어르신의 뇌기능, 인지능력을 확인합니다",
  type: "recognize",
  qusetionType: "recognizeQuestionnaire",
};

// 간소화 질문
export const RECOGNIZE_SIMPLE_QUESTION = {
  1: "치매 확진을 받았다",
  2: "환경이나 날짜를 인지하지 못한다",
  3: "상황 판단을 하지 못한다",
} as const;

// 구체화 질문
export const RECOGNIZE_QUESTION = {
  1: "치매 확진을 받았다",
  2: "단기 기억장애가 있다",
  3: "날짜를 인지하지 못한다",
  4: "장소를 인지하지 못한다",
  5: "나이, 생년월일을 인지하지 못한다",
  6: "지시를 이해하지 못한다",
  7: "상황 판단을 하지 못한다",
  8: "의사소통에 문제가 있다",
} as const;

// 답안
export const RECOGNIZE_ANSWER = {
  0: "아니오",
  1: "예",
} as const;

/**
 * 재활영역
 */
// 기본 정보
export const REHABILITATION_QUESION_INFO: { [key: string]: string } = {
  title: "재활영역 파악",
  desc: "어르신의 관절, 근육 등 몸을 움직이는 요소들을 확인합니다",
  type: "rehabilitation",
  qusetionType: "rehabilitationQuestionnaire",
};

// 간소화 질문
export const REHABILITATION_SIMPLE_QUESTION = {
  1: "오른쪽 팔·다리를",
  2: "왼쪽 팔·다리를",
  3: "무릎이나 발목 관절을",
  4: "팔꿈치나 손목, 손가락 관절을",
} as const;

// 구체화 질문
export const REHABILITATION_QUESTION = {
  1: "오른쪽 팔을",
  2: "오른쪽 다리를",
  3: "왼쪽 팔을",
  4: "왼쪽 다리를",
  5: "어깨 관절을",
  6: "팔꿈치 관절을",
  7: "손목 관절을",
  8: "고관절(골반)을",
  9: "무릎 관절을",
  10: "발목 관절을",
} as const;

// 답안
export const REHABILITATION_ANSWER = {
  1: "움직일 수 있다",
  2: "일부 움직일 수 있다",
  3: "움직일 수 없다",
} as const;

/**
 * 간호처치
 */
// 기본 정보
export const NURSING_QUESION_INFO: { [key: string]: string } = {
  title: "간호처치 파악",
  desc: "어르신께서 받고 계신 의학적 치료 존재 여부를 확인합니다",
  type: "nursing",
  qusetionType: "nursingQuestionnaire",
};

// 간소화 질문
export const NURSING_SIMPLE_QUESTION = {
  1: "욕창 간호가 필요하다",
  2: "투석 간호가 필요하다",
} as const;

// 구체화 질문
export const NURSING_QUESTION = {
  1: "기관지 절개관 간호를 받고있다",
  2: "흡인 간호를 받고있다",
  3: "산소요법을 받고있다",
  4: "욕창 간호를 받고 있다",
  5: "경관 영양 간호를 받고 있다",
  6: "암성 통증간호를 받고있다",
  7: "도뇨 관리를 받고 있다",
  8: "장루 간호를 받고있다",
  9: "투석 간호를 받고있다",
} as const;

// 답안지
export const NURSING_ANSWER = {
  0: "아니오",
  1: "예",
} as const;
