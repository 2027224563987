import "./styles.scss";
import classNames from "classnames";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { emailState } from "../../recoil/atoms";
import { useInput } from "../../hooks/useInput";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_REGEXP } from "../../constants/signup";
import Close from "../svg/icon/Close";
import ErrorText from "../ErrorText";
import Inputbox from "../Input/Inputbox";

interface Props {
  propVal?: string;
  errorMsg?: string;
}

const EmailForm: React.FC<Props> = ({ propVal, errorMsg }) => {
  const [emailValue, setEmailValue] = useRecoilState(emailState);

  const {
    text: email,
    setText: setEmail,
    error: emailError,
    setError: setEmailError,
    handleChange: handleChangeEmail,
    errorMsg: emailErrorMessage,
    setErrorMsg: setEmailErrorMessage,
  } = useInput("", SIGNUP_REGEXP.EMAIL, SIGNUP_ERROR_MESSAGE.EMAIL);
  const {
    text: domain,
    setText: setDomain,
    error: domainError,
    setError: setDomainError,
    handleChange: handleChangeDomain,
  } = useInput("", SIGNUP_REGEXP.DOMAIN);
  const {
    text: customDomain,
    setText: setCustomDomain,
    error: customDomainError,
    setError: setCustomDomainError,
    handleChange: handleChangeCustomDomain,
  } = useInput("", SIGNUP_REGEXP.DOMAIN);

  // 회원정보 수정 - 이메일 입력 값 체크 시 사용
  useEffect(() => {
    if (propVal) {
      const [localPart, domainPart] = propVal.split("@");
      setEmail(localPart);
      setDomain(domainPart);

      setEmailValue({ ...emailValue, email: localPart, domain: domainPart });
    }
  }, [propVal, emailValue, setDomain, setEmail, setEmailValue]);

  useEffect(() => {
    if (errorMsg) {
      if (errorMsg.includes("도메인")) {
        setEmailError(true);
        setDomainError(true);
        setCustomDomainError(true);
      } else {
        setEmailError(true);
        setDomainError(true);
        setCustomDomainError(false);
      }
      setEmailErrorMessage(errorMsg);
    }
  }, [errorMsg, setCustomDomainError, setDomainError, setEmailError, setEmailErrorMessage]);

  // 이메일 @ 앞 쪽 onChange 이벤트
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeEmail(e);
    const updatedDomain = domain === "직접 입력" ? customDomain : domain;
    updateEmail(e.target.value, updatedDomain);
  };

  // 이메일 @ 뒷 쪽 onChange 이벤트 - selectbox
  const handleDomainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChangeDomain(e);
    setCustomDomainError(false);
    const selectedDomain = e.target.value;
    setDomain(selectedDomain);
    if (selectedDomain !== "직접 입력") {
      setCustomDomain("");
      updateEmail(email, selectedDomain);
    } else {
      updateEmail(email, "");
    }
  };

  // 이메일 @ 뒷 쪽 onChange 이벤트 - 직접 입력(input)
  const handleCustomDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeCustomDomain(e);
    setDomainError(false);
    const customDomainValue = e.target.value;
    setCustomDomain(customDomainValue);
    updateEmail(email, customDomainValue);
  };

  // 직접 입력에서 selectbox로 전환
  const handleReset = () => {
    setDomain("");
    setCustomDomain("");
    updateEmail(email, "");
  };

  // 이메일 형식 업데이트 하는 함수
  const updateEmail = (localPart: string, domainPart: string) => {
    setEmailValue({ email: localPart, domain: domainPart });
  };

  return (
    <div className="email-form__container">
      <label>이메일</label>
      <div className="email-form__content">
        <div className="email-form__item-wrap">
          <div className="email-form__item">
            <Inputbox
              type="text"
              name="email"
              value={email}
              maxLength={20}
              placeholder={"이메일"}
              error={emailError}
              onChange={handleEmailChange}
              // onBlur={handleBlurEmail}
            />
          </div>
          <span>@</span>
          {domain === "직접 입력" ? (
            <div className="email-form__input-wrap">
              <input
                type="text"
                className={classNames("email-form__inputbox", { error: customDomainError })}
                value={customDomain}
                placeholder={"도메인 입력"}
                onChange={handleCustomDomainChange}
              />
              <button onClick={handleReset}>
                <Close width="20" height="20" />
              </button>
            </div>
          ) : (
            <select
              className={classNames("email-form__selectbox", { error: emailError })}
              value={domain}
              onChange={handleDomainChange}
              // onBlur={handleBlurEmail}
            >
              <option value="" disabled={true}>
                선택해주세요
              </option>
              <option value="naver.com">naver.com</option>
              <option value="hanmail.net">hanmail.net</option>
              <option value="daum.net">daum.net</option>
              <option value="gmail.com">gmail.com</option>
              <option value="nate.com">nate.com</option>
              <option value="직접 입력">직접 입력</option>
            </select>
          )}
        </div>
        {(emailError || domainError || customDomainError) && <ErrorText text={emailErrorMessage} />}
      </div>
    </div>
  );
};

export default EmailForm;
