import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { phoneAuthState } from "../../recoil/atoms";

interface Props {
  isClickAuthBtn: boolean;
  setIsClickAuthBtn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckTime: React.Dispatch<React.SetStateAction<boolean>>;
}

const Timer: React.FC<Props> = ({ isClickAuthBtn, setIsClickAuthBtn, setIsCheckTime }) => {
  const { isSuccess } = useRecoilValue(phoneAuthState);
  const [minutes, setMinutes] = useState<number>(3);
  const [seconds, setSeconds] = useState<number>(0);

  // 타이머 계산하는 로직
  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);
          setIsCheckTime(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    if (isSuccess) {
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [minutes, seconds, isSuccess]);

  useEffect(() => {
    // 인증번호 재발송 타이머 초기화
    if (isClickAuthBtn) {
      setMinutes(3);
      setSeconds(0);
      setIsCheckTime(false);
      setIsClickAuthBtn(false);
    }
  }, [isClickAuthBtn]);

  return (
    <span>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
};

export default Timer;
