import MypageHome from "../../components/NewMypage/MypageHome";

const Mypage = () => {
  return (
    <main className="page-main-container">
      <MypageHome />
    </main>
  );
};

export default Mypage;
