import "./styles.scss";
import classNames from "classnames";
import { forwardRef } from "react";

interface Props {
  type: string;
  name: string;
  value: string;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const Inputbox: React.FC<Props & React.RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, Props>(
  ({ type, name, value, minLength, maxLength, placeholder, disabled, error, onChange, onKeyDown, onBlur }, ref) => {
    return (
      <input
        type={type}
        className={classNames("text-input", { error: error })}
        name={name}
        value={value}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={ref}
      />
    );
  },
);

export default Inputbox;
