import Footer from "../../components/Footer";
import Main from "../../components/Main";

const NewHome = () => {
  return (
    // <main className="page-main-container">
    <>
      <Main />
      <Footer />
    </>
    // </main>
  );
};

export default NewHome;
