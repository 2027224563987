import { useNavigateHook } from "../../../hooks/useNavigateHook";
import Modal from "../ModalForm";

interface Props {
  isOpen: boolean;
  modalMsg: string;
  returnPath: string;
  closeModal: () => void;
}

const CheckModal: React.FC<Props> = ({ isOpen, modalMsg, returnPath, closeModal }) => {
  const { goTo } = useNavigateHook();

  const handleCloseModal = () => {
    goTo(returnPath);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={handleCloseModal}>
      {`${modalMsg}이\n완료되었습니다`}
    </Modal>
  );
};

export default CheckModal;
