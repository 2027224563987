/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { GENDER_TYPE } from "../../../constants/user";
import { postSignUp } from "../../../apis/accounts";
import { getCheckId } from "../../../apis/accounts";

interface Props {
  agreementObject: any;
}
const SignUpForm: FC<Props> = ({ agreementObject }) => {
  const navigate = useNavigate();

  // 초기값 세팅 - 아이디, 비밀번호, 비밀번호확인, 이름, 성별, 생년월일, 전화번호, 이메일
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // 오류메세지 상태 저장
  const [idMessage, setIdMessage] = useState("4-12사이 대소문자 또는 숫자만 입력해 주세요");
  const [passwordMessage, setPasswordMessage] = useState("숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요");
  const [nameMessage, setNameMessage] = useState("2글자 이상 5글자 이하로 입력해주세요");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("비밀번호가 일치하지 않습니다");
  const [birthMessage, setBirthMessage] = useState("날짜를 입력해주세요");
  const [confirmMessage, setConfirmMessage] = useState("");

  // 유효성 검사
  const [isId, setIsId] = useState(false);
  const [isName, setIsName] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);
  const [isBirth, setIsBirth] = useState(false);
  const [isGender, setIsGender] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const onClickToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onChangeId = (event: any) => {
    const currentId = event.target.value;
    setId(currentId);
    const idRegExp = /^[a-zA-z0-9]{4,12}$/;

    if (!idRegExp.test(currentId)) {
      setIdMessage("4-12사이 대소문자 또는 숫자만 입력해 주세요");
    }
  };

  const onChangeName = (event: any) => {
    const currentName = event.target.value;
    setName(currentName);

    if (currentName.length < 2 || currentName.length > 5) {
      setNameMessage("2글자 이상 5글자 이하로 입력해주세요");
      setIsName(false);
    } else {
      setNameMessage("사용가능한 닉네임 입니다.");
      setIsName(true);
    }
  };

  const onChangePassword = (event: any) => {
    const currentPassword = event.target.value;
    setPassword(currentPassword);
    const passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!passwordRegExp.test(currentPassword)) {
      setPasswordMessage("숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!");
      setIsPassword(false);
    } else {
      setPasswordMessage("안전한 비밀번호 입니다.");
      setIsPassword(true);
    }
  };

  const onChangePasswordConfirm = (event: any) => {
    const currentPasswordConfirm = event.target.value;
    setPasswordConfirm(currentPasswordConfirm);
    if (password !== currentPasswordConfirm) {
      setPasswordConfirmMessage("비밀번호가 일치하지 않습니다.");
      setIsPasswordConfirm(false);
    } else {
      setPasswordConfirmMessage("비밀번호가 일치합니다.");
      setIsPasswordConfirm(true);
    }
  };

  const onChangeBirth = (event: any) => {
    const inputValue = event.target.value;
    const formattedValue = formatDataString(inputValue);
    const month = inputValue.slice(4, 6);
    const day = inputValue.slice(6, 8);

    if (month > 12 || month < 1 || day > 31 || day < 1) {
      setBirthMessage("올바른 날짜 형식을 입력해주세요");
      setIsBirth(false);
    } else {
      setBirthMessage("");
      setIsBirth(true);
    }

    setBirth(formattedValue);
  };

  const formatDataString = (inputValue: any) => {
    const cleanedValue = inputValue.replace(/[^0-9]/g, "");
    const year = cleanedValue.slice(0, 4);
    const month = cleanedValue.slice(4, 6);
    const day = cleanedValue.slice(6, 8);

    // 빈 값이거나 숫자가 아닌 문자가 들어왔을 경우에 대비하여 유효성 검사를 수행
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return inputValue; // 유효하지 않은 값은 그대로 반환
    }

    // YYYY-MM-DD 형식으로 반환합니다.
    if (inputValue.length == 8) {
      return `${year}-${month}-${day}`;
    } else {
      return `${year}${month}${day}`;
    }
  };

  const handleGender = (event: any) => {
    const { value } = event.target;
    setGender(value);
    setIsGender(true);
  };

  const onClickCheckId = () => {
    getCheckId(id)
      .then((res) => {
        setIdMessage("사용가능한 아이디 입니다.");
        setIsId(true);
      })
      .catch((error) => {
        setIdMessage(error.response.data.message.username);
        setIsId(false);
      });
  };

  function printErrorMessage(message: string) {
    return alert(message);
  }

  const onClickConfirm = () => {
    if (isId == false) {
      printErrorMessage("아이디를 확인해주세요");
    } else if (isPassword == false) {
      printErrorMessage("비밀번호를 확인해주세요");
    } else if (isPasswordConfirm == false) {
      printErrorMessage(passwordConfirmMessage);
    } else if (isName == false) {
      printErrorMessage("사용할 수 없는 닉네임 입니다.");
    } else if (isGender == false) {
      printErrorMessage("성별을 선택해주세요.");
    } else if (isBirth == false) {
      printErrorMessage(birthMessage);
    } else {
      const requestBody = {
        username: id,
        password,
        name,
        gender,
        birth,
        phone,
        email,
        tac1: agreementObject[1],
        tac2: agreementObject[2],
        tac3: agreementObject[3],
        tac4: agreementObject[4],
      };

      postSignUp(requestBody)
        .then((res) => {
          console.log("회원가입 성공:", res);
          navigate("/login");
        })
        .catch((error) => {
          console.error("회원가입 실패", error);
          alert(error);
        });
    }
  };

  return (
    <>
      <main className="main-container">
        <h2>정보입력</h2>
        <div className="form-container">
          <div className="form-el">
            <label htmlFor="id">아이디</label> <br />
            <input id="id" name="id" value={id} onChange={onChangeId} />
            <button className="id-confirm-button" onClick={onClickCheckId}>
              중복확인
            </button>
            <p className="message"> {idMessage} </p>
          </div>
          <div className="form-el">
            <label htmlFor="password">비밀번호</label> <br />
            <input type="password" id="password" name="password" value={password} onChange={onChangePassword} />
            <p className="message"> {passwordMessage} </p>
          </div>
          <div className="form-el">
            <label htmlFor="passwordConfirm">비밀번호 확인</label> <br />
            <input
              type="password"
              id="passwordConfirm"
              name="passwordConfirm"
              value={passwordConfirm}
              onChange={onChangePasswordConfirm}
            />
            <p className="message"> {passwordConfirmMessage} </p>
          </div>
          <div className="form-el">
            <label htmlFor="name">이름</label> <br />
            <input id="name" name="name" value={name} onChange={onChangeName} />
            <p className="message"> {nameMessage} </p>
          </div>
          <div className="form-el">
            <label htmlFor="gender">성별</label> <br />
            <div className="answer-container">
              <div className="answer-box">
                <span className="answer-message">남성</span>
                <input
                  type="radio"
                  className="radio-button"
                  value={GENDER_TYPE.male}
                  name="gender"
                  onChange={(e) => handleGender(e)}
                />
              </div>
              <div className="answer-box">
                <span className="answer-message">여성</span>
                <input
                  type="radio"
                  className="radio-button"
                  value={GENDER_TYPE.female}
                  name="gender"
                  onChange={(e) => handleGender(e)}
                />
              </div>
            </div>
          </div>
          <div className="form-el">
            <label htmlFor="birth">생년월일</label> <br />
            <input id="birth" name="birth" value={birth} onChange={onChangeBirth} maxLength={8} />
            <p className="message"> {birthMessage} </p>
          </div>
        </div>
        <br></br>
        <button className="confirm-button" onClick={onClickConfirm}>
          가입하기
        </button>
        <br></br>
      </main>
    </>
  );
};

export default SignUpForm;
