import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { previousRoute } from "../../recoil/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/routePath";
import { LINK_PATH } from "../../constants/link";

const FallbackRedirector = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const prevRoute = useRecoilValue(previousRoute);

  // 경로와 대응되는 컴포넌트가 존재하는지 여부를 체크
  const isPathValid = (pathname: string): string => {
    let path = "";
    if (
      pathname === ROUTE_PATH.QUESTIONBOARD ||
      pathname === ROUTE_PATH.FREEBOARD ||
      pathname === ROUTE_PATH.COUNSEL ||
      pathname === ROUTE_PATH.NAVERBAND
    ) {
      path = "band";
    } else if (pathname === ROUTE_PATH.WELFAREDEVICE) {
      path = "welfare";
    } else if (pathname === ROUTE_PATH.SUBSIDY) {
      path = "subsidy";
    }
    return path;
  };

  useEffect(() => {
    const path = isPathValid(location.pathname);
    if (path) {
      if (path === "band") window.open(LINK_PATH.BAND, "_blank");
      else if (path === "welfare") window.open(LINK_PATH.WELFARE_EQUIPMENT, "_blank");
      else if (path === "subsidy") window.open(LINK_PATH.SUBSIDY, "_blank");

      navigator(prevRoute.path); // 이전 화면으로 돌아가기
    }
  }, [location, navigator, prevRoute.path]);

  return null; // 빈 컴포넌트
};

export default FallbackRedirector;
