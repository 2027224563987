import Assessment from "../../../components/CareGrade/Assessment";

const AssessmentPage = () => {
  return (
    <main className="page-main-container">
      <Assessment />
    </main>
  );
};

export default AssessmentPage;
