import "./styles.scss";

interface Props {
  title: string;
  children: any;
}

const NewMypageTemplate: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="mypage__content">
      <div className="mypage__content__title-wrap">
        <h3>{title}</h3>
      </div>
      <div className="mypage__content__main-wrap">{children}</div>
    </div>
  );
};

export default NewMypageTemplate;
