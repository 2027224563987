import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { genderState } from "../../recoil/atoms";
import { GENDER_TYPE } from "../../constants/user";
import { useInput } from "../../hooks/useInput";
import RadioButton from "../Radio/RadioButton";
import ErrorText from "../ErrorText";

interface Props {
  labelType?: string;
  propVal?: string;
  hasError?: boolean;
}

const GenderForm: React.FC<Props> = ({ labelType, propVal, hasError }) => {
  const [genderValue, setGenderValue] = useRecoilState(genderState);
  const {
    text: gender,
    setText: setGender,
    handleChange: handelChangeGender,
    error: genderError,
    setError: setGenserError,
    errorMsg: genderErrorMessage,
    setErrorMsg: setGenderErrorMessage,
  } = useInput("");

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    // 회원정보 수정 - 성별 입력 값 체크 시 사용
    if (propVal) {
      setGender(propVal);
      setGenderValue({ ...genderValue, gender: propVal });
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (hasError) {
      setGenserError(hasError);
      setGenderErrorMessage("필수 선택 항목입니다.");
    }
  }, [hasError]);

  useEffect(() => {
    setGenderValue({ ...genderValue, gender });
    setGenderErrorMessage("");
  }, [gender]);

  return (
    <div className="gender-form__container">
      <label className={labelType}>성별</label>
      <div className="gender-form__content">
        <div className="gender-form__btn-wrap">
          <RadioButton
            id="male"
            name="gender"
            value={GENDER_TYPE.male}
            checked={gender === GENDER_TYPE.male}
            valueText={"남자"}
            disabled={disabled}
            onChange={handelChangeGender}
          />
          <RadioButton
            id="female"
            name="gender"
            value={GENDER_TYPE.female}
            checked={gender === GENDER_TYPE.female}
            valueText={"여자"}
            disabled={disabled}
            onChange={handelChangeGender}
          />
        </div>
        {genderError && <ErrorText text={genderErrorMessage} />}
      </div>
    </div>
  );
};

export default GenderForm;
