import "./styles.scss";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { assessmentState, previousRoute } from "../../../recoil/atoms";
import { useLocation } from "react-router-dom";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";
import { CARE_GRADE_TYPE, CARE_GRADE_RESULT_TYPES } from "../../../constants/careGradeResultTypes";
import CommonButton from "../../Button/CommonButton";
import StrokeButton from "../../Button/StrokeButton";
import CareGirl from "../../svg/img/CareGirl";
import Cane from "../../svg/icon/Cane";
import Matress from "../../svg/icon/Matress";
import WheelChair from "../../svg/icon/WheelChair";

const CareGradeResult = () => {
  const location = useLocation();
  const setPreviousRoute = useSetRecoilState(previousRoute);

  const { goTo } = useNavigateHook();
  const assessmentResult = useRecoilValue(assessmentState);
  const type = assessmentResult.rate as CARE_GRADE_TYPE;
  const { CARE_GRADE, CAREGRADE_DESC, CAREGRADE_INFO } = CARE_GRADE_RESULT_TYPES[type];

  useEffect(() => {
    if (!assessmentResult.isResult) {
      alert("진행한 설문이 없습니다.\n설문 메인으로 이동합니다.");
      goTo(ROUTE_PATH.CAREGRADE);
    }
  }, []);

  // 추천 복지용구 자세히 알아보기 버튼
  const onClickWelfareDetail = () => {
    goTo(ROUTE_PATH.WELFAREDEVICE);
    setPreviousRoute({ path: location.pathname });
  };

  return (
    <div className="care-grade-result__container">
      <div className="care-grade-result__bg">
        <div className="care-grade-result__title-wrap">
          <div className="care-grade-result__title">
            <h2>예상 요양 등급 결과</h2>
            <p>※실제 등급과 편차가 있을 수 있습니다</p>
          </div>
          <div className="care-grade-result__grade-wrap">
            <div className="grade-box">{CARE_GRADE}</div>
            <p>{CAREGRADE_DESC}</p>
          </div>
        </div>
        <div className="care-grade-result__bg__rect"></div>
        <div className="care-grade-result__bg__img-wrap">
          <CareGirl />
        </div>
      </div>
      <div className="care-grade-result__content">
        <div className="care-grade-result__content__score">
          예상 인정 점수 : <span>{assessmentResult.score}점</span>
        </div>
        <div className="care-grade-result__content__desc">
          <div className="desc-text">
            <p>요양원 이용 시</p>
            <p className="txt-gray">
              전체 비용의
              <span> {CAREGRADE_INFO.NURSING_HOME_EXPENSE.PERCENT}</span>인
              <span> {CAREGRADE_INFO.NURSING_HOME_EXPENSE.COST}</span> 으로 이용하실 수 있습니다. (1개월 기준)
            </p>
          </div>
          <div className="desc-text">
            <p>방문간호, 방문요양 이용 시</p>
            <p className="txt-gray">
              전체 비용의
              <span> {CAREGRADE_INFO.VISITATION_EXPENSE.PERCENT}</span>인
              <span> {CAREGRADE_INFO.VISITATION_EXPENSE.COST}</span> 으로 이용하실 수 있습니다. (1시간 기준)
            </p>
            <p className="txt-gray">
              월 한도액은 <span>{CAREGRADE_INFO.VISITATION_LIMIT_AMOUNT}원</span> 입니다.
            </p>
          </div>
          <div className="desc-text">
            <p>데이케어센터 이용 시</p>
            <p className="txt-gray">
              전체 비용의
              <span> {CAREGRADE_INFO.DAYCARE_CENTER_EXPENSE.PERCENT}</span>인
              <span> {CAREGRADE_INFO.DAYCARE_CENTER_EXPENSE.COST}</span> 으로 이용하실 수 있습니다. (20일 기준)
            </p>
          </div>
          <div className="caution">
            <p className="txt-gray">
              ※ 상기 내용은 비급여 항목을 제외한 것으로, 이용하시는 시설마다 차이가 있을수 있습니다.
            </p>
            <p className="txt-gray">※ 소득 범위에 따라 실제 부담금은 더 낮아질 수 있습니다.</p>
          </div>
        </div>
        <div className="care-grade-result__content__recommand">
          <h3>추천 복지용구</h3>
          <div className="care-grade-result__content__recommand__box">
            <div className="care-grade-result__content__recommand__item-wrap">
              <div className="care-grade-result__content__recommand__item">
                <Cane width="48" height="48" />
                <span>지팡이</span>
                <StrokeButton
                  text={"자세히 알아보기"}
                  size={"md"}
                  strokeColor={"gray"}
                  onClick={onClickWelfareDetail}
                />
              </div>
              <div className="care-grade-result__content__recommand__item">
                <WheelChair width="48" height="48" />
                <span>휠체어</span>
                <StrokeButton
                  text={"자세히 알아보기"}
                  size={"md"}
                  strokeColor={"gray"}
                  onClick={onClickWelfareDetail}
                />
              </div>
              <div className="care-grade-result__content__recommand__item">
                <Matress width="48" height="48" />
                <span>욕창예방방석</span>
                <StrokeButton
                  text={"자세히 알아보기"}
                  size={"md"}
                  strokeColor={"gray"}
                  onClick={onClickWelfareDetail}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="care-grade-result__btn-wrap">
          <CommonButton text={"회원가입하고 지원금 확인하기"} size={"xl"} onClick={() => goTo(ROUTE_PATH.SIGN_UP2)} />
        </div>
      </div>
    </div>
  );
};

export default CareGradeResult;
