import { accountsInstance, authInstance } from "./index";
import { ISignUp, IUserInfo } from "../constants/interface";

/**
 * 회원가입
 */
// 회원가입
export const postSignUp = async (data: any) => {
  return await accountsInstance.post("/join/", data);
};

// 회원가입 - 신규
export const postNewSignUp = async (data: ISignUp) => {
  return await accountsInstance.post("/join", data);
};

// 아이디 중복확인
export const getCheckId = async (username: string) => {
  return await accountsInstance.get(`/join/check-id/${username}`);
};

// 닉네임 중복확인
export const getCheckName = async (nickname: string) => {
  return await accountsInstance.get(`/join/check-nickname/${nickname}`);
};

/**
 * 로그인
 */
// 로그인
export const postLogin = async (data: any) => {
  return await accountsInstance.post("/login", data);
};

// 로그인 - 신규
export const postNewLogin = async ({ username, password }: { username: string; password: string }) => {
  return await accountsInstance.post("/login", {
    username,
    password,
  });
};

// 로그아웃
export const postLogout = async () => {
  return await authInstance.post("/logout");
};

/**
 * 휴대폰 인증
 */
// 휴대폰 인증 요청 - 회원가입용
export const postPhoneAuth = async ({ phone }: { phone: string }) => {
  return await accountsInstance.post("/auth/request/phone", { phone });
};

// 휴대폰 인증 요청 - 비밀번호 찾기용
export const postPhoneAuthMember = async ({ username, phone }: { username: string; phone: string }) => {
  return await accountsInstance.post("/auth/request", {
    username,
    phone,
  });
};

// 휴대폰 인증번호 확인
export const postAuthVerify = async ({ phone, code }: { phone: string; code: string }) => {
  return await accountsInstance.post("/auth/verify", {
    phone,
    code,
  });
};

/**
 * 아이디/비밀번호 찾기 및 변경
 */
// 이름 인증 - 아이디 찾기
export const postAuthName = async ({ name, phone }: { name: string; phone: string }) => {
  return await accountsInstance.post("/auth/request/name", {
    name,
    phone,
  });
};

// 아이디 찾기
export const getUserId = async ({ phone }: { phone: string }) => {
  return await accountsInstance.post("/users/id", { phone });
};

// 아이디 인증(비회원) - 비밀번호 찾기
export const getAuthUser = async ({ username, phone }: { username: string; phone: string }) => {
  return await accountsInstance.post("/auth/request/id", {
    username,
    phone,
  });
};

// 비밀번호 변경(비회원) - 비밀번호 찾기
export const postChangePassword = async ({ username, password }: { username: string; password: string }) => {
  return await accountsInstance.post("/users/password", {
    username,
    password,
  });
};

/**
 * 마이페이지
 */
// 회원정보 조회
export const getProfile = async () => {
  return await authInstance.get("/users/profiles");
};

// 회원정보 수정
export const patchProfile = async (data: IUserInfo) => {
  return await authInstance.patch("/users/profiles", data);
};

// 비밀번호 확인(회원) - 비밀번호 변경
export const postCheckPassword = async ({ password }: { password: string }) => {
  return await authInstance.post("/users/check-pw", { password });
};

// 비밀번호 변경(회원)
export const postChangeUserPassword = async ({ password }: { password: string }) => {
  return await authInstance.post("/users/password", { password });
};

// 회원탈퇴
export const postWithdrawal = async () => {
  return await authInstance.post("/withdrawal");
};

// 토큰 재발급
export const postTokenRefresh = async ({ refresh }: { refresh: string | null }) => {
  return await accountsInstance.post("/users/token/refresh", { refresh });
};
