import "./styles.scss";

interface Props {
  id: string;
  name: string;
  value: string;
  checked?: boolean;
  valueText: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<Props> = ({ id, name, value, checked, valueText, disabled, onChange }) => {
  return (
    <div className="radio-btn-wrap">
      <input type="radio" id={id} name={name} value={value} checked={checked} disabled={disabled} onChange={onChange} />
      <label htmlFor={id}>{valueText}</label>
    </div>
  );
};

export default RadioButton;
