import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { phoneAuthState, searchIdState } from "../../../../recoil/atoms";
import { getUserId } from "../../../../apis/accounts";
import SearchTemplate from "../../SearchTemplate";
import ColInput from "../../../Input/ColInput";
import CommonButton from "../../../Button/CommonButton";
import { useInput } from "../../../../hooks/useInput";
import SearchPhoneAuthForm from "../../../SearchPhoneAuthForm";
import ErrorText from "../../../ErrorText";
import { isFormValid } from "../../../../common/function";
import { SIGNUP_REGEXP } from "../../../../constants/signup";

interface Props {
  setIsCheckId: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckPw: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchIdForm: React.FC<Props> = ({ setIsCheckId, setIsCheckPw }) => {
  const { phone, isSuccess } = useRecoilValue(phoneAuthState);
  const [searchId, setSearchId] = useRecoilState(searchIdState);

  const { text: name, handleChange: handleChangeName } = useInput("");

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isCheckSend, setIsCheckSend] = useState<boolean>(false);
  const [isCheckForm, setIsCheckForm] = useState<boolean>(false);

  useEffect(() => {
    // 비밀번호 찾기 탭 초기화
    setIsCheckPw(false);
  }, [setIsCheckPw]);

  useEffect(() => {
    // 휴대전화 인증번호 요청 시 사용
    setSearchId({ ...searchId, name });
  }, [name, searchId, setSearchId]);

  // 아이디 찾기
  const handelSearchId = () => {
    if (isSuccess) {
      getUserId({ phone })
        .then((res) => {
          // console.log("아이디 찾기 성공", res);
          const { id, date } = res.data.result;
          setSearchId({ ...searchId, id, date });
          setIsCheckId(true);

          setError(false);
          setErrorMessage("");
        })
        .catch((error) => {
          console.error("아이디 찾기 실패", error);

          const { message } = error.response.data;
          setError(true);
          setErrorMessage(message);
        });
    }
  };

  useEffect(() => {
    // 버튼 활성화 조건
    const flag = isFormValid({ name, phone }, SIGNUP_REGEXP);
    setIsCheckForm(flag);
  }, [name, phone]);

  return (
    <SearchTemplate title="아이디 찾기" tabIndex={1}>
      <div className="search-id-form__container">
        <div className="search-id-form__input-wrap">
          <ColInput
            labelText="이름"
            type="text"
            name="name"
            value={name}
            placeholder="이름을 입력해주세요"
            onChange={handleChangeName}
          />
          <SearchPhoneAuthForm
            formType={"searchId"}
            isCheckForm={isCheckForm}
            isCheckSend={isCheckSend}
            setIsCheckSend={setIsCheckSend}
          />
        </div>
        {error && <ErrorText text={errorMessage} />}
        {isCheckSend && (
          <CommonButton text={"아이디 찾기"} size={"xl"} disabled={!isSuccess} onClick={handelSearchId} />
        )}
      </div>
    </SearchTemplate>
  );
};

export default SearchIdForm;
