import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Cane: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.42878 2.64188C10.6873 1.90202 12.3432 1.67106 14.2788 2.65701C15.2331 3.14307 15.9146 3.76705 16.3586 4.48466C16.8014 5.20016 16.9748 5.95462 16.9971 6.65377C17.0404 8.01421 16.5155 9.22183 16.2011 9.79021L9.87948 21.4759C9.6167 21.9616 9.00988 22.1424 8.52412 21.8796C8.03836 21.6168 7.85761 21.01 8.12039 20.5243L14.4493 8.82517C14.6753 8.41755 15.0254 7.57615 14.9981 6.71739C14.9849 6.30503 14.8858 5.90525 14.658 5.53712C14.4314 5.1711 14.0443 4.78208 13.3711 4.43914C12.0517 3.7671 11.12 3.96763 10.4423 4.36603C9.69454 4.80563 9.15964 5.55218 8.87392 6.06604C8.60553 6.54872 7.99666 6.72245 7.51398 6.45406C7.03129 6.18567 6.85757 5.5768 7.12596 5.09412C7.49024 4.43898 8.24034 3.34052 9.42878 2.64188Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Cane;
