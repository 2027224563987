import CareGradeHome from "../../../components/CareGrade/CareGradeHome";

const CareGradeHomePage = () => {
  return (
    <main className="page-main-container">
      <CareGradeHome />
    </main>
  );
};

export default CareGradeHomePage;
