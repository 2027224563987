import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { assessmentState, genderState, responsesState } from "../../../recoil/atoms";
import { postCareGradeDetail, postCareGradeExternal } from "../../../apis/healths";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_REGEXP } from "../../../constants/signup";
import { ROUTE_PATH } from "../../../constants/routePath";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { useInput } from "../../../hooks/useInput";
import { checkIfAnyEmpty, getCookieByKey, isFormValid } from "../../../common/function";
import CommonButton from "../../Button/CommonButton";
import CommonInput from "../../Input/CommonInput";
import GenderForm from "../../GenderForm";

const AssessmentInfoForm = () => {
  const { goTo } = useNavigateHook();
  const isLogin = getCookieByKey("accessToken");

  const { gender } = useRecoilValue(genderState);
  const responses = useRecoilValue(responsesState);
  const setAssessmentResult = useSetRecoilState(assessmentState);

  const {
    text: age,
    handleChange: handleChangeAge,
    error: ageError,
    errorMsg: ageErrorMsg,
  } = useInput("", SIGNUP_REGEXP.AGE, SIGNUP_ERROR_MESSAGE.AGE);

  const [isCheckForm, setIsCheckForm] = useState<boolean>(false);

  useEffect(() => {
    if (checkIfAnyEmpty(responses)) {
      alert("진행한 설문이 없습니다.\n설문 메인으로 이동합니다.");
      goTo(ROUTE_PATH.CAREGRADE);
    }
  }, [responses, goTo]);

  // 결과 확인하기(요양 등급 확인 - 비회원일 경우(간소화))
  const handleCheckResult = () => {
    if (isCheckForm && responses) {
      const requestBody = {
        data: responses,
        gender,
        age,
      };
      if (!isLogin) {
        postCareGradeExternal(requestBody)
          .then((res) => {
            // console.log("요양등급검사 비회원 조회 성공", res);
            const { rate, score } = res.data.result;
            setAssessmentResult({ rate, score, isResult: true });
            goTo(ROUTE_PATH.CAREGRADERESULT);
          })
          .catch((error) => {
            console.error("요양등급검사 비회원 조회 실패", error);
            // 오류 발생 시 요양 등급 확인 첫 화면으로 이동
            alert("요양 등급 확인 중 오류가 발생하였습니다.\n다시 수행해주세요.");
            goTo(ROUTE_PATH.CAREGRADE);
          });
      }
      // 회원일 경우(구체화)
      else {
        postCareGradeDetail(requestBody)
          .then((res) => {
            console.log("요양등급검사 회원 조회 성공", res);
            const { rate, score } = res.data.result;
            setAssessmentResult({ rate, score, isResult: true });
            goTo(ROUTE_PATH.CAREGRADERESULT);
          })
          .catch((error) => {
            console.error("요양등급검사 회원 조회 실패", error);
            // 오류 발생 시 요양 등급 확인 첫 화면으로 이동
            alert("요양 등급 확인 중 오류가 발생하였습니다.\n다시 수행해주세요.");
            goTo(ROUTE_PATH.CAREGRADE);
          });
      }
    }
  };

  useEffect(() => {
    const flag = isFormValid({ age, gender }, SIGNUP_REGEXP);
    setIsCheckForm(flag);
  }, [age, gender]);

  return (
    <>
      <div className="assessment__title-wrap">
        <h2 className="primary">결과 확인하기</h2>
        <h3 className="sub-title">결과 확인을 위해 정보를 입력하세요</h3>
      </div>
      <div className="assessment-info-form__content">
        <div className="assessment-info-form__notice-box">
          <p className="txt-title">안내사항</p>
          <p>{`본 테스트는 건강보험공단의 장기 요양인정\n조사표의 설문 항목을 기반으로 제작되었습니다.`}</p>
        </div>
        <div className="assessment-info-form__input-wrap">
          <CommonInput
            labelText={"나이"}
            type="text"
            name="age"
            value={age}
            error={ageError}
            errorMsg={ageErrorMsg}
            onChange={handleChangeAge}
          />
          <GenderForm />
        </div>
        <div className="assessment__btn-wrap">
          <CommonButton text={"결과 확인하기"} size={"xl"} disabled={!isCheckForm} onClick={handleCheckResult} />
        </div>
      </div>
    </>
  );
};

export default AssessmentInfoForm;
