import "./styles.scss";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { IResponses, responsesState } from "../../../recoil/atoms";
import RadioButton from "../../Radio/RadioButton";
import CommonButton from "../../Button/CommonButton";
import StrokeButton from "../../Button/StrokeButton";

interface Props {
  currentIndex: number;
  questionType: any;
  handleClickNext: () => void;
  handleClickPrev: () => void;
  onUnansweredQuestionUpdate: (unansweredQuestionIndex: number) => void;
}

const AccessmentForm: React.FC<Props> = ({
  currentIndex,
  questionType,
  handleClickPrev,
  handleClickNext,
  onUnansweredQuestionUpdate,
}) => {
  const [response, setResponses] = useRecoilState(responsesState);

  // 각 questionItem에 대한 ref 배열 생성
  const questionRefs = useRef<HTMLDivElement[]>([]);

  // 각 질문이 처음으로 렌더링 될 때 ref 업데이트
  useEffect(() => {
    // 선택되지 않은 질문의 첫 번째 index를 부모에게 업데이트
    const unansweredIndex = questionType.questionList.findIndex(
      (questionItem: any) => !response[questionType.type as keyof IResponses]?.[questionItem.id],
    );
    onUnansweredQuestionUpdate(unansweredIndex);
  }, [response, questionType.questionList, questionType.type, onUnansweredQuestionUpdate]);

  // 설문지 선택 onChange 이벤트
  const handleResponseChange = (type: string, questionId: string, answerValue: string, index: number) => {
    setResponses((prevResponses: any) => {
      const updatedResponses = {
        ...prevResponses,
        [type]: {
          ...prevResponses[type],
          [questionId]: answerValue,
        },
      };
      return updatedResponses;
    });

    // 다음 questionItem으로 스크롤
    const nextIndex = index + 1;
    const nextElement = questionRefs.current.filter((el) => el !== null)[nextIndex];
    if (nextElement) {
      questionRefs.current[nextIndex].scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <div className="assessment__title-wrap">
        <h2 className="primary">{questionType.title}</h2>
        <h3 className="sub-title">{questionType.desc}</h3>
      </div>
      <div className="assessment__content">
        {questionType.questionList.map((questionItem: any, questionIndex: number) => {
          const currentResponse = response[questionType.type as keyof IResponses]?.[questionItem.id];

          return (
            <div
              className="assessment-form__item"
              key={questionItem.id}
              ref={(el) => (questionRefs.current[questionIndex] = el!)}
            >
              <p>{`${questionIndex + 1}. ${questionItem.message}`}</p>
              <div className="assessment-form__answer-box">
                {questionItem.answer.map((answerItem: any, answerIndex: number) => {
                  return (
                    <RadioButton
                      key={answerIndex}
                      id={`${questionItem.id}_${answerIndex}`}
                      name={`${questionItem.id}_answer`}
                      value={`${answerItem.value}`}
                      checked={currentResponse === `${answerItem.value}`} // checked 값 설정
                      valueText={answerItem.message}
                      onChange={(e) =>
                        handleResponseChange(questionType.type, questionItem.id, e.target.value, questionIndex)
                      }
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="assessment__btn-wrap">
          {currentIndex !== 1 && (
            <StrokeButton text={"이전"} size={"xl"} strokeColor={"gray"} onClick={handleClickPrev} />
          )}
          <CommonButton text={"다음"} size={"xl"} onClick={handleClickNext} />
        </div>
      </div>
    </>
  );
};

export default AccessmentForm;
