import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const NaverBand: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#19CB1D"
      />
      <g clipPath="url(#clip0_920_649)">
        <path
          d="M16.0023 10.3641C15.3774 10.3641 14.7752 10.4401 14.1956 10.5875V9.21605C14.1956 9.18478 14.1956 9.15351 14.1865 9.12224C14.0733 7.6168 12.8824 6.5 11.4334 6.5C9.98438 6.5 8.79348 7.61233 8.67574 9.12224C8.67122 9.15351 8.66669 14.2729 8.66669 14.2729V17.601C8.66669 17.6233 8.66669 17.6456 8.66669 17.6724C8.70291 21.6348 11.9722 24.8378 16.0023 24.8378C20.0323 24.8378 23.3379 21.5991 23.3379 17.601C23.3379 13.6028 20.055 10.3641 16.0023 10.3641ZM16.0023 23.721C12.5745 23.721 9.7942 20.9782 9.7942 17.5965V9.26519C9.7942 8.36282 10.5278 7.63467 11.4334 7.63467C12.339 7.63467 13.0726 8.36282 13.0726 9.26519V17.525C13.0726 19.1243 14.3812 20.5002 16.0068 20.5002C17.6324 20.5002 18.9456 19.2002 18.9456 17.601C18.9456 16.0017 17.6279 14.7018 16.0068 14.7018C15.3276 14.7018 14.6982 14.9296 14.2001 15.3138V14.0719C14.7435 13.7994 15.3548 13.6475 16.0068 13.6475C18.2211 13.6475 20.0142 15.4165 20.0142 17.601C20.0142 19.7854 18.2211 21.5544 16.0068 21.5544C13.7925 21.5544 11.9994 19.7854 11.9994 17.601V9.21158C11.9994 8.93908 11.7458 8.67999 11.4379 8.67999C11.13 8.67999 10.8764 8.93908 10.8764 9.21158V17.5965C10.8764 17.726 10.881 17.8511 10.89 17.9762C11.0847 20.5984 13.3035 22.6623 16.0068 22.6623C18.7101 22.6623 21.1417 20.393 21.1417 17.5965C21.1417 14.8 18.8414 12.5307 16.0068 12.5307C15.3729 12.5307 14.7616 12.6468 14.2001 12.8523V11.7355C14.7706 11.5658 15.3774 11.472 16.0068 11.472C19.4346 11.472 22.2149 14.2148 22.2149 17.5965C22.2149 20.9782 19.4346 23.721 16.0068 23.721H16.0023ZM14.1956 17.601C14.1956 16.6182 15.0061 15.8186 16.0023 15.8186C16.9985 15.8186 17.809 16.6182 17.809 17.601C17.809 18.5837 16.9985 19.3834 16.0023 19.3834C15.0061 19.3834 14.1956 18.5837 14.1956 17.601Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_920_649">
          <rect width="14.6667" height="18.3333" fill="white" transform="translate(8.66669 6.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NaverBand;
