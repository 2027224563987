import { useState } from "react";
import { postCheckPassword } from "../../../../apis/accounts";
import { useInput } from "../../../../hooks/useInput";
import CommonButton from "../../../Button/CommonButton";
import PasswordInput from "../../../Input/PasswordInput";

interface Props {
  setIsCheckPw: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckPasswordForm: React.FC<Props> = ({ setIsCheckPw }) => {
  const {
    text: password,
    handleChange: handelChangePassword,
    error: passwordError,
    setError: setPasswordError,
    inputRef: passwordRef,
  } = useInput("");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const handleShowPassword = () => setIsShowPassword(!isShowPassword);

  // 현재 비밀번호 확인
  const handleCheckPassword = () => {
    const requestBody = { password };
    postCheckPassword(requestBody)
      .then(() => {
        // console.log("비밀번호 확인 성공:", res);
        setPasswordError(false);
        setIsCheckPw(true);
      })
      .catch((error) => {
        console.error("비밀번호 확인 실패:", error);
        if (passwordRef.current) {
          passwordRef.current.focus();
        }
        setPasswordError(true);
        setErrorMessage(error.data.message);
      });
  };

  // 비밀번호 확인 엔터 이벤트
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleCheckPassword();
    }
  };

  return (
    <>
      <div className="mypage__content__input-wrap">
        <PasswordInput
          labelText={"현재 비밀번호"}
          type={isShowPassword ? "text" : "password"}
          name="password"
          value={password}
          placeholder="현재 비밀번호를 입력해주세요"
          error={passwordError}
          errorMsg={errorMessage}
          onChange={handelChangePassword}
          ref={passwordRef}
          onKeyDown={handleKeyDown}
          isShowPassword={isShowPassword}
          handleShowPassword={handleShowPassword}
        />
      </div>
      <div className="mypage__content__btn-wrap">
        <CommonButton text={"비밀번호 확인"} size={"xl"} disabled={!password} onClick={handleCheckPassword} />
      </div>
    </>
  );
};

export default CheckPasswordForm;
