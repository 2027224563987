import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const EyeOn: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87868 9.87868C10.4413 9.31607 11.2043 9 12 9C12.7957 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2043 15 12C15 12.7957 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7957 15 12 15C11.2043 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7957 9 12C9 11.2043 9.31607 10.4413 9.87868 9.87868ZM12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11Z"
        fill="#191919"
      />
      <path
        d="M4.17703 12C6.39038 15.4411 9.00114 17 12 17C14.9989 17 17.6096 15.4411 19.823 12C17.6096 8.55893 14.9989 7 12 7C9.00114 7 6.39038 8.55893 4.17703 12ZM2.14251 11.4855C4.66219 7.28603 7.94444 5 12 5C16.0556 5 19.3378 7.28603 21.8575 11.4855C22.0475 11.8022 22.0475 12.1978 21.8575 12.5145C19.3378 16.714 16.0556 19 12 19C7.94444 19 4.66219 16.714 2.14251 12.5145C1.9525 12.1978 1.9525 11.8022 2.14251 11.4855Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default EyeOn;
