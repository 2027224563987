import { healthsAccountsInstance, healthsInstance } from "./index";

export const postCareGradeExternal = async (data: any) => {
  return await healthsInstance.post("/caregrade/ex", data);
};

export const postCareGradeSimple = async (data: any) => {
  return await healthsInstance.post("/caregrade/simple", data);
};

export const postCareGradeDetail = async (data: any) => {
  return await healthsAccountsInstance.post("/caregrade/detail", data);
};
