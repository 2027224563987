import "./styles.scss";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";

const MypageSidebar = () => {
  const { goTo } = useNavigateHook();
  const location = useLocation();

  const isSelected = (path: string) => location.pathname === path;

  return (
    <div className="mypage-sidebar__container">
      <h3>홍길동님</h3>
      <div className="mypage-sidebar__content">
        <div className="mypage-sidebar__menu">
          <p>프로필</p>
          <ul className="mypage-sidebar__menu-ul">
            <li
              className={classNames({ selected: isSelected(ROUTE_PATH.USERINFO) })}
              onClick={() => goTo(ROUTE_PATH.USERINFO)}
            >
              회원정보 조회
            </li>
            <li
              className={classNames({ selected: isSelected(ROUTE_PATH.CHANGEPW) })}
              onClick={() => goTo(ROUTE_PATH.CHANGEPW)}
            >
              비밀번호 변경
            </li>
            <li
              className={classNames({ selected: isSelected(ROUTE_PATH.CANCELUSER) })}
              onClick={() => goTo(ROUTE_PATH.CANCELUSER)}
            >
              회원탈퇴
            </li>
          </ul>
        </div>
        <div className="mypage-sidebar__menu">
          <p>활동</p>
          <ul className="mypage-sidebar__menu-ul">
            <li onClick={() => alert("아직 준비중입니다.")}>요양등급검사</li>
            <li onClick={() => alert("아직 준비중입니다.")}>내가 쓴 글</li>
            <li onClick={() => alert("아직 준비중입니다.")}>상담내용</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MypageSidebar;
