import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const EyeOff: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.292 9.87968C11.6826 10.2701 11.6828 10.9033 11.2923 11.2939C11.1048 11.4815 10.9995 11.7359 10.9995 12.0011C10.9996 12.2663 11.105 12.5207 11.2925 12.7082C11.4801 12.8957 11.7345 13.001 11.9997 13.001C12.265 13.0009 12.5193 12.8955 12.7068 12.7079C13.0973 12.3173 13.7305 12.3172 14.121 12.7077C14.5116 13.0981 14.5117 13.7313 14.1213 14.1219C13.5588 14.6846 12.7958 15.0008 12.0001 15.001C11.2044 15.0011 10.4413 14.6852 9.87858 14.1226C9.31586 13.5601 8.99965 12.7971 8.99951 12.0014C8.99937 11.2058 9.31531 10.4427 9.87783 9.87993C10.2683 9.48934 10.9015 9.48923 11.292 9.87968Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L17.3072 15.893C17.3627 15.9375 17.4139 15.9885 17.4598 16.0456L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L16.5075 17.9217C15.1167 18.6388 13.5692 19.0107 11.9964 19C7.94252 18.9987 4.66145 16.7127 2.14251 12.5145C1.9525 12.1978 1.9525 11.8022 2.14251 11.4855C3.21193 9.70314 4.41907 8.25995 5.77593 7.19015L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289ZM7.20214 8.61635C6.13029 9.41881 5.11853 10.537 4.17707 12.0001C6.39041 15.4411 9.00116 17 12 17H12.0072V17C13.0389 17.0074 14.057 16.8074 15.0025 16.4168L7.20214 8.61635ZM11.9968 7C11.4535 6.99826 10.9115 7.05187 10.379 7.15999C9.83779 7.26991 9.30992 6.92026 9.20001 6.37902C9.09009 5.83779 9.43974 5.30992 9.98098 5.20001C10.646 5.06495 11.323 4.99794 12.0016 5C16.0564 5.00061 19.3381 7.2866 21.8575 11.4855C22.0476 11.8023 22.0475 12.1981 21.8573 12.5149C21.1597 13.6764 20.4048 14.6929 19.589 15.5566C19.2098 15.9581 18.5769 15.9762 18.1754 15.597C17.7739 15.2178 17.7558 14.5849 18.135 14.1834C18.7199 13.564 19.2832 12.8376 19.8228 11.9997C17.6095 8.55885 14.9988 7 12 7L11.9968 7Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default EyeOff;
