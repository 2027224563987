import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer__container">
      <div className="footer__content">
        <h3>어부바</h3>
        <div className="footer__content__explain">
          <p>법인명: (주) 어부바</p>
          <p>대표자: 이일광</p>
          <p>개인정보보호 책임자: 이준영</p>
          <p>이메일: admin@obubo.co.kr</p>
        </div>
        <div className="footer__content__term-wrap">
          <span>이용약관</span>
          <span>개인정보처리방침</span>
        </div>
        <p>Copyright by Obubo Inc.All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
