interface Props {
  width: string;
  height: string;
}

const BathChair: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.7871 21.3368C37.2326 20.8656 36.4831 20.771 35.831 21.0897L33.5794 22.1904C28.7866 24.5331 23.4483 25.5378 18.1424 25.097L7.8809 24.2437C7.29886 24.1946 6.72595 24.4354 6.3485 24.8861C5.97134 25.3364 5.83142 25.9469 5.97429 26.519L7.68026 33.3517C8.13793 35.1845 9.36186 36.6547 10.9547 37.4495C10.6094 37.987 10.4235 38.6136 10.4235 39.2712V40.0722C10.4235 40.2519 10.2783 40.398 10.0998 40.398C9.50386 40.398 9.02073 40.8867 9.02073 41.4893C9.02073 42.092 9.50386 42.5806 10.0998 42.5806C11.4682 42.5806 12.5816 41.4553 12.5816 40.0722V39.2712C12.5816 38.9527 12.7058 38.6546 12.9314 38.4319C13.1541 38.2119 13.4471 38.0918 13.7585 38.0977H27.9155C28.2262 38.0914 28.5186 38.2122 28.7411 38.4318C28.9666 38.6546 29.0908 38.9527 29.0908 39.2712V40.0722C29.0908 41.4554 30.2042 42.5806 31.5727 42.5806C32.1686 42.5806 32.6517 42.092 32.6517 41.4893C32.6517 40.8867 32.1686 40.398 31.5727 40.398C31.3942 40.398 31.2489 40.2518 31.2489 40.0722V39.2712C31.2489 38.7955 31.1513 38.3362 30.9671 37.9148C33.0963 37.3826 34.8508 35.7285 35.4685 33.5329L38.3641 23.2413C38.5622 22.5375 38.3411 21.8077 37.7871 21.3368ZM29.4881 35.9151H13.709C12.1449 35.9151 10.753 35.0163 10.0774 33.6478H33.1176C32.439 35.0124 31.0375 35.9151 29.4881 35.9151ZM33.8067 31.4652H9.43516L8.18512 26.4589L17.9656 27.2722C23.6555 27.7455 29.3794 26.6676 34.5185 24.1554L36.0777 23.3932L33.8067 31.4652Z"
        fill="black"
      />
      <path
        d="M43.0806 10.8661C43.0806 7.87453 40.668 5.43126 37.7019 5.41972L37.5956 5.41936C37.5892 5.41936 37.5828 5.41936 37.5762 5.41936C35.4553 5.41936 33.5246 6.67844 32.654 8.63134L31.2813 11.7102L30.5921 11.4571C30.0325 11.2518 29.4139 11.5432 29.2104 12.1083C29.0069 12.6734 29.2956 13.2981 29.8552 13.5035L33.2324 14.7435C33.354 14.7881 33.4784 14.8093 33.6008 14.8093C34.0412 14.8093 34.4548 14.5346 34.6141 14.0924C34.8176 13.5272 34.5288 12.9025 33.9693 12.6971L33.3135 12.4564L34.6203 9.52527C35.143 8.35263 36.3023 7.59683 37.578 7.59683C37.5818 7.59683 37.5859 7.59683 37.5897 7.59683L37.6941 7.59719C39.4752 7.60416 40.9243 9.07054 40.9243 10.8661L40.9244 40.4032H38.8123C38.2169 40.4032 37.7341 40.8906 37.7341 41.4919C37.7341 42.0932 38.2169 42.5806 38.8123 42.5806H42.0025C42.2885 42.5806 42.5627 42.466 42.7649 42.2617C42.9671 42.0575 43.0807 41.7806 43.0807 41.4919L43.0806 10.8661Z"
        fill="black"
      />
    </svg>
  );
};

export default BathChair;
