import Modal from "../ModalForm";

interface Props {
  isOpen: boolean;
  modalMsg: string;
  closeModal: () => void;
}

const NoticeModal: React.FC<Props> = ({ isOpen, modalMsg, closeModal }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      {modalMsg}
    </Modal>
  );
};

export default NoticeModal;
