import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { phoneAuthState, searchIdState, searchPwState } from "../../recoil/atoms";
import { getAuthUser, postAuthName, postAuthVerify } from "../../apis/accounts";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_REGEXP } from "../../constants/signup";
import { useInput } from "../../hooks/useInput";
import ColInput from "../Input/ColInput";
import TimerInput from "../Input/TimerInput";
import CommonButton from "../Button/CommonButton";
import ErrorText from "../ErrorText";

interface Props {
  formType: string;
  isCheckSend: boolean;
  isCheckForm: boolean;
  setIsCheckSend: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchPhoneAuthForm: React.FC<Props> = ({ formType, isCheckForm, isCheckSend, setIsCheckSend }) => {
  const { name } = useRecoilValue(searchIdState);
  const { id } = useRecoilValue(searchPwState);
  const [phoneAuth, setPhoneAuth] = useRecoilState(phoneAuthState);

  const { text: phone, handleChange: handleChangePhone, error: phoneError } = useInput("", SIGNUP_REGEXP.PHONE);
  const {
    text: authNum,
    handleChange: handleChangeAuthNum,
    error: authNumError,
    setError: setAuthNumError,
    errorMsg: authNumErrorMessage,
    setErrorMsg: setAuthNumErrorMessage,
  } = useInput("");

  const [buttonText, setButtonText] = useState<string>();

  const [isCheckAuth, setIsCheckAuth] = useState<boolean>(false); // 인증번호 전송 체크
  const [isClickAuthBtn, setIsClickAuthBtn] = useState<boolean>(false); // 인증번호 버튼 클릭 체크
  const [isCheckTime, setIsCheckTime] = useState<boolean>(false); // 시간종료 체크

  const [phoneDisabled, setPhoneDisable] = useState<boolean>(false);
  const [phoneBtnDisabled, setPhoneBtnDisable] = useState<boolean>(true);
  const [authNumDisabled, setAuthNumDisable] = useState<boolean>(false);
  const [authNumBtnDisabled, setAuthNumBtnDisable] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // 오류 메세지
  const [authNumMessage, setAuthNumMessage] = useState<string>("");

  useEffect(() => {
    setPhoneAuth({ ...phoneAuth, phone });
  }, [phone, phoneAuth, setPhoneAuth]);

  // 휴대폰 인증번호 요청
  const handleGetNumber = () => {
    if (formType === "searchId") {
      // 아이디 찾기
      const requestBody = {
        name,
        phone,
      };
      postAuthName(requestBody)
        .then(() => {
          // console.log("이름 인증 성공", res);
          alert("입력하신 휴대전화로 인증번호를 발송했습니다.");

          setIsCheckAuth(true);
          setIsClickAuthBtn(true);
          setButtonText("인증번호 재전송");
          setIsCheckSend(true);

          setPhoneDisable(true);

          setError(false);
          setErrorMessage("");

          // 휴대폰 입력 값 체크 시 사용
          setPhoneAuth({
            ...phoneAuth,
            phone,
            isSuccess: false,
          });
        })
        .catch((error) => {
          console.error("이름 인증 실패", error);

          const { message } = error.response.data;
          const errorMsg = message ? message : "이름과 전화번호를 다시 확인해주세요";

          setError(true);
          setErrorMessage(errorMsg);

          // 휴대폰 입력 값 체크 시 사용
          setPhoneAuth({
            ...phoneAuth,
            phone,
            isSuccess: false,
          });
        });
    } else if (formType === "searchPw") {
      // 비밀번호 찾기
      const requestBody = {
        username: id,
        phone,
      };
      getAuthUser(requestBody)
        .then(() => {
          // console.log("아이디 인증 성공:", res);
          alert("입력하신 휴대전화로 인증번호를 발송했습니다.");

          setError(false);
          setErrorMessage("");

          setIsCheckAuth(true);
          setIsClickAuthBtn(true);
          setButtonText("인증번호 재전송");
          setIsCheckSend(true);

          setPhoneDisable(true);

          // 휴대폰 입력 값 체크 시 사용
          setPhoneAuth({
            ...phoneAuth,
            phone,
            isSuccess: false,
          });
        })
        .catch((error) => {
          console.error("아이디 인증 실패:", error);

          const { message } = error.response.data;
          const errorMsg = message ? message : "아이디와 전화번호를 다시 확인해주세요";

          setError(true);
          setErrorMessage(errorMsg);

          setPhoneAuth({
            ...phoneAuth,
            phone,
            isSuccess: false,
          });
        });
    }
  };

  // 휴대폰 인증 확인
  const handleAuthVerify = () => {
    if (!authNum) {
      setAuthNumError(true);
      setAuthNumErrorMessage("인증번호를 입력해주세요");
    } else {
      if (!isCheckTime) {
        const requestBody = {
          phone,
          code: authNum,
        };
        postAuthVerify(requestBody)
          .then(() => {
            // console.log("휴대폰 인증 성공", res);

            setAuthNumError(false);
            setAuthNumMessage("인증에 성공하였습니다");

            setPhoneBtnDisable(true);
            setAuthNumDisable(true);
            setAuthNumBtnDisable(true);

            setPhoneAuth({ ...phoneAuth, isSuccess: true });
          })
          .catch((error) => {
            console.error("휴대폰 인증 실패", error);

            const { message } = error.response.data;
            setAuthNumError(true);
            setAuthNumErrorMessage(message);

            setPhoneAuth({ ...phoneAuth, isSuccess: false });
          });
      }
    }
  };

  useEffect(() => {
    if (isCheckTime) {
      // 나중에 모달로 대체
      alert("인증시간이 종료되었습니다. 인증전송을 다시 진행해주세요.");
    }
  }, [isCheckTime]);

  return (
    <div className="phone-auth-form">
      <ColInput
        labelText="휴대폰 번호"
        type="text"
        name="phone"
        value={phone}
        maxLength={11}
        placeholder="휴대폰 번호를 입력해주세요"
        buttonText={buttonText}
        disabled={phoneDisabled}
        btnDisabled={phoneBtnDisabled}
        error={phoneError}
        errorMsg={SIGNUP_ERROR_MESSAGE.PHONE}
        onChange={handleChangePhone}
        onClick={handleGetNumber}
      />
      {isCheckAuth && (
        <TimerInput
          labelText="인증번호"
          type="text"
          name="authNum"
          value={authNum}
          maxLength={6}
          placeholder="인증번호 6자리 입력"
          error={authNumError}
          errorMsg={authNumErrorMessage}
          okMsg={authNumMessage}
          buttonText={"인증번호 확인"}
          disabled={authNumDisabled}
          btnDisabled={authNumBtnDisabled}
          onChange={handleChangeAuthNum}
          isClickAuthBtn={isClickAuthBtn}
          setIsClickAuthBtn={setIsClickAuthBtn}
          setIsCheckTime={setIsCheckTime}
          onClick={handleAuthVerify}
        />
      )}
      {error && <ErrorText text={errorMessage} />}
      {!isCheckSend && (
        <CommonButton text={"인증번호 받기"} size={"xl"} disabled={!isCheckForm} onClick={handleGetNumber} />
      )}
    </div>
  );
};

export default SearchPhoneAuthForm;
