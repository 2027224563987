import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { phoneAuthState, searchPwState } from "../../../../recoil/atoms";
import { useInput } from "../../../../hooks/useInput";
import ColInput from "../../../Input/ColInput";
import CommonButton from "../../../Button/CommonButton";
import SearchTemplate from "../../SearchTemplate";
import SearchPhoneAuthForm from "../../../SearchPhoneAuthForm";
import { isFormValid } from "../../../../common/function";
import { SIGNUP_REGEXP } from "../../../../constants/signup";

interface Props {
  setIsCheckPw: React.Dispatch<any>;
}

const SearchPasswordForm: React.FC<Props> = ({ setIsCheckPw }) => {
  const { phone, isSuccess } = useRecoilValue(phoneAuthState);
  const setSearchPw = useSetRecoilState(searchPwState);

  const { text: id, handleChange: handleChangeId } = useInput("");

  const [isCheckSend, setIsCheckSend] = useState<boolean>(false);
  const [isCheckForm, setIsCheckForm] = useState<boolean>(false);

  useEffect(() => {
    // 휴대전화 인증번호 요청 시 사용
    setSearchPw({ id });
  }, [id]);

  // 비밀번호 찾기 - 휴대폰 인증
  const handleSearchPw = () => {
    if (isSuccess) {
      setIsCheckPw(true);
    }
  };

  useEffect(() => {
    // 버튼 활성화 조건
    const flag = isFormValid({ id, phone }, SIGNUP_REGEXP);
    setIsCheckForm(flag);
  }, [id, phone]);

  return (
    <SearchTemplate title={"비밀번호 찾기"} tabIndex={2}>
      <div className="search-password-form__container">
        <div className="search-password-form__input-wrap">
          <ColInput
            labelText="아이디"
            type="text"
            name="id"
            value={id}
            placeholder="아이디를 입력해주세요"
            onChange={handleChangeId}
          />
          <SearchPhoneAuthForm
            formType={"searchPw"}
            isCheckForm={isCheckForm}
            isCheckSend={isCheckSend}
            setIsCheckSend={setIsCheckSend}
          />
        </div>
        {isCheckSend && (
          <CommonButton text={"비밀번호 찾기"} size={"xl"} disabled={!isSuccess} onClick={handleSearchPw} />
        )}
      </div>
    </SearchTemplate>
  );
};

export default SearchPasswordForm;
