import "./styles.scss";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { searchIdState, searchPwState } from "../../../../recoil/atoms";
import { useNavigateHook } from "../../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../../constants/routePath";
import CommonButton from "../../../Button/CommonButton";
import StrokeButton from "../../../Button/StrokeButton";
import SearchTemplate from "../../SearchTemplate";
import Person from "../../../svg/icon/Person";

interface Props {
  setIsCheckId: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckPw: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchIdCompletion: React.FC<Props> = ({ setIsCheckId, setIsCheckPw }) => {
  const { goTo } = useNavigateHook();
  const { id, date } = useRecoilValue(searchIdState);
  const setId = useSetRecoilState(searchPwState);

  // 비밀번호 찾기
  const handelSearchPw = () => {
    setIsCheckId(false);
    setIsCheckPw(true);
    setId({ id }); // 비밀번호 재설정시 필요
    goTo(`${ROUTE_PATH.SEARCH}?type=pw`);
  };

  return (
    <SearchTemplate title="아이디 찾기">
      <div className="search-id-container">
        <h3>회원님의 정보와 일치하는 아이디입니다</h3>
        <div className="serach-id-box">
          <div className="ico-check-bg">
            <Person width="14" height="20" />
          </div>
          <div className="search-id-box-txt">
            <span className="primary-txt">{id}</span>
            <span className="gray-txt">{`가입일 ${date.replaceAll("-", ".")}`}</span>
          </div>
        </div>
        <div className="search-id-btn-wrap">
          <StrokeButton text={"비밀번호 찾기"} size={"xl"} strokeColor={"primary"} onClick={handelSearchPw} />
          <CommonButton text={"로그인"} size={"xl"} onClick={() => goTo(ROUTE_PATH.LOGIN2)} />
        </div>
      </div>
    </SearchTemplate>
  );
};

export default SearchIdCompletion;
