import "./styles.scss";
import LineTabbox from "../../Tabbox/LineTabbox";

interface Props {
  title: string;
  tabIndex?: number;
  children: any;
}

const SearchTemplate: React.FC<Props> = ({ title, tabIndex, children }) => {
  return (
    <div className="search__container">
      <h2>{title}</h2>
      {tabIndex && <LineTabbox tabIndex={tabIndex} />}
      {children}
    </div>
  );
};

export default SearchTemplate;
