export const privacyPolicy = {
  1: `1. [필수] 이용약관\r
■ 제 1 장 총 칙

제 1 조 (목적)
이 약관은 어부바(이하 “회사”라 합니다)이 제공하는 어부바 서비스(한글명: 어부바, 이하 "서비스"라 합니다)의 조건 및 절차에 관한 기본적인 사항을 정함을 목적으로 합니다.

  제 2 조 (이용약관의 효력 및 변경)
① 이 약관은 서비스 메뉴 및 “회사”에 게시하여 공시함으로써 효력을 발생합니다.
② “회사”는 합리적인 사유가 발생될 경우에는 이 약관을 변경할 수 있으며, 약관이 변경된 경우에는 홈페이지를 통해 이를 공지합니다.
③ 회원제서비스 이용고객은 변경된 약관 사항에 동의하지 않을 시, 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.
④ 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에 동의한 것으로 간주됩니다.

  제 3 조 (약관의 적용)
① 이 약관은 “회사”가 제공하는 개별서비스에 관한 이용안내(이하 "서비스별 안내"라 합니다)와 함께 적용합니다.
② 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 “서비스별 안내”의 취지에 따라 적용할 수 있습니다.

  제 4 조 (용어의 정의)
① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. "이용고객" 이라 함은 회원제 서비스를 이용하는 '유료회원'과 '무료회원'을 말합니다.
2. "유료회원'이라 함은 이용요금을 지불하고 “회사”와 회원제 유료서비스 이용계약을 체결하여 이용자번호(ID)를 부여 받은 회원으로 유료개인회원 또는 유료기업회원을 말합니다.
3. "무료회원"이라 함은 “회사”와 회원제 무료서비스 이용계약을 체결하여 이용자번호(ID)를 부여 받은 회원으로 무료개인회원 또는 무료기업회원을 말합니다.
4. "이용계약" 이라 함은 서비스 이용과 관련하여 “회사”와 이용고객 간에 체결하는 계약을 말합니다.
5. "이용요금"이라 함은 “회사”가 제공하는 이용고객에게 제공되는 서비스 중 유료서비스를 이용하기 위해 지불하는 요금으로 회원의 종류에 따라 차등하게 적용할 수 있으며, 그 금액은 별로도 정하여 고시하고 예고 없이 변경될 수 있다.
6. "회원제 서비스"라 함은 어부바가 제공하는 인터넷 서비스 또는 기타 유무선 통신설비를 통해 제공하는 유무형의 서비스를 말합니다.
7. "이용자번호(ID)"라 함은 이용고객의 식별과 이용고객의 서비스 이용을 위하여 이용고객이 선정하고 “회사”가 부여하는 문자와 숫자의 조합을 말합니다.
8. "비밀번호"라 함은 이용고객이 부여 받은 이용자번호와 일치된 이용고객 임을 확인하고 이용고객의 권익보호를 위하여 이용고객이 선정한 문자와 숫자의 조합을 말합니다.
9. "단말기"라 함은 “회사”가 제공하는 서비스를 이용하기 위해 이용고객이 설치한 개인용 컴퓨터 및 모뎀 등을 말합니다.
② 이 약관에서 사용하는 용어의 정의는 본 조 1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 안내에서 정하는 바에 의합니다.

■ 제 2 장 약관의 효력

제 5 조 (서비스의 구분)
① 서비스의 종류와 내용 등은 “회사”의 “서비스별 안내”에서 별도로 정하는 바에 의합니다.

  제 6 조 (이용계약의 성립 및 체결단위)
① 이용계약은 이용고객의 이용신청에 대하여 회사의 이용승낙으로 성립합니다.

  제 7 조 (이용 신청 및 약관의 동의)
① “회사”는 서비스 이용과 관련하여 서비스 약관에 동의하신 이용자에게 서비스를 무료 또는 유료로 제공합니다. 이용자가 등록절차를 거쳐 동의 버튼을 누름으로써 이 서비스 약관에 동의한 것으로 간주합니다.

■ 제 3 장 이용자의 의무

제 8 조 서비스의 보류 및 중지
① 회사는 다음 각 호의 1에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.
1. 타인 명의의 신청
2. 회원가입 등록정보에 기재한 내용이 허위임이 밝혀졌을 경우
3. 기타 이용신청고객의 귀책사유로 이용승낙이 어려운 경우
② “회사”는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대한 승낙 제한사유가 해소될 때까지는 승낙을 하지 않을 수 있습니다.
1. “회사”가 설비의 여유가 없는 경우
2. “회사”의 기술상 지장이 있는 경우
3. 기타 “회사”의 귀책사유로 이용승낙이 곤란한 경우
③ “회사”는 제2항의 규정에 의하여 이용신청이 불 승낙되거나 승낙을 제한하는 경우에는 이를 이용신청고객에게 즉시 알려야 합니다.
④ “회사”는 이용신청고객이 미성년자인 경우에는 서비스별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.

  제 9 조 (이용자ID 부여 및 변경)
① “회사”는 회원제서비스 이용고객에 대하여 서비스별 약관에 정하는 바에 따라 이용자ID를 부여합니다.
② 이용자ID는 다음 각 호에 해당하는 경우에는 이용고객 또는 “회사”의 요청에 의하여 변경할 수 있습니다.
1. 이용자ID가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가 우려가 있는 경우
2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
3. 기타 합리적인 사유가 있는 경우
③ 이용고객은 이용자ID를 제3자에게 사용하게 할 수 없으며, 이용자ID 및 비밀번호에 관한 모든 책임은 이용자에게 있습니다.
④ 기타 이용자ID의 관리 및 변경 등에 관한 사항은 서비스별 안내에 정하는 바에 의합니다.

■ 제 4 장 서비스의 이용 책임

제 10 조 (서비스의 이용책임)
“회사”는 전시·사변·천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
① “회사”는 제1항의 규정에 의하여 서비스의 이용을 제한하거나 정지한 때에는 그 사유 및 제한기간 등을 지체 없이 이용고객에게 알려야 합니다.

■ 제 5 장 서비스의 중지 및 종료

제 11 조 (천재지변)
“회사”는 전시·사변·천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
① “회사”는 제1항의 규정에 의하여 서비스의 이용을 제한하거나 정지한 때에는 그 사유 및 제한기간 등을 지체 없이 이용고객에게 알려야 합니다.

  제 12 조 (서비스 중지)
① ”회사”는 이용고객의 서비스 이용내용이 다음 각 호에 해당하는 경우에는 게시물을 삭제하거나 서비스의 전부 또는 일부의 이용을 제한하거나 정지할 수 있습니다.
1. 제13조의 제1항, 3항 또는 4항의 규정에 의한 이용고객의 의무를 이행하지 아니한 경우
2. 서비스의 안정적인 운영을 방해하는 경우
3. 정보통신 설비의 오 동작이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
4. 타인의 지적재산권을 침해하는 내용을 게시, 게재 또는 기타의 방법으로 전송 하는 경우
5. 정보통신윤리위원회의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우
6. 타인의 아이디(ID) 와 비밀번호(PASSWORD)를 사용하는 경우
7. 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승락없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
8. 전기통신 관련법령 등에 위배되는 경우
9. 다른 사용자의 개인정보를 수집 또는 저장하는 경우
10. 당사의 승인을 받지 않고 광고, 판촉물, 정크 메일, 스팸, 행운의 편지 등 기타 다른 형태의 권유를 게시, 게재 또는 기타 방법으로 전송하는 경우
11. 유료구인업체로서 구인업무를 대행해주는 경우
12. 기업회원의 경우 타기업의 구인업무를 대행해 주거나 허위사실의 게시물을 올리는 경우
13. 제3자의 권리를 침해하거나 타인을 비방하는 경우
② 제 2 항의 규정에 의하여 이용고객의 이용을 제한하는 경우에는 게시물 삭제 또는 제한의 종류 및 기간 등 구체적인 기준은 서비스별 안내에서 별도로 정하는 바에 의합니다.

  제 13 조 (서비스 이용 제한 및 해제)
“회사”는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며, 그 결과를 이용고객 또는 그 대리인에게 통지합니다.
① ”회사”는 이용정지 기간 중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지 조치를 즉시 해제합니다.
② 구인업체 및 인력파견업체는 유료회원으로 등록할 수 없습니다. 단, 예외적으로 서비스 내용이 이용자 대상으로 하여 양질의 품목임을 확인, “회사”와 합의된 경우 일부 제한적으로 서비스 승인 및 이용이 가능하다.
③ 위 사실이 차후에 확인되는 경우 “회사”는 회원의 권한을 임의로 정지시킬 수 있으며 환불에 대한 책임을 지지 않습니다.

  제 14 조 (회원가입 등록 정보의 변경)
이용고객은 주소 등 회원가입 등록 정보를 변경하고자 하는 경우에는 서비스별 안내에 정하는 바에 의하여 이용계약 변경 신청서나 서비스 또는 전화 등을 이용하여 변경할 수 있습니다.

  제 15 조 (면책)
① ”회사”는 이용고객이 “회사”의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
② ”회사”는 이용고객의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.
③ ”회사”는 이용자가 게시 또는 전송한 자료의 내용에 대해서는 책임이 면제됩니다.
④ ”회사”는 이용자 상호간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우에는 책임이 면제됩니다.
⑤ “회사”는 기타 내부 사정으로 인해여 일시적 또는 영구적으로 서비스를 중단할 수 있습니다. 이 경우 홈페이지를 통하여 미리 공지하도록 한다.
⑥ “회사”는 이용자의 서비스 이용과 관련하여 자료 보관 및 전송에 관한 책임이 없으며 자료의 손실이 있는 경우에도 책임이 면제됩니다.
⑦ 본 조에 정하지 않은 세부사항은 제 3조 “서비스별 안내”에 따라 책임이 면제됩니다.

■ 제 6 장 콘텐츠의 책임과 권한

제 16 조 (콘텐츠의 책임과 “회사”의 정보 수정 권한)
① 콘텐츠는 "회원"이 등록한 병원, 요양원정보, 돌봄질문, 돌봄답변 등과 관련된 1차 게시물과 그 외 "회원"이 서비스 내에서 작성하여 게시하는 댓글과 같은 2차 게시물을 포함하는 전체 게시물을 말한다.
② "회원"은 콘텐츠를 사실에 근거하여 성실하게 작성해야 하며, "회원" 본인이 작성하는 것을 원칙으로 한다.
③ “회사”는 "회원"이 작성한 콘텐츠를 검토하여 "요양보호사한테 물어보기" 및 " 커뮤니티 이용안내"에 명시된 조건에 문제가 없을 경우 "사이트"에 게시하는 방식의 필터링 작업을 수행하며, 이 작업에서 "요양보호사한테 물어보기" 및 " 커뮤니티 이용안내"에 위배된다 판단되어 게시가 허락되지 않을 경우 "회원"에게 사전 통보 없이 "사이트"에 게시되지 않는다.
④ 콘텐츠 내용에 오탈자 또는 사회적 통념에 어긋나는 문구와 내용, 명백하게 허위의 사실에 기초한 내용이 있을 경우 “회사”는 이를 언제든지 삭제하거나 수정할 수 있다.

  제 17 조 (콘텐츠의 권한 및 활용)
① "회원"이 입력한 콘텐츠는 “회사”가 제공하는 서비스를 통하여 익명으로 공개된다.
② “회사”는 "회원"이 입력한 콘텐츠를 취업 및 관련 동향의 통계 자료 작성에 익명으로 사용할 수 있고, 위 자료는 매체를 통해 언론에 배포될 수 있다.

  제 18 조 (콘텐츠의 저작권)
① "회원"이 서비스 내에 게시한 콘텐츠의 저작권은 해당 콘텐츠의 저작자에게 귀속된다. 다만 “회원”은 서비스를 통해 콘텐츠를 제출, 게시함으로써 “회사”에 대하여 콘텐츠에 대한 이용, 복사, 복제, 처리, 각색, 변경, 공개, 전송, 게재 또는 배포할 수 있는 독점적이며 무상의 저작사용권(2차 저작사용권을 허여 할 수 있는 권리 포함)을 부여한 것으로 간주된다.
② "회원"이 서비스 내에 게시하는 콘텐츠는 검색 결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있다. 이 경우, “회사”는 저작권법 규정을 준수하며, "회원"은 언제든지 "서비스"내 관리기능을 통해 해당 콘텐츠에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있다.

  제 19 조 (콘텐츠의 관리)
① "회원"의 게시한 콘텐츠가 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 콘텐츠의 게시중단 및 삭제 등을 요청할 수 있으며, “회사”는 관련법에 따라 조치를 취하여야 한다.
② “회사”는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 “회사” 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 콘텐츠에 대해 임시조치 등을 취할 수 있다.
③ 본 조에 따른 세부절차는 "정보통신망법" 및 "저작권법"이 규정한 범위 내에서 “회사”가 정한 "게시중단요청서비스"절차에 따른다.

■ 제 7 장 기타: 회원의 권리 및 보호, 분쟁의 해결

제 20 조 (권리의 귀속)
① "서비스"에 대한 저작권 및 지적재산권은 “회사”에 귀속된다. 단, "회원"이 게시한 콘텐츠 및 제휴계약에 따라 제공된 저작물 등은 제외된다.
② “회사”는 서비스와 관련하여 "회원"에게 “회사”가 정한 이용조건에 따라 계정, 아이디, 콘텐츠를 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 제 3자에게 양도, 판매, 담보제공 등의 처분행위를 할 수 없다.

  제 21 조 (손해배상)
① "회사"가 이 약관의 규정을 위반한 행위로 "회원"에게 손해를 입히거나 기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로 인해 이용자에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야 한다.
② "회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "회원"이 손해를 입었을 경우에 "회사"는 그 손해를 배상하여야 한다.
③ "회원"이 이 약관의 규정을 위반한 행위로 "회사" 및 제 3자에게 손해를 입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌 경우에는 "회원"은 그 손해를 배상하여야 한다.
④ 타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로 "회원"의 손해가 발생한 경우 "회사"는 이에 대한 배상 책임이 없다.

  제 22 조 (분쟁의 해결)
① "회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 한다.
② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지 관할법원으로 한다.
`,

  2: `2. [필수] 개인정보처리방침
주식회사 어부바(이하 “회사”)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자의 권익 보호에 최선을 다하고 있습니다.

본 개인정보처리방침은 회사가 제공하는 어부바 커뮤니티, 교육, 커리어 등 PC 및 모바일 온라인 기반 서비스(이하 “사이트”) 이용에 적용되며 다음과 같은 내용을 담고 있습니다.
1. 개인정보의 수집 및 이용목적
2. 수집하는 개인정보 항목 및 수집방법
3. 개인정보의 제공 및 취급위탁
4. 개인정보의 보유 및 이용기간
5. 개인정보 파기절차 및 파기방법
6. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항
7. 개인정보의 기술적, 관리적 보호대책
8. 이용자의 권리와 그 행사방법
9. 개인정보 관리책임자 및 민원서비스
10. 링크
11. 부칙
1. 개인정보의 수집 및 이용목적
회사는 이용자의 회원가입, 본인확인, 가입 의사 및 서비스 이용의사 확인, 부정이용방지, 서비스 제공, 이력서 등록과 입사지원 등 취업활동, 유료서비스 및 상품의 구매 및 요금결제, 물품 및 증빙발송, 기타 분쟁해결, 민원처리, 경품지급 등에 있어서 최적화되고 맞춤화된 서비스를 제공하기 위하여 이용자의 개인정보를 수집합니다. 회사는 최초 회원가입 시 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며 회사가 제공하는 채용서비스를 위한 이력서 정보 및 유료 서비스 및 상품의 구매에 따른 요금결제 등에 필요한 정보를 추가로 수집할 수 있습니다. 이용자가 제공한 모든 정보는 수집 및 이용 목적에 필요한 용도 이외로는 사용되지 않습니다.
2. 수집하는 개인정보 항목 및 수집방법
가. 수집하는 개인정보의 항목
1) 회원가입시 수집하는 항목

① 개인 회원 (요양보호사 회원)
*필수항목: 이름, 아이디, 비밀번호, 닉네임, 이메일, 휴대폰번호, 주소, 요양보호사 자격증번호, 자격증 취득 연월, 이메일 및 SMS 수신설정
*선택항목: 현직장명, 부서명

② 개인 회원 (어르신/보호자 회원)
*필수항목: 이름, 아이디, 비밀번호, 닉네임, 이메일, 휴대폰번호, 이메일 및 SMS 수신설정

③ 개인 회원 (일반회원)
*필수항목: 이름, 아이디, 비밀번호, 닉네임, 이메일, 휴대폰번호, 주소, 직업정보(현재 소속), 이메일 및 SMS 수신설정

④ 기업 회원
*필수항목: 이름, 아이디, 비밀번호, 휴대폰번호, 채용담당자 이메일, 회사분류(형태), 회사명, 회사주소, 대표전화, 대표자명, 사업자등록번호, 회사개요, 이메일 및 SMS 수신설정 (광고성 정보 포함)
*선택항목: 업태, 종목, 팩스번호, 홈페이지, 설립년도, 직원수, 병상수, 기업로고, 기업사진
2) 회원인증시 수집하는 항목

① 개인 회원 (요양보호사 회원)
*필수항목: 요양보호사 자격증 사본, 자격신고 확인증, 온라인 자격조회 화면 캡쳐 파일 중 하나

② 개인 회원 (어르신/보호자 회원)
*필수항목: 나이 / 성별 

① 개인 회원
- 신용카드 결제 : 카드사명, 결제정보, 카드번호, 결제일자, 기타정보(E-mail)
- 가상계좌 : (가상)계좌번호, 입금자명, 결제정보

② 기업 회원
- 간편결제 : 카드사명, 카드번호, 유효기간, 생년월일 또는 사업자등록번호, 간편결제 비밀번호
- 신용카드 결제 : 카드사명, 결제정보, 카드번호, 결제일자, 기타정보(E-mail)
- 가상계좌 : (가상)계좌번호, 입금자명, 결제정보
- 계좌이체 : 계좌번호, 구매자명, 결제정보
6) 요양보호사한테 질문하기 글 등록 시 수집하는 항목

① 개인 회원 (요양보호사 회원)
- 회원이 답변한 질문 및 그 내역

② 개인 회원 (어르신/보호자 회원)
- 회원이 올리는 질문 및 그 내역
7) 모바일 서비스 이용 시 수집되는 항목
원활한 서비스 제공, 서비스 개선을 위해 이용하시는 단말기 모델 정보가 수집될 수 있으나, 해당 정보는 개인을 식별할 수 없는 형태입니다.
8) 기타 수집항목
서비스 이용 과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.

① IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록
② 해당 정보는 회원 분들의 서비스 이용과 관련하여 서비스 개선, 서비스 불만 신고 및 문의, 응대를 위한 목적으로만 사용됩니다.

나. 개인정보 수집방법
홈페이지, 이메일, 전화, 팩스, 우편, 고객센터 문의하기, 이벤트 응모
3. 개인정보의 제공 및 취급위탁
가. 위탁 업체 및 위탁하는 업무 내용
수탁업체\t위탁업무 내용\t개인정보 이용기간
㈜케이지이니시스\t상품신용카드, 휴대폰, 실시간 계좌이체, 가상계좌(무통장 입금) 결제 업무\t관계법령에 따른 보관기한까지
서울신용평가정보(주)\t본인인증, 아이핀인증업무\t해당 업체에서 이미 보유하고 있는 개인 정보이며, 회사는 해당 정보를 별도 저장하거나 보관하지 않음
로젠㈜\t이벤트 상품 또는 구매 상품의 배송\t관계법령에 따른 보관기한까지
㈜에스아이에스 씨엔씨\t공지사항 또는 마케팅 문자 발송 업무\t관련법규에 의거, 발송일로부터 3개월 또는 위탁계약 종료시까지
㈜델피콤\t안심번호(050번호) 제공
위탁정보 : 휴대폰 번호\t관계법령에 따른 보관기한까지
나. 개인정보의 위탁/보관중 국외이전 사항
회사는 서비스 제공의 안정성과 최신 기술을 이용자에게 제공하기 위해 국외에 개인정보를 위탁하고 있으며, 이용자로부터 취득 또는 생성한 개인정보를 Microsoft Azure Cloud (Microsoft Corporation)가 보유하고 있는 데이터베이스(물리적 저장 장소: 미국)에 저장합니다. Azure Cloud는 해당 서버의 물리적인 관리만을 행하고, 이용자의 개인정보에 접근할 수 없습니다.
수탁업체\tMicrosoft Corporation - Microsoft Azure Cloud
이전 항목\t서비스 이용 기록 또는 수집된 개인정보
이전 국가\t미국 (Azure Global Cloud server)
이전 일시 및 방법\t서비스 이용 시점에 네트워크를 통한 전송
개인정보 보유 및 이용기간\t서비스 변경 시까지 (현재 회사가 이용 중인 클라우드 서비스 이용 변경 시까지)
4. 개인정보의 보유 및 이용기간
"사이트"는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 이용기간이 종료한 경우 개인정보를 지체 없이 파기합니다.
단, 다음의 경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.
1) 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다.
이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.
① 계약 또는 청약철회 등에 관한 기록: 5년
② 대금결제 및 재화 등의 공급에 관한 기록: 5년
③ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
④ 부정이용 등에 관한 기록: 5년
⑤ 웹사이트 방문기록(로그인 기록, 접속기록): 3개월
2) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 동의를 받은 경우에는 약정한 기간 동안 보유합니다.
3) 개인정보보호를 위하여 이용자가 1년 동안 "사이트"를 이용하지 않은 경우, "아이디"를 "휴면계정"로 분리하여 해당 계정의 이용을 중지할 수 있습니다. 이 경우 "회사"는 "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을 전자메일, 서면, SMS 중 하나의 방법으로 사전통지하며 이용자가 직접 본인확인을 거쳐, 다시 "사이트" 이용 의사표시를 한 경우에는 "사이트" 이용이 가능합니다.
5. 개인정보 파기절차 및 파기방법
이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. "회사"의 개인정보 파기절차 및 방법은 다음과 같습니다.
1) 파기절차
- 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.
- 별도 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는 다른 목적으로 이용되지 않습니다.
2) 파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기합니다.
- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
6. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항
1) 쿠키란
- 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은 텍스트 파일로 이용자의 하드디스크에 저장됩니다.
2) 쿠키의 사용 목적
- "사이트"가 쿠키를 통해 수집하는 정보는 '2. 수집하는 개인정보 항목 및 수집방법'과 같으며 '1. 개인정보의 수집 및 이용목적'외의 용도로는 이용되지 않습니다.
3) 쿠키 설치, 운영 및 거부
- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
- 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다. 예) 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 > 고급 > 쿠키 허용 정보 변경
- 단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
7. 개인정보의 기술적, 관리적 보호대책
1) 개인정보 암호화
- 이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 각종 데이터는 암호화하거나 파일 잠금 기능을 통해 별도의 보안기능을 통해 보호하고 있습니다.
2) 해킹 등에 대비한 대책
- 해킹이나 컴퓨터 바이러스 등에 의한 피해를 방지하기 위하여 백신 프로그램을 주기적으로 업데이트하며 새로운 바이러스가 출현할 경우 백신이 나오는 즉시 적용하여 개인정보가 훼손되지 않도록 방지하고 있으며, 외부 침입에 대비하여 접근이 통제된 구역에 시스템을 설치하고, 침입탐지 시스템 및 취약점 분석 시스템을 설치하여 24시간 감시하고 있습니다.
3) 개인정보 취급 직원의 교육
- 개인정보관련 취급 직원은 최소한의 인원으로 구성되며, 새로운 보안기술 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 내부 감사 절차를 통해 보안이 유지되도록 시행하고 있습니다.
4) 개인 아이디와 비밀번호 관리
- "회사"는 이용자의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있습니다. 단, 이용자의 개인적인 부주의로 ID, 비밀번호등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
8. 이용자의 권리
이용자의 개인정보는 언제든지 홈페이지의 “개인정보 수정”을 통하여 열람하거나 정정할 수 있으며, 회원탈퇴를 통해 개인정보를 삭제할 수 있습니다. 또한 이력서에 있는 개인정보는 이력서 관리 기능을 통해 언제든지 열람하거나 수정 또는 삭제를 할 수 있습니다.
1) 개인정보 열람 및 정정은 개인회원으로 로그인 하신 후 페이지 상단 내정보관리 (OOO님) 클릭 > 마이페이지 > 기본정보 수정 메뉴에서 할 수 있습니다.
2) 회원 탈퇴(ID삭제)는 개인회원으로 로그인 하신 후 마이페이지 하단에 있는 ‘어부바 회원탈퇴’ 메뉴를 통해 탈퇴할 수 있습니다.
- 이력서 열람 및 수정, 삭제는 개인회원으로 로그인 하신 후 어부바 커리어 이동 > 마이페이지 > 내 이력서 관리 메뉴에서 할 수 있습니다.
- 이용자께서 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
9. 개인정보 관리책임자 및 민원서비스
"회사"는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충처리를 위하여 아래와 같이 개인정보 관리책임자와 개인정보 관리담당자를 지정하고 있습니다. 언제라도 문의 주시면 신속하고 충분한 답변을 드리도록 하겠습니다.


1) 개인정보 관리책임자
이름 : 이일광
소속/직위 : 대표/대표이사
E-mail : 
전화 : 
팩스 : \t

2) 개인정보 관리담당자
이름 : 이준영
소속/직위 : CTO
E-mail : 
전화 : 
팩스 : 
또한 개인정보에 관한 상담이 필요한 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
개인정보침해신고센터\thttp://privacy.kisa.or.kr / 국번없이 118
대검찰청 사이버수사과\thttp://www.spo.go.kr / 국번없이 1301
경찰청 사이버안전국\thttp://www.ctrc.go.kr / 국번없이 182
10. 링크
"어부바 사이트"는 다양한 배너와 링크를 포함하고 있습니다. 많은 경우 타 사이트의 페이지와 연결되어 있으며 이는 광고주와의 계약관계에 의하거나 제공받은 컨텐츠의 출처를 밝히기 위한 조치입니다.
"어부바 사이트"가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 어부바와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.
11. 부칙
현 개인정보처리방침은 법령, 정책, 보안기술의 변경 또는 회사의 필요에 의하여 변경될 수 있으며, 내용의 추가 및 삭제, 수정이 있을 시에는 홈페이지 공지를 통해 고지합니다. 또한 당사는 개인정보의 수집 및 활용, 제3자 제공 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법령에 따라 고객의 별도 동의가 필요한 사항과 관련된 내용이 추가, 변경되는 경우에는 관련 법령에 따른 고객의 별도 동의를 받습니다.
  `,
  3: `어부바는 다음의 목적을 위해 개인정보를 수집 및 이용합니다.\n\n[수집 목적]\n○ 홈페이지의 원활한 서비스 이용 (게시글 작성, 공유서비스예약, 제안토론, 설문투표, 통합돌봄(어부바돌봄), 통합회원 연계 서비스 등)\n※ 수집된 개인정보는 목적이외의 용도로는 이용되지 않습니다. 수집 목적이 변경될 경우 사전에 알리고 동의를 받을 예정입니다.\n\n[수집하는 개인정보의 항목]`,
  4: `어부바에서는 회원에게 시정정보에 대한 문자(SMS), 이메일(E-mail)을 발송 할 수 있습니다. 
  문자(SMS)와 이메일(E-mail) 서비스 제공을 위해서는 회원님의 동의가 필요합니다.서비스에 대한 동의를 거부할 권리가 있으며 동의 거부 시에는 일부 서비스에 제한이 있을 수 있습니다.`,
};
