export const SIGNUP_REGEXP = {
  ID: /^[a-zA-Z0-9]{5,20}$/,
  PASSWORD: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^*+=-]).{8,25}$/,
  NAME: /^[a-zA-Z가-힣]{2,5}$/,
  NICKNAME: /^[a-zA-Z가-힣]{2,20}$/,
  BIRTH: /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/,
  PHONE: /^(01\d{1})\d{3,4}\d{4}$/,
  EMAIL: /^[a-zA-Z0-9._%+-]{5,20}$/,
  DOMAIN: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  AUTHNUM: /^\d{6}$/,
  AGE: /^([5-9]\d|10[0-9]|110)$/,
};

export const SIGNUP_MESSAGE = {
  ID: "사용가능한 아이디 입니다",
  PASSWORD: "사용가능한 비밀번호 입니다",
  PASSWORDCONFIRM: "비밀번호가 일치합니다",
  NICKNAME: "사용가능한 닉네임 입니다",
};

export const SIGNUP_ERROR_MESSAGE = {
  ID: "아이디는 5~20자 이내의 대소문자 또는 숫자만 입력해 주세요",
  PASSWORD: "비밀번호는 영문, 숫자, 특수문자(!@#$% 등)를 포함해 8자리 이상 입력해 주세요",
  PASSWORDCONFIRM: "비밀번호가 일치하지 않습니다",
  NAME: "이름은 한글 또는 영문으로 입력해주세요",
  NICKNAME: "닉네임은 2~20자 이내의 한글 또는 영문으로 입력해주세요",
  BIRTH: "생년월일은 8자리 숫자를 입력해 주세요",
  EMAIL: "올바른 이메일 형식으로 입력해 주세요",
  PHONE: "휴대폰 번호가 정확한지 확인해 주세요",
  AUTHNUM: "인증번호가 정확한지 확인해 주세요",
  AGE: "나이는 숫자만 입력 해주세요(50~110세)",
};
