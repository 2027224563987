import { useEffect, useState } from "react";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_MESSAGE, SIGNUP_REGEXP } from "../../constants/signup";
import { useInput } from "../../hooks/useInput";
import CommonInput from "../Input/CommonInput";
import { getCheckId } from "../../apis/accounts";
import { useRecoilState } from "recoil";
import { idState } from "../../recoil/atoms";

interface Props {
  labelType?: string;
  hasError?: boolean;
}

const IdForm: React.FC<Props> = ({ labelType, hasError }) => {
  const [idValue, setIdValue] = useRecoilState(idState);
  const {
    text: id,
    handleChange: handelChangeId,
    error: idError,
    setError: setIdError,
    handleBlur: handleBlurId,
    errorMsg: idErrorMessage,
    setErrorMsg: setIdErrorMessage,
    inputRef: idRef,
  } = useInput("", SIGNUP_REGEXP.ID, SIGNUP_ERROR_MESSAGE.ID);

  const [idMessage, setIdMessage] = useState<string>("");

  useEffect(() => {
    if (hasError) {
      handleBlurId();
    }
  }, [hasError, handleBlurId]);

  useEffect(() => {
    setIdValue({ ...idValue, id, isCheckId: false });
    setIdMessage("");
  }, [id, idValue, setIdValue]);

  // 아이디 중복 체크
  const handleCheckId = () => {
    if (!id && idRef.current) {
      idRef.current.focus();
      setIdError(true);
      setIdErrorMessage("아이디를 입력해주세요");
    } else {
      getCheckId(id)
        .then(() => {
          // console.log("아이디 중복 체크 성공", res);
          setIdError(false);
          setIdMessage(SIGNUP_MESSAGE.ID);

          // 아이디 입력 값 체크 시 사용
          setIdValue({ ...idValue, id, isCheckId: true });
        })
        .catch((error) => {
          console.error("아이디 중복 체크 실패", error);

          if (idRef.current) {
            idRef.current.focus();
          }
          const { message } = error.response.data;
          setIdMessage("");
          setIdError(true);
          setIdErrorMessage(message.username);

          setIdValue({ ...idValue, id, isCheckId: false });
        });
    }
  };

  // 아이디 중복확인 엔터 이벤트
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleCheckId();
    }
  };

  // 포커스가 사라질 때 중복확인을 하지 않았을 경우 에러 처리
  const handleBlurIdWithCheck = () => {
    handleBlurId(); // 기존 블러 핸들러 호출

    if (idValue.id && !idValue.isCheckId) {
      setIdError(true);
      setIdErrorMessage("아이디 중복확인을 해주세요");
    }
  };

  return (
    <CommonInput
      labelText={"아이디"}
      labelType={labelType}
      type="text"
      name="id"
      value={id}
      maxLength={20}
      placeholder="아이디 영문, 숫자 조합 5~20자"
      error={idError}
      errorMsg={idErrorMessage}
      okMsg={idMessage}
      buttonText="중복확인"
      onChange={handelChangeId}
      onClick={handleCheckId}
      onKeyDown={handleKeyDown}
      onBlur={handleBlurIdWithCheck}
      ref={idRef}
    />
  );
};

export default IdForm;
