import "./styles.scss";
import classNames from "classnames";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { previousRoute } from "../../recoil/atoms";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/routePath";
import Close from "../svg/icon/Close";
import Down from "../svg/icon/Down";
import Up from "../svg/icon/Up";

interface Props {
  isLogin: string | null;
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const Sidebar: React.FC<Props> = ({ isLogin, isOpen, onClose, onLogout }) => {
  const location = useLocation();
  const setPreviousRoute = useSetRecoilState(previousRoute);

  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({
    depth1: false,
    depth2: false,
    depth3: false,
  });

  const toggleMenu = (menu: string) => {
    // 제일 상위메뉴 닫히면 모두 닫히도록 처리
    if (menu === "depth1" && openMenus.depth1) {
      setOpenMenus((prev) => ({
        ...prev,
        depth2: false,
        depth3: false,
      }));
    }
    setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  // 사이드바 영역에서 클릭 시 닫히지 않도록 버블링 방지
  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onClickSideMenu = () => setPreviousRoute({ path: location.pathname });

  return (
    <div className={classNames("sidebar__wrap", { open: isOpen })} onClick={onClose}>
      <aside className="sidebar__container" onClick={handleContainerClick}>
        <div className="sidebar__content">
          <div className="sidebar__icon">
            <button onClick={onClose}>
              <Close width="28" height="28" />
            </button>
          </div>
          <div className="sidebar__menu">
            <ul onClick={onClose}>
              {!isLogin && (
                <>
                  <li>
                    <Link to={ROUTE_PATH.LOGIN2}>로그인</Link>
                  </li>
                  <li>
                    <Link to={ROUTE_PATH.SIGN_UP2}>회원가입</Link>
                  </li>
                </>
              )}
              {isLogin && (
                <>
                  <li className="has" onClick={(e) => e.stopPropagation()}>
                    <Link to="#" onClick={() => toggleMenu("depth1")}>
                      마이페이지
                      {openMenus.depth1 ? <Up width="20" height="20" /> : <Down width="20" height="20" />}
                    </Link>
                  </li>
                  <div
                    className={classNames("sidebar__menu__depth-1", { on: openMenus.depth1 })}
                    onClick={handleContainerClick}
                  >
                    <ul>
                      <li>
                        <p className="has">
                          <Link to="#" onClick={() => toggleMenu("depth2")}>
                            프로필
                            {openMenus.depth2 ? <Up width="16" height="16" /> : <Down width="16" height="16" />}
                          </Link>
                        </p>
                        <div className={classNames("sidebar__menu__depth-2", { on: openMenus.depth2 })}>
                          <ul onClick={onClose}>
                            <li>
                              <Link to={ROUTE_PATH.USERINFO}>회원정보 조회</Link>
                            </li>
                            <li>
                              <Link to={ROUTE_PATH.CHANGEPW}>비밀번호 변경</Link>
                            </li>
                            <li>
                              <Link to={ROUTE_PATH.CANCELUSER}>회원탈퇴</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <p className="has">
                          <Link to="#" onClick={() => toggleMenu("depth3")}>
                            활동
                            {openMenus.depth3 ? <Up width="16" height="16" /> : <Down width="16" height="16" />}
                          </Link>
                        </p>
                        <div className={classNames("sidebar__menu__depth-2", { on: openMenus.depth3 })}>
                          <ul onClick={onClose}>
                            <li>
                              <Link to="#" onClick={() => alert("아직 준비중입니다.")}>
                                요양등급검사
                              </Link>
                            </li>
                            <li>
                              <Link to="#" onClick={() => alert("아직 준비중입니다.")}>
                                내가 쓴 글
                              </Link>
                            </li>
                            <li>
                              <Link to="#" onClick={() => alert("아직 준비중입니다.")}>
                                상담내용
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
              <li>
                <Link to={ROUTE_PATH.CAREGRADE}>예상 요양 등급 확인</Link>
              </li>
              <li>
                <Link to={ROUTE_PATH.QUESTIONBOARD} onClick={onClickSideMenu}>
                  질문 게시판
                </Link>
              </li>
              <li>
                <Link to={ROUTE_PATH.FREEBOARD} onClick={onClickSideMenu}>
                  자유게시판
                </Link>
              </li>
              <li>
                <Link to={ROUTE_PATH.COUNSEL} onClick={onClickSideMenu}>
                  상담하기
                </Link>
              </li>
              {isLogin && (
                <li className="sidebar__logout">
                  <Link to={ROUTE_PATH.HOME} onClick={onLogout}>
                    로그아웃
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
