import "./styles.scss";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { IResponses, responsesState } from "../../../recoil/atoms";
import { DETAIL_QUESTION_TYPE, EX_QUESTION_TYPE } from "../../../constants/careGrade";
import { getCookieByKey, mapQuestionData } from "../../../common/function";
import AccessmentForm from "../AssessmentForm";
import AssessmentInfoForm from "../AssessmentInfoForm";

const Assessment = () => {
  const isLogin = getCookieByKey("accessToken");
  const questionCategories = !isLogin ? EX_QUESTION_TYPE : DETAIL_QUESTION_TYPE;
  const questionTypes = Object.values(questionCategories).map((category) => mapQuestionData(category));
  const lastIndex = Object.keys(questionTypes).length;

  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [isResult, setIsResult] = useState<boolean>(false);
  const [unansweredQuestionIndex, setUnansweredQuestionIndex] = useState<number>(-1);

  const responses = useRecoilValue<IResponses>(responsesState);

  useEffect(() => {
    // 스크롤 최상단 이동
    window.scrollTo(0, 0);
  }, [currentIndex]);

  // 이전 버튼
  const handleClickPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  // 다음 버튼
  const handleClickNext = () => {
    const currentCategory = questionTypes[currentIndex - 1].type as keyof IResponses;
    const totalQuestions = questionTypes[currentIndex - 1].questionList.length;
    const answeredQuestions = Object.keys(responses[currentCategory] || {}).length;

    if (answeredQuestions === totalQuestions) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      if (currentIndex === lastIndex) {
        setIsResult(true);
      }
    } else {
      if (unansweredQuestionIndex !== -1) {
        alert(`질문 ${unansweredQuestionIndex + 1}에 응답해 주세요.`);

        if (unansweredQuestionIndex !== -1) {
          const questionElement = document.querySelector(
            `.assessment-form__item:nth-child(${unansweredQuestionIndex + 1})`,
          );
          if (questionElement) {
            questionElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }
  };

  // 첫 번째 선택되지 않은 질문 ref 업데이트
  const handleUnansweredQuestionUpdate = (unansweredQuestionIndex: number) => {
    if (unansweredQuestionIndex !== -1) {
      setUnansweredQuestionIndex(unansweredQuestionIndex);
    }
  };

  return (
    <div className="assessment__container">
      {isResult ? (
        <AssessmentInfoForm />
      ) : (
        <AccessmentForm
          currentIndex={currentIndex}
          questionType={questionTypes[currentIndex - 1]}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          onUnansweredQuestionUpdate={handleUnansweredQuestionUpdate}
        />
      )}
    </div>
  );
};

export default Assessment;
