import "./styles.scss";
import React, { FC } from "react";

interface Props {
  setIsStartTest: React.Dispatch<React.SetStateAction<boolean>>;
}
const MainGuideMessage: FC<Props> = ({ setIsStartTest }) => {
  return (
    <section className="message-container page-main-container">
      <div className="message-box ft-bold">
        <div>
          <p>장기요양등급 판정은 어르신의 질병 유무 보다는 현재 상태에서 일상적인 생활의 유지가 얼마나 </p>
          <p>가능하신지, 타인의 도움이 필요한 수준이 어느 정도인지를 종합적으로 판단하게 됩니다. </p>
        </div>
        <div>
          <p>본 테스트는 실제 건강보험공단의 평가사가 어르신을 방문하여 작성하는 장기요양인정조사표의 설문을</p>
          <p>기반으로 하였으며, 등급판정 기준에 대한 고시를 바탕으로 평가방법이 만들어져서, 최대한 실제</p>
          <p>조사결과와 유사하도록 제작되었습니다.</p>
        </div>
        <div>
          <p>쉽게 조사하실 수 있도록 16문항으로 구분하였으며 이에 따라 실제 조사와 편차가 있을 수 있습니다.</p>
        </div>
      </div>
      <p className="warning ft-bold">※주의 : 실제 등급과 편차가 있을 수 있음</p>
      <button
        className="confirm-button ft-bold"
        onClick={() => {
          setIsStartTest(true);
        }}
      >
        셀프 요양등급 테스트 시작하기
      </button>
    </section>
  );
};
export default MainGuideMessage;
