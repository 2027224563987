import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import LoginForm from "../../components/Login/LoginForm";
import { useNavigate } from "react-router-dom";

const Login: FC = () => {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate("/home");
    }
  });

  return (
    <main className="page-main-container main-container">
      <LoginForm isLogin={isLogin} setIsLogin={setIsLogin} />
    </main>
  );
};

export default Login;
