import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import {
  recognizeQuestionnaire,
  behaviorQuestionnaire,
  physicalQuestionnaire,
  rehabilitationQuestionnaire,
  nursingQuestionnaire,
} from "../../../../constants/questionnaireList";
import { GENDER_TYPE } from "../../../../constants/user";
import { postCareGradeExternal } from "../../../../apis/healths";

interface Props {
  setShowResult: React.Dispatch<React.SetStateAction<boolean>>;
  setResultGrade: React.Dispatch<React.SetStateAction<number>>;
}

interface answerRadioList {
  [key: string]: string;
}

const QuestionnaireForm: FC<Props> = ({ setShowResult, setResultGrade }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [physicalAnswer, setPhysicalAnswer] = useState(
    physicalQuestionnaire.questionList.reduce((acc, question) => {
      acc[question.id] = "";
      return acc;
    }, {} as answerRadioList),
  );

  const [behaviorAnswer, setBehaviorAnswer] = useState(
    behaviorQuestionnaire.questionList.reduce((acc, question) => {
      acc[question.id] = "";
      return acc;
    }, {} as answerRadioList),
  );

  const [recognizeAnswer, setRecognizeFunction] = useState(
    recognizeQuestionnaire.questionList.reduce((acc, question) => {
      acc[question.id] = "";
      return acc;
    }, {} as answerRadioList),
  );

  const [rehabilitationAnswer, setRehabilitationAnswer] = useState(
    rehabilitationQuestionnaire.questionList.reduce((acc, question) => {
      acc[question.id] = "";
      return acc;
    }, {} as answerRadioList),
  );

  const [nursingAnswer, setNursingAnswer] = useState(
    nursingQuestionnaire.questionList.reduce((acc, question) => {
      acc[question.id] = "";
      return acc;
    }, {} as answerRadioList),
  );

  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");

  const handleGender = (event: any) => {
    const { value } = event.target;
    setGender(value);
  };

  const onChangeAge = (event: any) => {
    const { value } = event.target;
    setAge(value.replace(/[^0-9]/g, ""));
  };

  const setAnswerData = (event: any, setTargetAnswer: React.Dispatch<any>, targetAnswerList: any) => {
    const { name, value } = event.target;
    setTargetAnswer({
      ...targetAnswerList,
      [name]: value,
    });
  };

  const onClickConfirm = () => {
    const requestBody = {
      gender,
      age,
      data: {
        physical: { ...physicalAnswer },
        recognize: { ...recognizeAnswer },
        behavior: { ...behaviorAnswer },
        nursing: { ...nursingAnswer },
        rehabilitation: { ...rehabilitationAnswer },
      },
    };

    postCareGradeExternal(requestBody).then((res) => {
      const {
        status,
        data: {
          code,
          result: { rate },
        },
      } = res;
      switch (status) {
        case 200:
          switch (code) {
            case 200: {
              setResultGrade(rate);
              break;
            }
          }
          break;
      }
    });

    setShowResult(true);
  };

  const checkAnswer = (answerObject: object) => {
    let returnValue = false;
    for (const value of Object.values(answerObject)) {
      returnValue = !!value;
    }
    return returnValue;
  };

  useEffect(() => {
    setIsAllChecked(
      checkAnswer(physicalAnswer) &&
        checkAnswer(behaviorAnswer) &&
        checkAnswer(recognizeAnswer) &&
        checkAnswer(rehabilitationAnswer) &&
        checkAnswer(nursingAnswer),
    );

    if (!age || !gender) {
      setIsAllChecked(false);
    }
  }, [physicalAnswer, behaviorAnswer, recognizeAnswer, rehabilitationAnswer, nursingAnswer, gender, age, isAllChecked]);

  return (
    <main className="questionnaire-form-container page-main-container">
      <section className="form-container">
        <section className="form-section">
          <p className="section-title">평가 항목 1 - {physicalQuestionnaire.sectionTitle}</p>
          <div className="section_description">
            <p>{physicalQuestionnaire.sectionDescription.main}</p>
            <p>{physicalQuestionnaire.sectionDescription.sub}</p>
          </div>
          {physicalQuestionnaire.questionList.map((questionListItem) => {
            return (
              <div className="question-container" key={questionListItem.id}>
                <p className="question-message">{questionListItem.message}</p>
                <div className="answer-container">
                  {questionListItem.answer.map((answerListItem) => {
                    return (
                      <div className="answer-box" key={answerListItem.message}>
                        <span className="answer-message">{answerListItem.message}</span>
                        <input
                          name={questionListItem.id}
                          type="radio"
                          className="radio-button"
                          value={answerListItem.value}
                          onChange={(e) => setAnswerData(e, setPhysicalAnswer, physicalAnswer)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
        <section className="form-section">
          <p className="section-title">평가 항목 2 - {behaviorQuestionnaire.sectionTitle}</p>
          <div className="section_description">
            <p>{behaviorQuestionnaire.sectionDescription.main}</p>
            <p>{behaviorQuestionnaire.sectionDescription.sub}</p>
          </div>
          {behaviorQuestionnaire.questionList.map((questionListItem) => {
            return (
              <div className="question-container" key={questionListItem.id}>
                <p className="question-message">{questionListItem.message}</p>
                <div className="answer-container">
                  {questionListItem.answer.map((answerListItem) => {
                    return (
                      <div className="answer-box" key={answerListItem.message}>
                        <span className="answer-message">{answerListItem.message}</span>
                        <input
                          name={questionListItem.id}
                          type="radio"
                          className="radio-button"
                          value={answerListItem.value}
                          onChange={(e) => setAnswerData(e, setBehaviorAnswer, behaviorAnswer)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
        <section className="form-section">
          <p className="section-title">평가 항목 3 - {recognizeQuestionnaire.sectionTitle}</p>
          <div className="section_description">
            <p>{recognizeQuestionnaire.sectionDescription.main}</p>
            <p>{recognizeQuestionnaire.sectionDescription.sub}</p>
          </div>
          {recognizeQuestionnaire.questionList.map((questionListItem) => {
            return (
              <div className="question-container" key={questionListItem.id}>
                <p className="question-message">{questionListItem.message}</p>
                <div className="answer-container">
                  {questionListItem.answer.map((answerListItem) => {
                    return (
                      <div className="answer-box" key={answerListItem.message}>
                        <span className="answer-message">{answerListItem.message}</span>
                        <input
                          name={questionListItem.id}
                          type="radio"
                          className="radio-button"
                          value={answerListItem.value}
                          onChange={(e) => setAnswerData(e, setRecognizeFunction, recognizeAnswer)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
        <section className="form-section">
          <p className="section-title">평가 항목 4 - {rehabilitationQuestionnaire.sectionTitle}</p>
          <div className="section_description">
            <p>{rehabilitationQuestionnaire.sectionDescription.main}</p>
            <p>{rehabilitationQuestionnaire.sectionDescription.sub}</p>
          </div>
          {rehabilitationQuestionnaire.questionList.map((questionListItem) => {
            return (
              <div className="question-container" key={questionListItem.id}>
                <p className="question-message">{questionListItem.message}</p>
                <div className="answer-container">
                  {questionListItem.answer.map((answerListItem) => {
                    return (
                      <div className="answer-box" key={answerListItem.message}>
                        <span className="answer-message">{answerListItem.message}</span>
                        <input
                          name={questionListItem.id}
                          type="radio"
                          className="radio-button"
                          value={answerListItem.value}
                          onChange={(e) => setAnswerData(e, setRehabilitationAnswer, rehabilitationAnswer)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
        <section className="form-section">
          <p className="section-title">평가 항목 5 - {nursingQuestionnaire.sectionTitle}</p>
          <div className="section_description">
            <p>{nursingQuestionnaire.sectionDescription.main}</p>
            <p>{nursingQuestionnaire.sectionDescription.sub}</p>
          </div>
          {nursingQuestionnaire.questionList.map((questionListItem) => {
            return (
              <div className="question-container" key={questionListItem.id}>
                <p className="question-message">{questionListItem.message}</p>
                <div className="answer-container">
                  {questionListItem.answer.map((answerListItem) => {
                    return (
                      <div className="answer-box" key={answerListItem.message}>
                        <span className="answer-message">{answerListItem.message}</span>
                        <input
                          name={questionListItem.id}
                          type="radio"
                          className="radio-button"
                          value={answerListItem.value}
                          onChange={(e) => setAnswerData(e, setNursingAnswer, nursingAnswer)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
        <section className="form-section genderSection">
          <div className="question-container">
            <p className="question-message"> 어르신의 성별을 선택해주세요</p>
            <div className="answer-container">
              <div className="answer-box">
                <span className="answer-message">남성</span>
                <input
                  type="radio"
                  className="radio-button"
                  value={GENDER_TYPE.male}
                  name="gender"
                  onChange={(e) => handleGender(e)}
                />
              </div>
              <div className="answer-box">
                <span className="answer-message">여성</span>
                <input
                  type="radio"
                  className="radio-button"
                  value={GENDER_TYPE.female}
                  name="gender"
                  onChange={(e) => handleGender(e)}
                />
              </div>
            </div>
          </div>
          <div className="question-container name">
            <p className="question-message name"> 어르신의 연세를 입력해주세요 :</p>
            <input type="text" maxLength={3} value={age} onChange={(e) => onChangeAge(e)} />
          </div>
        </section>
      </section>
      <button className="confirm-button" onClick={onClickConfirm} disabled={!isAllChecked}>
        요양등급 설문조가 결과 보러가기
      </button>
    </main>
  );
};

export default QuestionnaireForm;
