import "./styles.scss";
import { forwardRef } from "react";
import classNames from "classnames";
import StrokeButton from "../../Button/StrokeButton";
import MessageText from "../../MessageText";
import Inputbox from "../Inputbox";

interface Props {
  labelText: string;
  labelType?: string;
  type: string;
  name: string;
  value: string;
  maxLength?: number;
  placeholder?: string;
  error?: boolean;
  errorMsg?: string;
  okMsg?: string;
  buttonText?: string;
  disabled?: boolean;
  btnDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const CommonInput: React.FC<Props & React.RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, Props>(
  (
    {
      labelText,
      labelType,
      type,
      name,
      value,
      maxLength,
      placeholder,
      error,
      errorMsg = "",
      okMsg = "",
      buttonText,
      disabled,
      btnDisabled,
      onChange,
      onClick,
      onKeyDown,
      onBlur,
    },
    ref,
  ) => {
    return (
      <div className="common-input__container">
        <label className={`${labelType}`}>{labelText}</label>
        <div className={classNames("common-input__content", { error: error })}>
          <div className={classNames("common-input__item", { both: buttonText !== undefined })}>
            <Inputbox
              type={type}
              name={name}
              value={value}
              maxLength={maxLength}
              placeholder={placeholder}
              disabled={disabled}
              error={error}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              ref={ref}
            />
            {buttonText && (
              <StrokeButton
                text={buttonText}
                size={"lg"}
                strokeColor={"black"}
                disabled={btnDisabled}
                onClick={onClick}
              />
            )}
          </div>
          {error ? <MessageText type={`${error}`} text={errorMsg} /> : okMsg && <MessageText text={okMsg} />}
        </div>
      </div>
    );
  },
);

export default CommonInput;
