import "./styles.scss";
import Tabbox from "../../Tabbox/NumberTabbox";

interface Props {
  title: string;
  subTitle?: string;
  subTitleTag?: string;
  tabIndex: number;
  children: any;
}

const NewSignUpTemplate: React.FC<Props> = ({ title, subTitle, subTitleTag, tabIndex, children }) => {
  return (
    <div className="new-signup__container">
      <h2>{title}</h2>
      <Tabbox tabIndex={tabIndex} />
      {subTitle && (
        <div className="new-signup__title-wrap">
          <h3>{subTitle}</h3>
          {subTitleTag && <p>{subTitleTag}</p>}
        </div>
      )}
      {children}
    </div>
  );
};

export default NewSignUpTemplate;
