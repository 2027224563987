import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import classNames from "classnames";
import { privacyPolicy } from "../../../constants/privacyPolicy";

interface Props {
  setIsAgreePrivacy: React.Dispatch<any>;
  agreementObject: any;
  setAgreementObject: React.Dispatch<React.SetStateAction<any>>;
}
const Privacy: FC<Props> = ({ setIsAgreePrivacy, agreementObject, setAgreementObject }) => {
  const [isAgree, setIsAgree] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedPopupNumber, setSelectedPopupNumber] = useState(1);

  const handleAgreementObject = (key: number) => {
    const tempValue = agreementObject[key];

    setAgreementObject({
      ...agreementObject,
      [key]: !tempValue,
    });
  };

  const onClickAllAgreement = () => {
    setAgreementObject({
      1: true,
      2: true,
      3: true,
      4: true,
    });
  };

  useEffect(() => {
    const isAgree = agreementObject[1] && agreementObject[2];
    setIsAgree(isAgree);
  }, [agreementObject]);

  const onClickDetail = (value: any) => {
    setSelectedPopupNumber(value);
    setIsOpenPopup(true);
  };

  return (
    <main className="page-main-container privacy-main-container">
      <h2>(회원가입) 약관동의</h2>
      <div className="check-container">
        {isOpenPopup ? (
          <div className="popup-container">
            <p className="info-desc">{selectedPopupNumber === 1 ? privacyPolicy[1] : privacyPolicy[2]}</p>
            <div>
              <button onClick={() => setIsOpenPopup(false)}>닫기</button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ marginBottom: "15px" }}>
          <span
            className={classNames("check-box", {
              checked: agreementObject[1] && agreementObject[2] && agreementObject[3] && agreementObject[4],
            })}
            onClick={onClickAllAgreement}
          />
          <span className="desc">모두 동의합니다.</span>
        </div>
        <div>
          <span
            className={classNames("check-box", { checked: agreementObject[1] })}
            onClick={() => handleAgreementObject(1)}
          />
          <span className="desc">(필수) 서비스 이용 약관</span>
          <span className="detail" onClick={() => onClickDetail(1)}>
            약관보기
          </span>
        </div>
        <div>
          <span
            className={classNames("check-box", { checked: agreementObject[2] })}
            onClick={() => handleAgreementObject(2)}
          />
          <span className="desc">(필수) 개인정보 이용 동의</span>
          <span className="detail" onClick={() => onClickDetail(2)}>
            약관보기
          </span>
        </div>
        <div>
          <span
            className={classNames("check-box", { checked: agreementObject[3] })}
            onClick={() => handleAgreementObject(3)}
          />
          <span className="desc">(선택) 개인정보 활용 동의</span>
        </div>
        <div>
          <span
            className={classNames("check-box", { checked: agreementObject[4] })}
            onClick={() => handleAgreementObject(4)}
          />
          <span className="desc">(선택) SMS, 이메일 마케팅 수신 동의</span>
        </div>
      </div>
      <button disabled={!isAgree} onClick={() => setIsAgreePrivacy(true)}>
        다음으로
      </button>
    </main>
  );
};

export default Privacy;
