export type userTypes = 1 | 2 | 3 | 4 | 5;

export const USER_TYPE_CD: { [k in string]: userTypes } = {
  GUARDIAN: 1,
  SELF: 2,
  CAREWORKER: 3,
  CORPORATION: 4,
  OUTSOURCING: 5,
} as const;

export const USER_TYPE_DESC: Record<userTypes, string> = {
  1: "보호자",
  2: "본인",
  3: "요양보호사",
  4: "기업",
  5: "아웃소싱",
} as const;

export const USER_TYPE = {
  guard: "Guard", // 보호자
  self: "Self", // 본인
};

export const GENDER_TYPE = {
  male: "M", // 남자
  female: "W", //여자
};

export const GENDER_TYPE_DESC: { [k in string]: string } = {
  M: "남자",
  W: "여자",
};
